import { PrimaryWorkerState } from '../../types.d'

import { getLocalStorageState } from './local-storage-utils'

const localProjectStorageDeleteProject = async (
  workerState: PrimaryWorkerState.WorkerState,
): Promise<boolean> => {
  const localStorage = getLocalStorageState(workerState)
  const { projectId } = workerState.projectState
  if (!projectId) {
    throw new Error('Missing projectId')
  }

  // delete objects
  const deleteCount = await localStorage.objectsTable
    .where('projectId')
    .equals(projectId)
    .delete()

  // delete meta and pending changes
  await localStorage.syncMetaTable.delete(projectId)
  await localStorage.cloudPendingChangesTable.delete(projectId)

  return deleteCount > 0
}

export default localProjectStorageDeleteProject
