import React, { useCallback, useState } from 'react'
import { Box } from 'reflexbox'
import { useHistory } from 'react-router-dom'

import { Icon, A, Tooltip, Menu, MenuItem, Divider } from '@rapidapi/ui-lib'

import { removeOAuthToken } from 'lib-hooks/cloud-api/cloud-api-auth'
import { useAuthStatus } from 'lib-hooks'

const AccountMenuButton: React.FC = () => {
  // get user auth status
  const authStatus = useAuthStatus()

  // menu
  const [menuButtonElement, setMenuButtonElement] = useState<Element | null>(
    null,
  )
  const onButtonClick = useCallback((event: React.MouseEvent) => {
    setMenuButtonElement((currentVal) =>
      currentVal ? null : event.currentTarget,
    )
  }, [])
  const onClose = useCallback(() => {
    setMenuButtonElement(null)
  }, [])

  // actions
  const history = useHistory()
  const onClickProjects = useCallback(() => {
    history.push('/')
  }, [history])
  const onClickLogin = useCallback(() => {
    history.push('/oauth')
  }, [history])
  const onClickAccount = useCallback(() => {
    window.location.href = 'https://paw.cloud/account/'
  }, [])
  const onClickLogout = useCallback(() => {
    // logout
    removeOAuthToken()

    // just reload
    window.location.reload()
  }, [])

  return (
    <>
      <Tooltip content="Account" placement="right">
        <Box py={2}>
          <A variant="secondary" displayInline={false} onClick={onButtonClick}>
            <Icon symbol="user" size={24} />
          </A>
        </Box>
      </Tooltip>
      <Menu
        popoverProps={{
          placement: 'top-start',
          open: !!menuButtonElement,
          anchorEl: menuButtonElement,
          onClose,
        }}
      >
        {authStatus === 'authenticated' && (
          <>
            <MenuItem onSelect={onClickProjects}>Projects</MenuItem>
            <MenuItem onSelect={onClickAccount}>Account</MenuItem>
            <Divider />
            <MenuItem onSelect={onClickLogout}>Logout</MenuItem>
          </>
        )}
        {authStatus === 'missing' && (
          <MenuItem onSelect={onClickLogin}>Login</MenuItem>
        )}
      </Menu>
    </>
  )
}

export default AccountMenuButton
