/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, Suspense, lazy } from 'react'
import { Global, css } from '@emotion/core'
import emotionReset from 'emotion-reset'
import { useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from 'emotion-theming'

import { ErrorBoundary, useWatchAgent } from 'ecosystems'

import { selectTheme } from 'store/selectors'
import { lightTheme, darkTheme } from 'themes'
import { PromptProvider, global } from '@rapidapi/ui-lib'
import pawIcons from 'assets/img/paw-icon.svg'

import './app.css'
import { useWatchMedia } from 'utils'

// declare the svg sprite path from the current tproject
global.iconPath = pawIcons

const HomePage = lazy(() => import('pages/home-page/home-page'))
const SandboxPage = lazy(() => import('pages/sandbox-page/sandbox-page'))
const ProjectPage = lazy(() => import('pages/project-page/project-page'))
const OAuthPage = lazy(() => import('pages/oauth-page/oauth-page'))
const DownloadPage = lazy(() => import('pages/download-page/download-page'))

const NoMatch = () => (
  <div>
    <h1>Oops</h1>
  </div>
)

const AppRouter: React.FC<{
  basename: string
  appPrefix: string
  initialApiId?: string
}> = ({ basename, appPrefix, initialApiId }) => {
  const [apiId, setApiId] = React.useState(initialApiId)
  React.useEffect(() => {
    const listener = ((e: CustomEvent<string>) =>
      setApiId(e.detail)) as EventListener
    window.addEventListener('rapidapi:api-id-changed', listener)
    return () => {
      window.removeEventListener('rapidapi:api-id-changed', listener)
    }
  })

  return (
    <Router
      // FIXME: This seems to work without the need to fix all the links 🤞😅
      // But routing will be broken if this event will be triggered without URL update (which should not happen :D)
      // key is required, cause Router is not updating basename without remounting
      // unmounting is not ideal, but with the current flow apiId will almost never change and mostlikely won't be done often while app is mounted?
      key={apiId}
      basename={[basename, apiId, appPrefix].filter(Boolean).join('/')}
    >
      {/* TODO: we need to decide how this fallback spinner should look like */}
      <Suspense fallback={<div />}>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/projects/:id">
            <ProjectPage />
          </Route>
          <Route exact path="/oauth">
            <OAuthPage />
          </Route>
          <Route exact path="/signup">
            <Redirect to="/oauth" />
          </Route>
          <Route exact path="/sandbox">
            <SandboxPage />
          </Route>
          <Route exact path="/download">
            <DownloadPage />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  )
}

const App: React.FC<{
  BASE_APP_PREFIX?: string
  BASE_PATH?: string
  INITIAL_API_ID?: string
}> = ({ BASE_PATH, BASE_APP_PREFIX, INITIAL_API_ID }) => {
  const themeSettings = useSelector(selectTheme)
  const autoDark = useWatchMedia('(prefers-color-scheme: dark)')
  const autoLight = useWatchMedia('(prefers-color-scheme: light)')

  const theme = useMemo(() => {
    if (themeSettings === 'light') {
      return lightTheme
    }

    if (themeSettings === 'dark') {
      return darkTheme
    }

    // Automatic Preference: when set to 'auto', the theme will adapt
    // to the Operating System's color mode settings.
    if (themeSettings === 'auto' && autoDark) {
      return darkTheme
    }

    if (themeSettings === 'auto' && autoLight) {
      return lightTheme
    }

    return lightTheme
  }, [themeSettings, autoDark, autoLight])

  // watch agent/proxy
  useWatchAgent()

  return (
    <HelmetProvider>
      <Global
        styles={css`
          ${emotionReset}

          @font-face {
            font-family: 'Inter';
            src: url('/fonts/inter-regular.ttf') format('truetype');
            font-weight: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'Inter';
            src: url('/fonts/inter-semi-bold.ttf') format('truetype');
            font-weight: bold;
            font-display: swap;
          }

          @font-face {
            font-family: 'JetBrains Mono';
            src: url('/fonts/jet-brains-mono-regular.ttf') format('truetype');
            font-weight: normal;
            font-display: swap;
          }

          @font-face {
            font-family: 'JetBrains Mono';
            src: url('/fonts/jet-brains-mono-bold.ttf') format('truetype');
            font-weight: bold;
            font-display: swap;
          }

          *,
          *::after,
          *::before {
            box-sizing: border-box;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
          }

          body {
            margin: 0;
            font-family: ${theme.fonts.default};
            height: 100%;
            overflow-y: hidden;
            overscroll-behavior: none;
            background-color: ${theme.colors.backgroundColor};
            color: ${theme.colors.text};
            &.disable-overflow {
              overflow: hidden;
            }
          }

          html,
          body,
          #root {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            height: 100%;
            min-height: 100%;
          }

          #root {
            display: flex;
            flex-direction: column;
          }

          body {
            margin: 0;
            font-family: ${theme.fonts.default};
            height: 100%;
            background-color: ${theme.colors.backgroundColor};
            color: ${theme.colors.text};
          }

          /** Scrollbar styling for Firefox */
          * {
            scrollbar-width: thin;
            scrollbar-color: ${theme.colors.backgrounds.scrim} transparent;
          }

          /** Scrollbar styling for Webkit (Chrome, Safari, Edge) */
          *::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }

          *::-webkit-scrollbar-track {
            background: transparent;
          }

          *::-webkit-scrollbar-thumb {
            background-color: ${theme.colors.backgrounds.scrim};
            border: 0 none;
          }

          /* Make contenteditable works in Safari  */
          [contenteditable] {
            -webkit-user-select: text;
            user-select: text;
          }
        `}
      />
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <PromptProvider>
            <AppRouter
              basename={BASE_PATH ?? process.env.REACT_APP_BASENAME ?? ''}
              appPrefix={BASE_APP_PREFIX ?? ''}
              initialApiId={INITIAL_API_ID}
            />
          </PromptProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default App
