/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import css from '@styled-system/css'
import styled from 'themes'
import { Box } from 'reflexbox'
import { Icon, A } from '@rapidapi/ui-lib'
import { AccountMenuButton, ThemeButton } from 'pages/settings-page'
import { ProjectUndoButton } from 'pages/project-page/project-undo-button'
import { NavItem, NavItemProps } from './nav-item'

export type AppHeaderProps = {
  pageNav?: NavItemProps[]
  systemNav?: NavItemProps[]
}

const HeaderStyled = styled(Box)(
  ({
    theme: {
      addAlpha,
      colors: { common },
    },
  }) =>
    css({
      width: 56,
      gridArea: 'header',
      display: 'grid',
      gridTemplateRows: 'min-content 1fr min-content',
      color: addAlpha(common.white, 0.6),
      bg: 'darkGray.100',
      padding: 2,
      '& hr': {
        mt: 2,
        mb: 3,
        border: '0 none',
        borderTop: `1px solid ${addAlpha(common.white, 0.1)}`,
      },
    }),
)

const AppHeader: React.FC<AppHeaderProps> = ({ pageNav, systemNav }) => {
  const pageNavItems: NavItemProps[] = pageNav || []
  const sysNavItems: NavItemProps[] = systemNav || []

  return (
    <HeaderStyled as="header">
      <Box textAlign="center">
        <A displayInline={false} link={{ to: '/' }}>
          <Icon symbol="luckymarmot" size={40} />
        </A>
        <hr />
      </Box>
      <Box textAlign="center">
        {pageNavItems.map((navItem: NavItemProps, navItemIndex: number) => (
          <NavItem key={navItemIndex.toString()} {...navItem} />
        ))}
      </Box>
      <Box textAlign="center">
        <ProjectUndoButton hidden />
        <ThemeButton />
        <AccountMenuButton />
        {sysNavItems.length > 0 &&
          sysNavItems.map((navItem: NavItemProps, navItemIndex: number) => (
            <Box key={navItemIndex.toString()} py={2}>
              <A variant="secondary" displayInline={false} {...navItem}>
                <Icon symbol={navItem.icon} size={24} />
              </A>
            </Box>
          ))}
      </Box>
    </HeaderStyled>
  )
}

export default AppHeader
