import { AnyAction } from '@reduxjs/toolkit'

import {
  fetchHttpExchangeListAction,
  fetchHttpExchangeItemAction,
  deleteHttpExchangeListAction,
  deleteHttpExchangeItemAction,
  extensionsLoadListAction,
  extensionsUnloadItemAction,
  extensionsGenerateCodeAction,
  extensionsExportRequestsAction,
  loadProjectAction,
  unloadProjectAction,
  syncProjectAction,
  sendRequestAction,
  sendCurrentRequestAction,
  currentItemStateActions,
  setProjectValue,
  undoAction,
  redoAction,
  projectSetterAction,
  extensionsImportRequestsAction,
} from 'store/actions'

import {
  projectLoadActionHandler,
  projectUnloadActionHandler,
  projectSyncActionHandler,
  projectApplyChangeActionHandler,
  requestSendActionHandler,
  httpExchangeFetchListActionHandler,
  httpExchangeFetchItemActionHandler,
  httpExchangeDeleteListActionHandler,
  httpExchangeDeleteItemActionHandler,
  extensionsLoadListActionHandler,
  extensionsUnloadItemActionHandler,
  extensionsGenerateCodeActionHandler,
  extensionsExportRequestsActionHandler,
} from './handlers'
import extensionsImportRequestsActionHandler from './handlers/extensions-import-code-action-handler'

import { ActionHandler } from './types.d'

const handleAction = (handler: ActionHandler<AnyAction>): Promise<void> => {
  const { action, next } = handler

  // Project Actions
  if (loadProjectAction.match(action)) {
    return projectLoadActionHandler({
      ...handler,
      action,
    })
  }
  if (unloadProjectAction.match(action)) {
    return projectUnloadActionHandler({
      ...handler,
      action,
    })
  }
  if (syncProjectAction.match(action)) {
    return projectSyncActionHandler({
      ...handler,
      action,
    })
  }

  // Apply Project Changes
  if (
    setProjectValue.match(action) ||
    projectSetterAction.match(action) ||
    undoAction.match(action) ||
    redoAction.match(action)
  ) {
    return projectApplyChangeActionHandler({
      ...handler,
      action,
    })
  }

  // Send Request Action
  if (
    sendCurrentRequestAction.match(action) ||
    sendRequestAction.match(action)
  ) {
    return requestSendActionHandler({
      ...handler,
      action,
    })
  }

  // HTTP Exchange Actions
  if (fetchHttpExchangeListAction.match(action)) {
    return httpExchangeFetchListActionHandler({
      ...handler,
      action,
    })
  }
  if (fetchHttpExchangeItemAction.match(action)) {
    return httpExchangeFetchItemActionHandler({
      ...handler,
      action,
    })
  }
  if (deleteHttpExchangeListAction.match(action)) {
    return httpExchangeDeleteListActionHandler({
      ...handler,
      action,
    })
  }
  if (deleteHttpExchangeItemAction.match(action)) {
    return httpExchangeDeleteItemActionHandler({
      ...handler,
      action,
    })
  }

  // Extension Actions
  if (extensionsLoadListAction.match(action)) {
    return extensionsLoadListActionHandler({ ...handler, action })
  }

  if (extensionsUnloadItemAction.match(action)) {
    return extensionsUnloadItemActionHandler({ ...handler, action })
  }

  if (extensionsGenerateCodeAction.match(action)) {
    return extensionsGenerateCodeActionHandler({ ...handler, action })
  }

  if (extensionsExportRequestsAction.match(action)) {
    return extensionsExportRequestsActionHandler({ ...handler, action })
  }

  if (extensionsImportRequestsAction.match(action)) {
    return extensionsImportRequestsActionHandler({ ...handler, action })
  }

  // HTTP Exchanges - when selecting a request, fetch the request history list
  if (currentItemStateActions.setCurrentTreeItemRef.match(action)) {
    const requestUuid = action.payload?.ref
    if (requestUuid) {
      httpExchangeFetchListActionHandler({
        ...handler,
        action: fetchHttpExchangeListAction({ requestUuid }),
      })
    }
    // and continue (do not return here)
  }

  next(action)
  return Promise.resolve()
}

export default handleAction
