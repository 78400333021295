import css from '@styled-system/css'
import styled from 'themes'
import { Box } from 'reflexbox'

const MarkdownEditorWrapper = styled(Box)(
  ({
    theme: {
      radii,
      colors: { blue },
    },
  }) =>
    css({
      position: 'relative',
      height: '90%',
      minHeight: 150,
      marginTop: 1,
      paddingTop: 2,
      paddingLeft: 2,
      paddingBottom: '48px',
      borderRadius: radii.default,
      backgroundColor: 'backgrounds.secondary',
      border: '2px solid transparent',
      transition: 'box-shadow .2s ease, border-color .2s ease',
      overflow: 'hidden',

      '& .monaco-editor-background.lines-content': {
        backgroundColor: 'backgrounds.secondary',
      },

      '& .monaco-editor .scroll-decoration': {
        boxShadow: 'none',
      },

      '&:focus, &:focus-within': {
        borderColor: blue[200],
        backgroundColor: 'backgrounds.primary',
      },

      '&:focus .monaco-editor-background.lines-content, &:focus-within .monaco-editor-background.lines-content': {
        backgroundColor: 'backgrounds.primary',
      },
    }),
)

export default MarkdownEditorWrapper
