import React from 'react'
import styled from 'themes'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import css from '@styled-system/css'
import { Box } from 'reflexbox'

export type MarkdownPreviewType = {
  content: string
}

const MarkdownRenderer = styled(Box)(
  ({
    theme: {
      addAlpha,
      radii,
      space,
      lineHeights,
      fonts,
      fontSizes,
      colors: { content, backgrounds },
    },
  }) => {
    const headingSize = (step: number): string =>
      `${fontSizes.headline + step}px`
    return css({
      boxSizing: 'border-box',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '48px',
      zIndex: 100,
      width: '100%',
      height: 'inherit',
      padding: 3,
      overflowY: 'auto',
      lineHeight: lineHeights.body1,
      backgroundColor: 'backgrounds.secondary',
      a: {
        '&:link, &:active, &:visited': {
          color: content.accent,
          textDecoration: 'none',
        },
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      img: {
        maxWidth: '35%',
      },
      p: {
        marginTop: space[2],
        marginBottom: space[2],
      },
      hr: {
        marginTop: space[2],
        marginBottom: space[2],
        border: `1px solid ${content.primary}`,
      },
      strong: {
        fontWeight: 'bold',
      },
      em: {
        fontStyle: 'italic',
      },
      table: {
        marginTop: space[4],
        marginBottom: space[4],
        borderCollapse: 'collapse',
        borderSpacing: 0,
        emptyCells: 'show',
        border: `1px solid ${content.primary}`,
        'thead, tbody': {
          textAlign: 'left',
          padding: space[2],
          verticalAlign: 'bottom',
        },
        thead: {
          fontSize: fontSizes.caption,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: 'content.secondary',
          backgroundColor: 'backgrounds.tertiary',
        },
        'th, td': {
          border: `1px solid ${backgrounds.primary}`,
          margin: 0,
          overflow: 'visible',
          padding: space[2],
        },
        'tbody tr:nth-of-type(even)': {
          backgroundColor: addAlpha(backgrounds.tertiary, 0.75),
        },
      },
      pre: {
        display: 'block',
        marginTop: space[2],
        marginBottom: space[2],
        padding: space[4],
        overflow: 'auto',
        fontFamily: fonts.mono,
        borderRadius: radii.default,
        backgroundColor: 'backgrounds.tertiary',
      },
      'code:not(pre code), kbd': {
        display: 'inlineBlock',
        fontFamily: fonts.mono,
        color: content.accent,
        lineHeight: 1,
        padding: `2px ${space[2]}px`,
        borderRadius: radii.default,
        backgroundColor: backgrounds.scrim,
      },
      blockquote: {
        display: 'block',
        marginTop: space[3],
        marginBottom: space[3],
        padding: space[2],
        paddingLeft: space[4],
        borderLeft: `4px solid ${backgrounds.tertiary}`,
      },
      'ul, ol': {
        display: 'block',
        marginTop: space[2],
        marginBottom: space[2],
        paddingLeft: space[5],
        li: {
          marginLeft: 0,
        },
      },
      'ol li': {
        listStyleType: 'decimal',
      },
      'ul li': {
        listStyleType: 'disc',
      },
      'ul.contains-task-list, ol.contains.task-list': {
        display: 'block',
        marginTop: space[2],
        marginBottom: space[2],
        padding: 0,

        li: {
          marginLeft: 0,
          listStyle: 'none',
        },
      },
      'h1, h2, h3, h4, h5, h6': {
        display: 'block',
        fontWeight: 'bold',
        marginTop: space[4],
        marginBottom: space[5],
        paddingBottom: space[0],
        lineHeight: lineHeights.headline,
      },
      h1: {
        paddingBottom: space[2],
        fontSize: headingSize(3),
        lineHeight: lineHeights.title,
        borderBottom: `2px solid ${content.tertiary}`,
      },
      h2: {
        paddingBottom: space[2],
        fontSize: headingSize(2.4),
        lineHeight: lineHeights.title,
        borderBottom: `2px solid ${backgrounds.tertiary}`,
      },
      h3: {
        paddingBottom: space[4],
        fontSize: headingSize(2),
        borderBottom: `2px solid ${backgrounds.tertiary}`,
      },
      h4: {
        fontSize: headingSize(1.8),
      },
      h5: {
        fontSize: headingSize(1.6),
      },
    })
  },
)

const MarkdownPreview: React.FC<MarkdownPreviewType> = ({ content }) => (
  <MarkdownRenderer>
    <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
  </MarkdownRenderer>
)

export default MarkdownPreview
