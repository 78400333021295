import { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getRequestGroup = <Optional extends true | false>(
  ref: Project.GenericRef<Project.RequestGroup>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true ? Project.RequestGroup | null : Project.RequestGroup =>
  getObject(ref, objects, isOptional, 'group')

export default getRequestGroup
