/* eslint-disable no-param-reassign */
import { Project } from 'lib/project/types.d'
import addDynamicValue from './add-dynamic-value'

export type InsertDynamicValuesArgs<Y> = {
  dynamicValues: Project.InsertDynamicValue<Y>[]
}
const insertDynamicValues = <Y = Project.ObjectMap<unknown>>(
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { dynamicValues }: InsertDynamicValuesArgs<Y>,
): void => {
  dynamicValues.forEach((dynamicValue) => {
    addDynamicValue(objects, root, dynamicValue)
  })
}

export default insertDynamicValues
