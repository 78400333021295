import { DynamicValues } from 'lib/dynamic-values/types.d'
import { Evaluation, evaluateFullURLString } from 'lib/evaluation'
import { getRequestLayer } from 'lib/evaluation/layers'
import { getRequest } from 'lib/project'
import { RequestURLDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.RequestURLDynamicValue'
const title = 'Request URL'

const defaultValue: RequestURLDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  request: '',
  includeScheme: true,
  includeHost: true,
  includeParameters: true,
}

const editForm: DynamicValues.EditForm<RequestURLDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'request',
      fieldType: 'request',
      label: 'Request',
    },
    {
      fieldKey: 'includeScheme',
      fieldType: 'checkbox',
      label: 'Include scheme',
    },
    {
      fieldKey: 'includeHost',
      fieldType: 'checkbox',
      label: 'Include host',
    },
    {
      fieldKey: 'includeParameters',
      fieldType: 'checkbox',
      label: 'Include parameters',
    },
  ],
}

function getRequestRef(requestUUID: string | null, ctx: Evaluation.Ctx) {
  return requestUUID ? { ref: requestUUID } : getRequestLayer(ctx)?.requestRef
}

const implRequestURLDynamicValue: DynamicValues.Implementation<RequestURLDynamicValueInterface> = {
  title,
  identifier,
  defaultValue,
  editForm,
  getAllRefs() {
    return null
  },
  getEvaluatedString: async (
    { request: requestUUID, includeHost, includeParameters, includeScheme },
    ctx,
  ) => {
    const requestRef = getRequestRef(requestUUID, ctx)
    if (!requestRef) {
      return ''
    }

    const requestObject = getRequest(requestRef, ctx.project.objects, true)
    if (!requestObject) {
      return ''
    }

    const urlString = await evaluateFullURLString(requestObject, ctx)
    let urlObject = null
    try {
      urlObject = new URL(urlString)
    } catch (e) {
      return urlString
    }

    if (!urlObject) {
      return urlString
    }

    const { protocol, host, searchParams, hash } = urlObject
    let reconstructedUrl = ''

    if (includeScheme && protocol) {
      reconstructedUrl += `${protocol}//`
    }

    if (includeHost && host) {
      reconstructedUrl += host
    }

    if (includeParameters && searchParams.toString()) {
      reconstructedUrl += `?${searchParams}`
    }

    return reconstructedUrl + hash
  },
  getTokenInfo: async ({ request: requestUUID }, ctx) => {
    const requestRef = getRequestRef(requestUUID, ctx)
    if (requestRef) {
      const requestObject = getRequest(requestRef, ctx.project.objects, true)
      if (requestObject) {
        return {
          title,
          text: requestObject.title || null,
        }
      }
    }
    return {
      title,
      text: '',
    }
  },
}

export default implRequestURLDynamicValue
