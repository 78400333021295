import { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getDynamicValue = <
  Optional extends true | false,
  DV extends Project.DynamicValue = Project.DynamicValue
>(
  ref: Project.GenericRef<DV>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true ? DV | null : DV =>
  getObject(ref, objects, isOptional, 'dynamicValue')

export default getDynamicValue
