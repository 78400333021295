import { addAlpha } from 'utils'
import { common, blue, darkRed, darkGreen } from '../colors'

const content = {
  primary: addAlpha(common.white, 0.87),
  secondary: addAlpha(common.white, 0.6),
  tertiary: addAlpha(common.white, 0.28),
  accent: blue[200],
  error: darkRed[200],
  success: darkGreen[200],
}

export default content
