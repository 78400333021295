import { DynamicString } from './extension-types/dynamic-string.d'
import { DynamicValue } from './extension-types/dynamic-value.d'

export {
  Generator,
  Importer,
  BasicAuth,
  OAuth1,
  OAuth2,
  Context,
  Environment,
  EnvironmentDomain,
  EnvironmentVariable,
  ExtensionImportFile,
  ExtensionOption,
  ExtensionItem,
  ExtensionInputType,
  HTTPExchange,
  KeyValue,
  Request,
  RequestGroup,
  RequestVariable,
  RuntimeInfo,
  UserInfo,
  DocumentInfo,
} from './extension-types/index.d'

export declare function use(extension: Generator | Importer): void
export type DynamicStringComponent = string | DynamicValue
export type RequestTreeItem = Request | RequestGroup

export enum KeyValueMode {
  Normal = 0,
  NormalAlwaysAddEqualSign = 1,
  Raw = 2,
}

declare global {
  export { DynamicString, DynamicValue }
}
