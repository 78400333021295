/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Box, Flex } from 'reflexbox'
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system'

import { Project } from 'lib/project'
import { DynamicValues } from 'lib/dynamic-values'

import { Typography } from '@rapidapi/ui-lib'
import { IconSymbol } from 'themes/types.d'
import { useDynamicValueObject } from 'utils/hooks'

import { StringEditor } from '../string-editor'
import { DynamicStringEditor } from '../dynamic-string-editor'
import { EnvironmentVariablePickerEditor } from '../environment-variable-picker-editor'
import { RequestPickerEditor } from '../request-picker-editor'
import { RequestVariablePickerEditor } from '../request-variable-picker-editor'
import { ParameterListEditor } from '../parameter-list-editor'
import { RadioChoiceEditor } from '../radio-choice-editor'
import { CheckboxEditor } from '../checkbox-editor'
import { JsonEditor } from '../json-editor'
import { OAuth2TokenEditor } from './custom-oauth2-token-editor'
import { FilePicker } from '../file-picker'

export type DynamicValueEditorRenderFieldContainer = (
  fieldKey: string,
  fieldType: DynamicValues.EditFormFieldType,
  children: JSX.Element,
) => JSX.Element

export type DynamicValueEditorFieldSetProps = SpaceProps &
  LayoutProps &
  FlexboxProps & {
    objectRef: Project.GenericRef<Project.DynamicValue>
    fields: DynamicValues.EditFormField<Project.DynamicValue>[]
    renderFieldContainer?: DynamicValueEditorRenderFieldContainer
  }

const defaultRenderFieldContainer = (
  fieldKey: string,
  fieldType: DynamicValues.EditFormFieldType,
  children: JSX.Element,
) => (
  <Box key={fieldKey} mt={1} mb={2}>
    {children}
  </Box>
)

const DynamicValueEditorFieldSet: React.FC<DynamicValueEditorFieldSetProps> = ({
  objectRef,
  fields,
  renderFieldContainer = defaultRenderFieldContainer,
  ...props
}) => {
  const dynamicValue = useDynamicValueObject(objectRef)

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      maxHeight="40vh"
      overflowY="scroll"
      px={1}
      {...props}
    >
      {fields
        .filter(({ isHidden }) => !isHidden || !isHidden(dynamicValue))
        .map(({ fieldKey, fieldType, label, hint, hintIcon, ...more }) =>
          renderFieldContainer(
            fieldKey as string,
            fieldType,
            <>
              {(() => {
                switch (fieldType) {
                  case 'string':
                    return (
                      <StringEditor
                        key={fieldKey as string}
                        objectRef={objectRef}
                        objectProperty={fieldKey}
                        label={label}
                        hint={hint}
                        hintIcon={hintIcon as IconSymbol}
                        width="auto"
                      />
                    )
                  case 'dynamicString':
                    return (
                      <DynamicStringEditor
                        key={fieldKey as string}
                        objectRef={objectRef}
                        objectProperty={fieldKey}
                        label={label}
                        hint={hint}
                        hintIcon={hintIcon as IconSymbol}
                      />
                    )
                  case 'environmentVariable':
                    return (
                      <EnvironmentVariablePickerEditor
                        key={fieldKey as string}
                        objectRef={objectRef}
                        objectProperty={fieldKey}
                        label={label}
                      />
                    )
                  case 'request':
                    return (
                      <RequestPickerEditor
                        key={fieldKey as string}
                        objectRef={objectRef}
                        objectProperty={fieldKey}
                        label={label}
                      />
                    )
                  case 'requestVariable':
                    return (
                      <RequestVariablePickerEditor
                        key={fieldKey as string}
                        objectRef={objectRef}
                        objectProperty={fieldKey}
                        label={label}
                      />
                    )
                  case 'parameterList':
                    return (
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      <ParameterListEditor<Project.DynamicValue<any>>
                        key={fieldKey as string}
                        objectRef={objectRef}
                        objectProperty={fieldKey as string}
                        label={label}
                      />
                    )
                  case 'checkbox':
                    return (
                      <CheckboxEditor
                        key={fieldKey as string}
                        objectRef={objectRef}
                        objectProperty={fieldKey}
                        label={label}
                        hint={hint}
                        hintIcon={hintIcon as IconSymbol}
                      />
                    )
                  case 'choice':
                    return (
                      <RadioChoiceEditor
                        key={fieldKey as string}
                        objectRef={objectRef}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        objectProperty={fieldKey as any}
                        label={label}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        choices={more.choices as any}
                      />
                    )
                  case 'json':
                    return (
                      <JsonEditor
                        key={fieldKey as string}
                        objectRef={objectRef}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        objectProperty={fieldKey as any}
                        flexGrow={1}
                        flexBasis={0}
                      />
                    )
                  case 'file':
                    return (
                      <FilePicker
                        key={fieldKey as string}
                        objectRef={objectRef}
                        flexGrow={1}
                        flexBasis={0}
                      />
                    )
                  case 'customOAuth2Token':
                    return (
                      <OAuth2TokenEditor
                        key={fieldKey as string}
                        objectRef={objectRef}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        objectProperty={fieldKey as any}
                      />
                    )
                  default:
                    return (
                      <Typography key={fieldKey as string} color="error">
                        {`Missing editor for type ${fieldType}`}
                      </Typography>
                    )
                }
              })()}
            </>,
          ),
        )}
    </Flex>
  )
}

export default DynamicValueEditorFieldSet
