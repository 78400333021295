import { Project } from 'lib/project/types.d'
import { getRequest, getRequestVariable } from '../atoms'

const getVariableByName = (
  requestRef: Project.GenericRef<Project.Request>,
  objects: Project.ObjectMap,
  matchName: string,
): Project.GenericRef<Project.RequestVariable> | null => {
  const request = getRequest(requestRef, objects, false)
  const result = request.variables.find((variableRef) => {
    const variable = getRequestVariable(variableRef, objects, false)
    const { name } = variable
    return name && name.trim().toLowerCase() === matchName.trim().toLowerCase()
  })
  return result || null
}

export default getVariableByName
