import { AnyAction } from '@reduxjs/toolkit'

import { acknowledgePendingChanges, setProject } from 'store/actions'
import {
  selectProjectApplyPendingChangesMutex,
  selectProjectPendingChanges,
} from 'store/selectors'
import { projectApplyChangesWorkerAction } from 'worker/primary-worker/actions'
import { ActionHandler } from '../types.d'

const projectApplyChangeActionHandler = async ({
  next,
  action,
  worker,
  getState,
}: ActionHandler<AnyAction>): Promise<void> => {
  // execute the action first (synchronously)
  next(action)

  // if no worker, skip
  if (!worker) {
    return
  }

  const mutex = selectProjectApplyPendingChangesMutex(getState())

  // propagate changes asynchronously and inside a mutex to
  // avoid two of such processed to happen together
  await mutex.dispatch(async () => {
    // get pending changes
    const { changes, objectsBase } = selectProjectPendingChanges(getState())

    // if no changes, there's nothing to do
    if (changes.length === 0) {
      return
    }

    // apply the first one
    const {
      syncBranch,
      syncCommitMeta,
      syncBranches,
      cloudPendingChangesCount,
      realtimeIsConnected,
      applyChangeStatus,
      root,
      objects,
      objectsBase: newObjectsBase,
    } = await worker.runAction(projectApplyChangesWorkerAction, {
      changes,
      objectsBase,
    })

    if (applyChangeStatus === 'accepted') {
      // change was accepted by worker
      // update the project state according to the last propagated change
      next(
        acknowledgePendingChanges({
          actionsIds: (changes || []).map(({ uuid }) => uuid),
          partialUpdate: {
            objectsBase: newObjectsBase,
            syncState: {
              syncBranch,
              syncCommitMeta,
              syncBranches,
              cloudPendingChangesCount,
              realtimeIsConnected,
            },
          },
        }),
      )
    } else if (applyChangeStatus === 'rejectedWithFullReload') {
      // change was reject by worker
      // full reload the project
      next(
        setProject({
          root,
          objects,
          objectsBase: newObjectsBase,
          // reset pending changes, we don't want to sync them anymore
          pendingChanges: [],
          syncState: {
            syncBranch,
            syncCommitMeta,
            syncBranches,
            cloudPendingChangesCount,
            realtimeIsConnected,
          },
        }),
      )
    } else {
      throw new Error(
        `Invalid value for applyChangeStatus: ${applyChangeStatus}`,
      )
    }
  })
}

export default projectApplyChangeActionHandler
