import { addAlpha } from 'utils'

const blue = {
  10: addAlpha('#0D93F2', 0.8),
  50: addAlpha('#0D93F2', 0.5),
  100: addAlpha('#0D93F2', 0.3),
  200: '#0D93F2',
}

export default blue
