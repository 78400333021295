import { addAlpha } from 'utils'
import { common, blue, darkGray, darkBlue, darkRed, darkGreen } from '../colors'

const backgrounds = {
  primary: darkGray[10],
  secondary: darkGray[50],
  tertiary: darkGray[100],
  accent: blue[200],
  info: addAlpha(darkBlue[200], 0.12),
  error: addAlpha(darkRed[200], 0.12),
  success: addAlpha(darkGreen[200], 0.12),
  scrim: addAlpha(common.black, 0.2),
}

export default backgrounds
