import { addAlpha } from 'utils'
import { common, gray, blue, red, green } from '../colors'

const backgrounds = {
  primary: common.white,
  secondary: gray[10],
  tertiary: gray[100],
  accent: blue[200],
  info: addAlpha(blue[200], 0.12),
  error: addAlpha(red[200], 0.12),
  success: addAlpha(green[200], 0.12),
  scrim: addAlpha(common.black, 0.2),
}

export default backgrounds
