import { Project } from 'lib/project/types.d'
import { getObject } from '../getters'

// eslint-disable-next-line import/no-cycle
import duplicateDynamicString from './duplicate-dynamic-string'
// eslint-disable-next-line import/no-cycle
import duplicateDynamicValue from './duplicate-dynamic-value'
// eslint-disable-next-line import/no-cycle
import duplicateParameter from './duplicate-parameter'

const duplicateObject = <T extends Project.AnyObject>(
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceRef }: { sourceRef: Project.GenericRef<T> },
): Project.GenericRef<T> => {
  const sourceObject = getObject(sourceRef, objects, false)

  switch (sourceObject.type) {
    case 'dynamicString':
      return duplicateDynamicString(objects, root, { sourceRef })
    case 'dynamicValue':
      return duplicateDynamicValue(objects, root, { sourceRef })
    case 'parameter':
      return duplicateParameter(objects, root, { sourceRef })
    default:
      // @TODO make sure we support everything that we need
      // we won't support KeyValues anyway, since its `type` conflicts with Parameter
      throw new Error(
        `duplicateObject() not supported for type ${sourceObject.type}`,
      )
  }
}

export default duplicateObject
