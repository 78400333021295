import { PayloadAction } from '@reduxjs/toolkit'

import { httpExchangesActions } from 'store/actions'
import { httpExchangeDeleteItemWorkerAction } from 'worker/primary-worker/actions'
import { DeleteHttpExchangePayload } from 'store/types.d'

import { ActionHandler } from '../types.d'

const httpExchangeDeleteItemActionHandler = async ({
  action: {
    payload: { requestUuid, httpExchangeUuid },
  },
  next,
  worker,
}: ActionHandler<PayloadAction<DeleteHttpExchangePayload>>): Promise<void> => {
  if (!worker) {
    throw new Error('Worker is missing')
  }
  await worker.runAction(httpExchangeDeleteItemWorkerAction, {
    httpExchangeUuid,
  })
  next(
    httpExchangesActions.removeHttpExchangeForRequest({
      requestUuid,
      httpExchangeUuid,
    }),
  )
}

export default httpExchangeDeleteItemActionHandler
