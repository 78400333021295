import { FetchResult } from '../fetch/fetch-types.d'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleError = async (result: FetchResult): Promise<any> => {
  // if ok, there's no error to handle
  if (result.ok) {
    throw new Error('Internal error: handleError() called on success')
  }

  // parse body safely
  let responseBody
  try {
    responseBody = (await result.body.json()) as {
      error?: string | null
      // eslint-disable-next-line camelcase
      non_field_errors?: string[] | string | null
      detail:
        | undefined
        | null
        | {
            message?: string
            error?: string
          }
    }
  } catch (error) {
    throw new Error(`Unknown error (HTTP ${result.status})`)
  }

  // Django `non_field_errors`
  if (typeof responseBody.non_field_errors === 'string') {
    throw new Error(responseBody.non_field_errors)
  }
  if (Array.isArray(responseBody.non_field_errors)) {
    throw new Error(responseBody.non_field_errors.join(', '))
  }

  // Django `detail.message` and `detail.error`
  if (
    typeof responseBody.detail?.message === 'string' ||
    typeof responseBody.detail?.error === 'string'
  ) {
    throw new Error(
      [responseBody.detail?.error, responseBody.detail?.message]
        .filter((value) => !!value)
        .join(': '),
    )
  }

  // `error`
  throw new Error(responseBody.error || `Unknown error (HTTP ${result.status})`)
}

export default handleError
