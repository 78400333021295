import {
  configureStore,
  getDefaultMiddleware,
  Middleware,
} from '@reduxjs/toolkit'
import { enablePatches } from 'immer'
import { persistStore } from 'redux-persist'
import LogRocket from 'logrocket'
import { primaryWorkerMiddleware } from 'worker/primary-worker/middleware'

import userActionsMiddleware from './user-actions/user-actions-middleware'
import { projectConsistencyMiddleware } from './project-consistency-middleware'
import rootReducer, { RootState } from './root-reducer'

// enable immer patches
enablePatches()

// debug
const isProd = process.env.REACT_APP_NODE_ENV === 'production'

export type StoreOptions = {
  noWorker?: boolean
  middlewares?: Middleware<unknown, RootState>[]
}

// let this be inferred by TS
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getStore = (options: StoreOptions) => {
  // Set `false` to disable the `SerializableStateInvariantMiddleware`
  // to not get a performance bottleneck in development mode
  // Leave it to `undefined` to get the default behavior: enabled in dev
  // mode and disabled in production
  // Do not commit changes to this
  const immutableCheck = {
    ignoredPaths: ['project.applyPendingChangesMutex'],
  }

  // store
  const store = configureStore({
    reducer: rootReducer,
    middleware: [
      userActionsMiddleware,
      ...(!options.noWorker ? [primaryWorkerMiddleware] : []),
      ...(options.middlewares || []),
      ...(isProd ? [] : [projectConsistencyMiddleware]),
      ...getDefaultMiddleware({
        immutableCheck,
        // Set to ignore actions by Redux Persist
        // See: https://github.com/rt2zz/redux-persist/issues/988
        serializableCheck: {
          ignoredActions: ['persist/PERSIST'],
          ignoredPaths: ['project.applyPendingChangesMutex'],
        },
      }),
      // LogRocket middleware should go last
      LogRocket.reduxMiddleware(),
    ],
  })

  // persistor
  const persistor = persistStore(store)

  return { store, persistor }
}

export type AppStore = ReturnType<typeof getStore>['store']

export { getStore }
