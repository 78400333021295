import React from 'react'
import { Box } from 'reflexbox'

const CoverTemplateFooter: React.FC = ({ children }) => (
  <Box as="footer" textAlign="center">
    {children}
  </Box>
)

export default CoverTemplateFooter
