import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  assertType,
  serializeObjectRefArray,
} from 'lib/cloud-sync/serializer/helpers'
import { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

import serializeEnvironment from './serialize-environment'
import serializeEnvironmentVariable from './serialize-environment-variable'

const serializeEnvironmentDomain = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.EnvironmentDomain,
): CloudAPI.SyncTreeItem => {
  // check type
  assertType(object, 'environmentDomain')

  // serialize all values for this domain
  const valuesRefs: Project.GenericRef<
    Project.EnvironmentVariableValue
  >[] = Object.values(serializer.objects)
    .filter(
      (item) =>
        item.type === 'environmentVariableValue' &&
        item.domain.ref === object.uuid,
    )
    .map(({ uuid: itemUuid }) => ({ ref: itemUuid }))
  serializeObjectRefArray(serializer, valuesRefs)

  // children or null (Paw Mac spec)
  const environments = serializeObjectRefArray(
    serializer,
    object.environments as Project.GenericRef<Project.Environment>[],
    serializeEnvironment,
  )
  const variables = serializeObjectRefArray(
    serializer,
    object.variables as Project.GenericRef<Project.EnvironmentVariable>[],
    serializeEnvironmentVariable,
  )

  const syncTreeItem: CloudAPI.SyncTreeItem = {
    _uuid: object.uuid,
    _type: 'environmentDomain',
    name: object.name,
    environments: environments.length > 0 ? environments : null,
    variables: variables.length > 0 ? variables : null,
  }
  return syncTreeItem
}

export default serializeEnvironmentDomain
