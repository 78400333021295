import { Project } from 'lib/project/types.d'
import deleteObject from './delete-object'
import { getRequest } from '../getters'

const deleteRequestVariable = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    ref,
    parentRef,
  }: {
    ref: Project.GenericRef<Project.RequestVariable>
    parentRef: Project.GenericRef<Project.Request>
  },
): Project.GenericRef<Project.RequestVariable> => {
  const request = getRequest(parentRef, objects, false)

  // remove reference to this Request Variable
  if (request.variables) {
    request.variables = request.variables.filter(
      (itemRef) => itemRef.ref !== ref.ref,
    )
  }

  // delete the Request Variable
  return deleteObject(objects, root, { ref })
}

export default deleteRequestVariable
