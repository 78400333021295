import { Project } from 'lib/project/types.d'
import deleteObject from './delete-object'
import { getRequest } from '../getters'

export interface DeleteKeyValueArgs {
  ref: Project.GenericRef<Project.KeyValue>
  parentRef: Project.GenericRef<Project.Request>
  parentProperty: Project.GetListKeys<Project.Request>
}

const deleteKeyValue = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { ref, parentRef, parentProperty }: DeleteKeyValueArgs,
): Project.GenericRef<Project.KeyValue> => {
  const request = getRequest(parentRef, objects, false)

  // remove reference to this Key Value
  if (request[parentProperty]) {
    request[parentProperty] = request[parentProperty].filter(
      (itemRef) => itemRef.ref !== ref.ref,
    )
  }

  // delete the Key Value
  return deleteObject(objects, root, { ref })
}

export default deleteKeyValue
