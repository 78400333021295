import { Project } from 'lib/project/types.d'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'
import filterArrayNonNull from './filter-array-non-null'
import loadObjectRef from './load-object-ref'

const loadAnything = (
  loader: CloudLoader.ProjectLoader,
  object: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  // primitives
  if (
    typeof object === 'string' ||
    typeof object === 'number' ||
    typeof object === 'boolean'
  ) {
    return object
  }

  // arrays, it can be anything, just map the values
  if (object && Array.isArray(object)) {
    return filterArrayNonNull(
      (object || []).map((item) => loadAnything(loader, item)),
    )
  }

  // objects are considered to be refs
  if (object && typeof object === 'object') {
    return loadObjectRef(
      loader,
      object as Project.GenericRef<Project.AnyObject>,
    )
  }

  return null
}

export default loadAnything
