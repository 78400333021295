import { RootState } from 'store/root-reducer'

/**
 * A "selector factory" that creates a selector for tab selection
 * Note: for performance reasons, selector factories should be memoized
 * in the component that is calling it.
 * @param identifier The tab bar identifier
 * @param defaultTab The default tab to return
 * @param tabs List of available tabs, so we never return something that's not a valid tab
 * @returns A selector for the selected/current tab
 */
// eslint-disable-next-line import/prefer-default-export
export const currentTabSelectorFactory = (
  identifier: string,
  defaultTab: string,
  tabs: string[],
) => (store: RootState): string => {
  /*
   * Save preference for all tabs hierarchically,
   * for example identifier "requests/UUID" will
   * save preference for "requests" alone and
   * "requests/UUID". This way, the setting
   * will apply for all requests if nothing specific
   * is set for another request.
   */
  const identifierSplit = identifier.split('/')
  for (let i = 0; i < identifierSplit.length; i += 1) {
    const subId = identifierSplit
      .slice(0, identifierSplit.length - i)
      .map((slice) => slice.trim())
      .join('/')
    const tabSystem = store.uiState.tabsState.tabSystems[subId]
    if (
      tabSystem &&
      tabSystem.currentTab &&
      tabs.indexOf(tabSystem.currentTab) >= 0
    ) {
      return tabSystem.currentTab
    }
  }
  return defaultTab
}
