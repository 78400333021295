import { Project } from 'lib/project/types.d'
import getImplementations from './get-implementations'
import { DynamicValues } from './types.d'

const dict = getImplementations().reduce((acc, impl) => {
  acc[impl.identifier] = impl
  return acc
}, {} as Project.ObjectMap<unknown>)

const getImplementation = <T extends Project.DynamicValue>(
  dv: T,
): DynamicValues.Implementation<T> | null => {
  const impl = dict[dv.identifier]
  if (!impl) {
    return null
  }
  return impl as DynamicValues.Implementation<T>
}

export default getImplementation
