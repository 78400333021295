/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { SpaceProps } from 'styled-system'
import { Flex } from 'reflexbox'

import { Project } from 'lib/project'
import { useParameterObject } from 'utils/hooks'

import { DataTable, DataTableField } from '@rapidapi/ui-lib'
import { DynamicStringEditor } from 'ecosystems/field-editors/dynamic-string-editor'
import useChangeItemData from 'store/hooks/use-change-item-data.hook'
import useAddParameterValue from 'store/hooks/use-add-parameter-value.hook'
import useChangeOrder from 'store/hooks/use-change-order.hook'
import useGetDataTableItems from './use-get-data-table-items.hook'

type ParameterListEditorProps<T extends Project.TypesWithLists> = SpaceProps & {
  objectRef: Project.GenericRef<T>
  objectProperty: Project.GetListKeys<T>
  label?: string
}

type ParameterListEditorReturnType = React.ReactElement<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> | null

const fields: DataTableField<Project.Parameter>[] = [
  {
    key: 'Name',
    value: (data) =>
      (data?.key && (
        <DynamicStringEditor
          dynamicStringRef={data.key}
          placeholder="Add Name"
          hover
        />
      )) ??
      '',
  },
  {
    key: 'Value',
    value: (data) =>
      (data?.value && (
        <DynamicStringEditor
          dynamicStringRef={data.value}
          placeholder="Add Value"
          hover
        />
      )) ??
      '',
  },
]

const useItemDataSelectorList = (
  item: Project.GenericRef<Project.KeyValue>,
) => {
  const data = useParameterObject(item)

  return {
    uuid: data.uuid,
    data,
    disabled: false,
    checked: data.enabled,
  }
}

const ParameterListEditor = <T extends Project.TypesWithLists>({
  objectRef,
  objectProperty,
  ...props
}: ParameterListEditorProps<T>): ParameterListEditorReturnType => {
  const dataTableItems = useGetDataTableItems(objectRef, objectProperty)
  // updates the enabled/disabled status
  const onChangeItemData = useChangeItemData()
  // adds a new item at the end of the table
  const addParameterValue = useAddParameterValue(objectRef, objectProperty)
  // changes items order
  const onChangeOrder = useChangeOrder(objectRef, objectProperty)
  return (
    <Flex flexDirection="column">
      <DataTable
        {...props}
        fields={fields}
        items={dataTableItems}
        itemDataSelector={useItemDataSelectorList}
        checkboxProps={{ marginTop: '4px' }}
        onChangeItemData={onChangeItemData}
        onChangeOrder={onChangeOrder}
        lastRow={{
          placeholders: ['Add Name', 'Add Value'],
          onClick: addParameterValue,
        }}
        hasCheckbox
        reorderable
      />
    </Flex>
  )
}

export default ParameterListEditor
