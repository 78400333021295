import config from 'lib/cloud-sync/cloud-api/config'
import { Project } from 'lib/project'
import { FetchResult } from './fetch-types.d'

/**
 * Fetch direct using fetch() function.
 * @param path Path to fetch (base URL is https://paw.cloud/ or configured in .env)
 * @param method Method, if omitted, GET.
 * @param headers Headers
 * @param body Body to include
 */
const webFetch = async (
  path: string,
  method?: string,
  headers?: Project.ObjectMap<string>,
  body?: string,
): Promise<FetchResult> => {
  const { API_ENDPOINT } = config
  const res = await fetch(`${API_ENDPOINT}${path}`, {
    method: method || 'GET',
    credentials: 'omit',
    mode: 'cors',
    headers,
    body,
  })
  return {
    ok: res.ok,
    status: res.status,
    statusText: res.statusText,
    body: {
      text: () => res.text(),
      json: () => res.json(),
    },
  }
}

export default webFetch
