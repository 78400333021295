import { createSelector } from '@reduxjs/toolkit'

import { Project } from 'lib/project/types.d'
import { RootState } from 'store/root-reducer'
import { selectCurrentTreeItemRef } from 'store/slices/ui/selectors'
import {
  findRequestTreeItemParent,
  getAuthHeaderDynamicValue,
  getDynamicString,
  getDynamicValue,
  getEnvironmentDomain,
  getEnvironmentVariable,
  getOnlyDynamicValue,
  isAuthEmptyOrPristine,
  isBodyEmptyOrPristine,
} from 'lib/project/getters'
import { ProjectState } from '../types.d'

export const selectProject = (state: RootState): ProjectState => state.project

export const selectProjectRootRef = createSelector(
  selectProject,
  (project) => project.root,
)

export const selectProjectObjects = createSelector(
  selectProject,
  (project) => project.objects,
)

export const selectProjectRoot = createSelector(
  selectProjectObjects,
  selectProjectRootRef,
  (objects, ref): Project.Project | null =>
    ref ? (objects[ref.ref] as Project.Project) : null,
)

export const selectProjectIsLoaded = createSelector(
  selectProjectRoot,
  (root) => !!root,
)

/**
 * A "selector factory" that creates a selector for the given ref.
 * Note: for performance reasons, selector factories should be memoized
 * in the component that is calling it.
 * @param ref An object ref
 * @returns A selector for that object
 */
export const selectProjectRefFactory = <T extends Project.AnyObject>({
  ref,
}: Project.GenericRef<T>): ((state: RootState) => T) =>
  createSelector(selectProjectObjects, (objects): T => objects[ref] as T)

/**
 * A "selector factory" that creates a selector for the given ref.
 * Note: for performance reasons, selector factories should be memoized
 * in the component that is calling it.
 * Version of `selectProjectRefFactory` that supports optional.
 * @param ref An object ref (optional)
 * @returns A selector for that object
 */
export const selectProjectOptionalRefFactory = <T extends Project.AnyObject>(
  ref: Project.GenericRef<T> | null | undefined,
): ((state: RootState) => T | null) =>
  createSelector(
    selectProjectObjects,
    (objects): T | null => (ref && (objects[ref.ref] as T)) || null,
  )

/**
 * A "selector factory" that creates a selector for an array of refs.
 * Note: for performance reasons, selector factories should be memoized
 * in the component that is calling it.
 * @param refs An array of object refs
 * @returns A selector for these object refs
 */
export const selectProjectRefArrayFactory = <T extends Project.AnyObject>(
  refs: Project.GenericRef<T>[],
): ((state: RootState) => T[]) =>
  createSelector(selectProjectObjects, (objects): T[] =>
    refs.map(({ ref }) => objects[ref] as T),
  )

/**
 * A "selector factory" that creates a selector for the given ref.
 * Note: for performance reasons, selector factories should be memoized
 * in the component that is calling it.
 * @param ref An object ref
 * @returns A selector for that object
 */
export const selectProjectPropertyValueFactory = <T extends Project.AnyObject>(
  { ref }: Project.GenericRef<T>,
  property: keyof T,
  expect?: 'string' | 'number' | 'boolean',
  allowsNull?: boolean,
): ((state: RootState) => unknown | null) =>
  createSelector(selectProjectObjects, (objects): unknown | null => {
    const obj = objects[ref] as T
    if (!obj) {
      return null
    }
    const value = obj[property]
    if ((value === undefined || value === null) && allowsNull) {
      return null
    }
    if (expect && typeof value !== expect) {
      throw new Error(
        `[selectProjectPropertyValueFactory] Expected type ${expect}, got ${typeof value}`,
      )
    }
    return value
  })

/**
 * A "selector factory" that creates a selector for the given ref.
 * Note: for performance reasons, selector factories should be memoized
 * in the component that is calling it.
 * @param ref An object ref
 * @returns A selector for that object
 */
export const selectProjectPropertyValueRefFactory = <
  T extends Project.AnyObject
>(
  { ref }: Project.GenericRef<T>,
  property: keyof T,
  isOptional?: boolean,
): ((state: RootState) => Project.AnyObject | null) =>
  createSelector(selectProjectObjects, (objects): Project.AnyObject | null => {
    const obj = objects[ref] as T
    if (!obj) {
      return null
    }
    const value = (obj[property] as unknown) as Project.GenericRef<
      Project.AnyObject
    >
    if ((value === undefined || value === null || !value.ref) && isOptional) {
      return null
    }
    const resultObj = objects[value.ref]
    if (!resultObj && !isOptional) {
      throw new Error(
        `Cannot find object in reference ${property} of object ${ref}`,
      )
    }
    return resultObj
  })

export const selectAllEnvironmentVariables = createSelector(
  selectProjectObjects,
  selectProjectRoot,
  (objects, root): Project.EnvironmentVariable[] => {
    const a = root?.environmentDomains.map((domainRef) => {
      const domain = getEnvironmentDomain(domainRef, objects, false)
      return domain.variables.map((variableRef) =>
        getEnvironmentVariable(variableRef, objects, false),
      )
    })
    return a ? a.flat() : []
  },
)

export const selectAllRequests = createSelector(
  selectProjectObjects,
  selectProjectRoot,
  (objects, root): Project.Request[] => {
    if (!root) {
      return []
    }

    const flattenRequests = (
      itemRefs: Project.GenericRef<Project.AnyRequestTreeItem>[],
    ) => {
      const flat: Project.Request[] = []

      itemRefs.forEach((itemRef) => {
        const item = objects[itemRef.ref]
        if (item.type === 'group') {
          flat.push(...flattenRequests(item.children))
        } else {
          flat.push(item as Project.Request)
        }
      })

      return flat
    }

    return flattenRequests(root.requests)
  },
)

export const selectProjectRequestListProps = createSelector(
  selectProjectObjects,
  selectProjectRootRef,
  (objects, root): string[] | null =>
    root
      ? (objects[root.ref] as Project.Project).requests.map(({ ref }) => ref)
      : null,
)

export const selectCurrentTreeItem = createSelector(
  selectProjectObjects,
  selectCurrentTreeItemRef,
  (objects, treeItemRef): Project.AnyRequestTreeItem | null =>
    treeItemRef
      ? (objects[treeItemRef.ref] as Project.AnyRequestTreeItem)
      : null,
)

export const selectCurrentRequest = createSelector(
  selectProjectObjects,
  selectCurrentTreeItemRef,
  (objects, treeItemRef): Project.Request | null => {
    const treeItem = treeItemRef ? objects[treeItemRef.ref] : null
    if (treeItem?.type !== 'request') {
      return null
    }
    return treeItem as Project.Request
  },
)

export const selectCurrentRequestRef = createSelector(
  selectProjectObjects,
  selectCurrentTreeItemRef,
  (objects, treeItemRef): Project.GenericRef<Project.Request> | null => {
    const treeItem = treeItemRef ? objects[treeItemRef.ref] : null
    if (treeItem?.type !== 'request') {
      return null
    }
    return treeItemRef
  },
)

export const selectCurrentRequestBodyDynamicValueRef = createSelector(
  selectProjectObjects,
  selectCurrentRequest,
  (objects, request): Project.GenericRef<Project.DynamicValue> | null => {
    // get the body string's dynamic string
    const bodyString = request?.bodyString
    if (!bodyString) {
      return null
    }
    const ds = getDynamicString(bodyString, objects, false)

    // get the only dynamic value (nor null)
    return getOnlyDynamicValue(ds)
  },
)

export const selectCurrentRequestBodyDynamicValueIdentifier = createSelector(
  selectProjectObjects,
  selectCurrentRequestBodyDynamicValueRef,
  (objects, onlyDvRef): string | null => {
    // get the only dynamic value (nor null)
    if (!onlyDvRef) {
      return null
    }

    // get the dynamic value object
    const dv = getDynamicValue(onlyDvRef, objects, false)
    return dv.identifier
  },
)

export const selectCurrentRequestBodyDynamicValuesFactory = (): ((
  state: RootState,
) => Project.DynamicValue[]) =>
  createSelector(
    selectProjectObjects,
    selectCurrentRequest,
    (objects, request) => {
      const bodyString = request?.bodyString
      if (!bodyString) {
        return []
      }

      const ds = getDynamicString(bodyString, objects, false)

      return ds?.strings.reduce((acc, s) => {
        if (typeof s !== 'string') {
          const dv = getDynamicValue(s, objects, false)
          acc.push(dv)
        }

        return acc
      }, [] as Project.DynamicValue[])
    },
  )

export const selectCurrentRequestBodyDynamicStringFactory = (): ((
  state: RootState,
) => Project.DynamicString | null) =>
  createSelector(
    selectProjectObjects,
    selectCurrentRequest,
    (objects, request) => {
      const bodyString = request?.bodyString
      if (!bodyString) {
        return null
      }
      return getDynamicString(bodyString, objects, false)
    },
  )

export const selectBodyIsEmptyFactory = (
  ref: Project.GenericRef<Project.Request>,
): ((state: RootState) => boolean) =>
  createSelector(selectProjectObjects, (objects): boolean =>
    isBodyEmptyOrPristine(ref, objects),
  )

export const selectCurrentRequestAuthDynamicValueRef = createSelector(
  selectProjectObjects,
  selectCurrentRequest,
  (objects, request): Project.GenericRef<Project.DynamicValue> | null =>
    request ? getAuthHeaderDynamicValue({ ref: request.uuid }, objects) : null,
)

export const selectCurrentRequestAuthDynamicValueIdentifier = createSelector(
  selectProjectObjects,
  selectCurrentRequestAuthDynamicValueRef,
  (objects, onlyDvRef): string | null => {
    // get the only dynamic value (nor null)
    if (!onlyDvRef) {
      return null
    }

    // get the dynamic value object
    const dv = getDynamicValue(onlyDvRef, objects, false)
    return dv.identifier
  },
)

export const selectAuthIsEmptyFactory = (
  ref: Project.GenericRef<Project.Request>,
): ((state: RootState) => boolean) =>
  createSelector(selectProjectObjects, (objects): boolean =>
    isAuthEmptyOrPristine(ref, objects),
  )

export const selectParentForNewRequestTreeItem = createSelector(
  selectProjectObjects,
  selectProjectRootRef,
  selectCurrentTreeItem,
  (
    objects,
    root,
    currentTreeItem,
  ): Project.GenericRef<Project.RequestGroup> | null => {
    // a request is selected, we pick the same parent
    if (currentTreeItem && currentTreeItem.type === 'request') {
      const parentRef =
        (root &&
          findRequestTreeItemParent(
            { ref: currentTreeItem.uuid },
            objects,
            root,
          )) ||
        null
      return parentRef
    }

    // a group is selected, we insert into this group
    if (currentTreeItem && currentTreeItem.type === 'group') {
      return { ref: currentTreeItem.uuid }
    }

    // no selection, return null (root)
    return null
  },
)
