import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import { assertType, loadAnything } from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

const loadDynamicString = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.DynamicString => {
  // check type
  assertType(object, 'dynamicString')

  const ds: Project.DynamicString = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'dynamicString',
    strings: loadAnything(loader, object.strings),
  }
  return ds
}

export default loadDynamicString
