import { getUuid, base64Encode } from 'lib/utils'
import { Evaluation } from 'lib/evaluation/types.d'
import { RequestHandling } from 'lib/request-handling/types.d'
import { getHTTPExchangeRequest } from 'lib/request-handling/utils'
import config from 'config'

interface CFProxyRequestHeader {
  name: string
  value: string
}

interface CFProxyRequest {
  method: string
  url: string
  httpVersion: 'HTTP/1.1'
  headers: CFProxyRequestHeader[]
  postData: {
    text: string
    encoding: 'base64'
  }
}

interface CFProxyBody {
  request: CFProxyRequest
}

interface CFProxyResponseInfo {
  status: number
  statusText: string
  responseTime: number
  headers: CFProxyRequestHeader[]
}

interface CFProxyReponsePayload {
  info: CFProxyResponseInfo
  body: {
    text: string
    encoding: 'base64'
  }
  date: string
  downloadTime: number
}

const getCfProxyBody = (req: Evaluation.EvaluatedRequest): CFProxyBody => {
  let { url } = req
  if (url.indexOf('://') === -1) {
    url = `http://${url}`
  }
  const cfRequest: CFProxyRequest = {
    method: req.method,
    url,
    httpVersion: 'HTTP/1.1',
    headers: req.headers.map(
      ({ name, value }): CFProxyRequestHeader => ({
        name,
        value,
      }),
    ),
    postData: {
      text: btoa(encodeURIComponent(req.body)),
      encoding: 'base64',
    },
  }

  const cfProxyBody: CFProxyBody = {
    request: cfRequest,
  }

  return cfProxyBody
}

const sendCfProxyRequest = async (
  cfProxyBody: CFProxyBody,
): Promise<CFProxyReponsePayload> => {
  // send and measure time
  const startTime = new Date()

  const response = await fetch(config.cfProxy.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(cfProxyBody),
    mode: 'cors',
    credentials: 'omit',
    cache: 'no-cache',
    redirect: 'manual',
  })

  const completeTime = new Date()

  if (!response.ok) {
    throw new Error('An error occured with Paw Cloud Proxy')
  }

  // CloudflareProxy forwards the destination headers via the special
  // `paw-proxy-headers` header
  // This allows the body to stay as it is and be streamed by the CF Proxy
  const pph = response.headers.get('paw-proxy-headers')
  if (!pph) {
    throw new Error('Missing `paw-proxy-headers` header')
  }
  const info = JSON.parse(decodeURIComponent(atob(pph))) as CFProxyResponseInfo

  // get body
  const bodyArrayBuffer = await response.arrayBuffer()
  const base64Body = base64Encode(new Uint8Array(bodyArrayBuffer))

  // return our payload
  return {
    info,
    body: {
      text: base64Body,
      encoding: 'base64',
    },
    date: startTime.toISOString(),
    downloadTime: (completeTime.valueOf() - startTime.valueOf()) / 1000,
  }
}

const cfProxySender = async (
  concreteRequest: RequestHandling.ConcreteRequest,
): Promise<RequestHandling.ConcreteRequest> => {
  // get evaluated request
  if (!concreteRequest || !concreteRequest.evaluatedRequest) {
    throw new Error('Missing evaluatedRequest')
  }

  // get CF proxy request body
  const cfProxyBody = getCfProxyBody(concreteRequest.evaluatedRequest)

  // send request
  let httpExchange: RequestHandling.HTTPExchange
  try {
    const { info, body, date, downloadTime } = await sendCfProxyRequest(
      cfProxyBody,
    )

    // create our final HTTPExchange
    httpExchange = {
      uuid: getUuid(),
      projectUuid: concreteRequest.projectUuid,
      requestUuid: concreteRequest.requestUuid,
      library: 'cf',
      request: getHTTPExchangeRequest(concreteRequest.evaluatedRequest),
      response: {
        status: info.status,
        statusText: info.statusText,
        headers: info.headers,
        body,
      },
      errorCode: -1,
      errorData: '',
      date,
      responseTime: info.responseTime,
      downloadTime,
    }
  } catch (error) {
    // create HTTP Exchange
    httpExchange = {
      uuid: getUuid(),
      projectUuid: concreteRequest.projectUuid,
      requestUuid: concreteRequest.requestUuid,
      library: 'cf',
      request: getHTTPExchangeRequest(concreteRequest.evaluatedRequest),
      response: null,
      errorCode: 1,
      errorData: error.message || String(error),
      responseTime: 0,
      downloadTime: 0,
      date: new Date().toISOString(),
    }
  }

  return {
    ...concreteRequest,
    httpExchange,
  }
}

export default cfProxySender
