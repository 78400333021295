import { Project } from 'lib/project/types.d'
import { getDynamicString, getDynamicValue, getRequest } from '../atoms'
import { getOnlyDynamicValue } from '../dynamic-value-functions'

const getRequestBodyDynamicValue = (
  ref: Project.GenericRef<Project.Request>,
  objects: Project.ObjectMap,
): Project.DynamicValue | null => {
  const request = getRequest(ref, objects, false)
  const bodyString = request.bodyString
    ? getDynamicString(request.bodyString, objects, true)
    : null
  if (!bodyString) {
    return null
  }
  const onlyDynamicValueRef = getOnlyDynamicValue(bodyString)
  if (!onlyDynamicValueRef) {
    return null
  }
  return getDynamicValue(onlyDynamicValueRef, objects, false)
}

export default getRequestBodyDynamicValue
