import { DynamicValues } from 'lib/dynamic-values/types.d'
import { Evaluation, evaluateBody } from 'lib/evaluation'
import { getRequestLayer } from 'lib/evaluation/layers'
import { getRequest } from 'lib/project'
import { RequestRawBodyDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.RequestRawBodyDynamicValue'
const title = 'Request Raw Body'

const defaultValue: RequestRawBodyDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  request: null,
}

const editForm: DynamicValues.EditForm<RequestRawBodyDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'request',
      fieldType: 'request',
      label: 'Request',
    },
  ],
}

function getRequestRef(requestUUID: string | null, ctx: Evaluation.Ctx) {
  return requestUUID ? { ref: requestUUID } : getRequestLayer(ctx)?.requestRef
}

const implRequestRawBodyDynamicValue: DynamicValues.Implementation<RequestRawBodyDynamicValueInterface> = {
  title,
  identifier,
  defaultValue,
  editForm,
  getAllRefs() {
    return null
  },
  getEvaluatedString: async ({ request: requestUUID }, ctx) => {
    const requestRef = getRequestRef(requestUUID, ctx)
    if (!requestRef) {
      return ''
    }

    const request = getRequest(requestRef, ctx.project.objects, true)
    if (!request) {
      return ''
    }

    return evaluateBody(request, ctx)
  },
  getTokenInfo: async ({ request: requestUUID }, ctx) => {
    const requestRef = getRequestRef(requestUUID, ctx)
    if (requestRef) {
      const requestObject = getRequest(requestRef, ctx.project.objects, true)
      if (requestObject) {
        return {
          title,
          text: requestObject.title || null,
        }
      }
    }
    return {
      title,
      text: '',
    }
  },
}

export default implRequestRawBodyDynamicValue
