import { DynamicValues } from 'lib/dynamic-values/types.d'
import { evaluateDynamicString } from 'lib/evaluation'
import { getRequestVariable } from 'lib/project'
import { RequestVariableDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.RequestVariableDynamicValue'

const defaultValue: RequestVariableDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  variableUUID: '',
}

const editForm: DynamicValues.EditForm<RequestVariableDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'variableUUID',
      fieldType: 'requestVariable',
      label: 'Variable',
    },
  ],
}

const implRequestVariableDynamicValue: DynamicValues.Implementation<RequestVariableDynamicValueInterface> = {
  title: 'Request Variable',
  identifier,
  defaultValue,
  editForm,
  getAllRefs() {
    return null
  },
  getEvaluatedString: async ({ variableUUID }, ctx) => {
    if (!variableUUID) {
      return ''
    }
    const variable = getRequestVariable(
      { ref: variableUUID },
      ctx.project.objects,
      true,
    )
    if (!variable) {
      return ''
    }
    if (!variable.value) {
      return ''
    }
    return evaluateDynamicString(variable.value, ctx)
  },
  getTokenInfo: async ({ variableUUID }, ctx) => {
    if (variableUUID) {
      const variable = getRequestVariable(
        { ref: variableUUID },
        ctx.project.objects,
        true,
      )
      if (variable) {
        return {
          title: variable.name || null,
          text: null,
        }
      }
    }
    return {
      title: 'Missing Variable',
      text: '',
    }
  },
}

export default implRequestVariableDynamicValue
