import { setProject } from 'store/actions'
import { PrimaryWorkerState } from 'worker/primary-worker/implementation/types.d'
import { NotificationHandler } from '../types.d'

const handledidImportRequests = async ({
  notification,
  next,
}: NotificationHandler<PrimaryWorkerState.ProjectStateObjects>): Promise<
  void
> => {
  next(
    setProject({
      ...notification.payload,
    }),
  )
}

export default handledidImportRequests
