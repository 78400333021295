import { Project } from 'lib/project/types.d'

const getAllObjectOfType = (
  objects: Project.ObjectMap,
  type: Project.ObjectTypes,
): Project.GenericRef<Project.AnyObject>[] =>
  Object.entries(objects)
    .filter(([, { type: aType }]) => type === aType)
    .map(([ref]): Project.GenericRef<Project.AnyObject> => ({ ref }))

export default getAllObjectOfType
