import React, { useCallback, useMemo } from 'react'
import { Flex } from 'reflexbox'

import { RequestTabViewProps } from 'ecosystems/project/request-editor/request-tabs/request-tabs-props.d'
import { TabBar } from '@rapidapi/ui-lib'
import {
  selectCurrentRequestBodyDynamicStringFactory,
  selectCurrentRequestBodyDynamicValueIdentifier,
  selectCurrentRequestBodyDynamicValuesFactory,
  selectProjectObjects,
} from 'store/selectors'
import { applyProjectSetter } from 'store/actions'
import { useCurrentRequestRef } from 'utils/hooks'

import { useSelector, useStore } from 'store/hooks'
import {
  getBodyTabKey,
  bodyTabComponents,
  getNewBodyDynamicValue,
  convertBodyDynamicValue,
} from './body-tabs-functions'
import { BodyTabKey } from './body-tabs-types.d'

const renderBodyTypeTab = (tabKey: BodyTabKey): JSX.Element => {
  const Tab = bodyTabComponents[tabKey]
  if (!Tab) {
    throw new Error(`[RequestBodyTab] Missing tab for key ${tabKey}`)
  }
  return <Tab />
}

const RequestBodyTab: React.FC<RequestTabViewProps> = () => {
  // get the body's dynamic value type (if only one dynamic value)
  // if we use a JSON, Form URL-Encoded, Multipart, File, or GraphQL body
  // it's gonna be a dynamic value
  // otherwise, it's gonna be a text field
  const requestRef = useCurrentRequestRef()
  const bodyOnlyDvIdentifier = useSelector(
    selectCurrentRequestBodyDynamicValueIdentifier,
  )
  const store = useStore()

  const tabs = useMemo(
    () => ({
      text: 'Text',
      json: 'JSON',
      jsonTree: 'JSON Tree',
      urlEncoded: 'Form URL-Encoded',
      multipart: 'Multipart',
      // file: 'File',
      gqlQuery: 'GraphQL',
    }),
    [],
  )

  const currentTab = useMemo(() => getBodyTabKey(bodyOnlyDvIdentifier), [
    bodyOnlyDvIdentifier,
  ])

  const setCurrentTab = useCallback(
    (tabValue: string) => {
      if (!requestRef || currentTab === tabValue) {
        return
      }

      const state = store.getState()

      const objects = selectProjectObjects(state)
      const currentBodyDynamicString = selectCurrentRequestBodyDynamicStringFactory()(
        state,
      )
      const currentBodyDynamicValues = selectCurrentRequestBodyDynamicValuesFactory()(
        state,
      )

      const { inserts, strings } = currentBodyDynamicString
        ? convertBodyDynamicValue({
            from: currentTab,
            to: tabValue as BodyTabKey,
            currentBodyDynamicString,
            currentBodyDynamicValues,
            objects,
          })
        : getNewBodyDynamicValue(tabValue as BodyTabKey)

      if (strings === null) {
        return
      }

      store.dispatch(
        applyProjectSetter('updateDynamicString')({
          parentRef: requestRef,
          parentProperty: 'bodyString',
          strings,
          inserts,
        }),
      )
    },
    [requestRef, currentTab, store],
  )

  return (
    <>
      <TabBar
        tabs={tabs}
        variant="segment"
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        mt={5}
        px={5}
      />
      <Flex flexBasis={0} flexGrow={1} overflow="hidden" flexDirection="column">
        {renderBodyTypeTab(currentTab as BodyTabKey)}
      </Flex>
    </>
  )
}

export default RequestBodyTab
