import { addAlpha } from 'utils'
import { darkGray } from '../colors'

const fills = {
  primary: addAlpha(darkGray[500], 0.36),
  secondary: addAlpha(darkGray[500], 0.32),
  tertiary: addAlpha(darkGray[500], 0.24),
  quanternary: addAlpha(darkGray[500], 0.12),
}

export default fills
