import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import { useHistory } from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  ProgressIndicator,
  TextField,
  Typography,
  Notification,
} from '@rapidapi/ui-lib'
import { useCreateProject, useGetAccountInfo, useGetTeamList } from 'lib-hooks'
import TeamSelect from './team-select'

interface CreateProjectDialogProps {
  isDialogOpen: boolean
  onDialogClose?: () => void
}

const CreateProjectDialog: React.FC<CreateProjectDialogProps> = ({
  isDialogOpen,
  onDialogClose,
}) => {
  const {
    status: accountStatus,
    response: accountResponse,
    error: accountError,
  } = useGetAccountInfo()
  const {
    status: teamListStatus,
    response: teamListResponse,
    error: teamListError,
  } = useGetTeamList()

  // loading
  const isLoading = accountStatus === 'loading' || teamListStatus === 'loading'
  const isLoadingFailed =
    accountStatus === 'failed' || teamListStatus === 'failed'
  const isLoaded =
    accountStatus === 'succeeded' && teamListStatus === 'succeeded'

  // team ID
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null)
  const effectiveElectedTeamId = useMemo(() => {
    if (selectedTeamId) {
      return selectedTeamId
    }
    // eslint-disable-next-line camelcase
    return accountResponse?.personal_team?.id || null
  }, [selectedTeamId, accountResponse])

  // project name
  const [projectName, setProjectName] = useState('')

  // create project
  const {
    start,
    status: createProjectStatus,
    response: createProjectResponse,
    error: createProjectError,
  } = useCreateProject()

  const isSubmitDisabled = !effectiveElectedTeamId || !projectName.trim()

  const onSubmit = useCallback(() => {
    if (isSubmitDisabled || effectiveElectedTeamId === null) {
      return
    }
    start(projectName.trim(), effectiveElectedTeamId)
  }, [isSubmitDisabled, effectiveElectedTeamId, start, projectName])

  // redirect once project is created
  const history = useHistory()
  useEffect(() => {
    if (
      createProjectResponse &&
      createProjectResponse.id &&
      createProjectStatus === 'succeeded'
    ) {
      history.push(`/projects/${createProjectResponse.id}`)
    }
  }, [history, createProjectStatus, createProjectResponse])

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onDialogClose}
      width="30vw"
      height="auto"
      minWidth={300}
      maxWidth={900}
      animation="none"
    >
      <DialogTitle onClose={onDialogClose} pl={1} divider>
        Create Project
      </DialogTitle>
      <DialogContent p={0} mb={-2}>
        {isLoading && (
          <Flex justifyContent="center" my={2}>
            <ProgressIndicator
              size="default"
              variant="indeterminate"
              type="circular"
            />
          </Flex>
        )}
        {isLoadingFailed && (
          <Flex justifyContent="center" my={2}>
            <Notification
              width="100%"
              icon="alert"
              variant="error"
              title="Loading error"
              m={0}
            >
              {accountError?.message || teamListError?.message || false}
            </Notification>
          </Flex>
        )}
        {isLoaded && accountResponse && teamListResponse && (
          <Box>
            {createProjectStatus === 'failed' && createProjectError && (
              <Notification
                width="100%"
                icon="alert"
                variant="error"
                title="Failed to create project"
                m={0}
                mb={3}
              >
                {createProjectError?.message || false}
              </Notification>
            )}
            <TextField
              my={2}
              width="100%"
              label="Project Name"
              value={projectName}
              onChange={setProjectName}
              onPressEnter={onSubmit}
            />
            <Box
              my={2}
              onKeyPressCapture={(event) => {
                if (event.key === 'Enter' && !isSubmitDisabled) {
                  onSubmit()
                }
              }}
            >
              <Typography variant="body2" mb={1} color="primary">
                Team
              </Typography>
              <TeamSelect
                accountResponse={accountResponse}
                teamListResponse={teamListResponse}
                selectedTeamId={effectiveElectedTeamId}
                setSelectedTeamId={setSelectedTeamId}
              />
            </Box>
            <Box mt={3} mb={2} alignItems="center">
              <Button
                variant="primary"
                onClick={onSubmit}
                type="submit"
                disabled={isSubmitDisabled}
                loading={createProjectStatus === 'loading'}
              >
                Create Project
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CreateProjectDialog
