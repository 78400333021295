import { equals } from 'ramda'

import { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'
import { Project } from 'lib/project/types.d'
import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'

import serializeProject from './serialize-project'

const getSyncTreePatch = (
  baseSyncTree: CloudAPI.SyncTree,
  syncTree: CloudAPI.SyncTree,
): CloudAPI.SyncPatch => {
  const toAdd: CloudAPI.SyncTree = {}
  const toDelete: string[] = []
  const existingItems = new Set<string>()

  /* iterate over the base tree to detect changes in items also present in
   * target tree, or find deleted items inside of target tree
   */
  Object.entries(baseSyncTree).forEach(([uuid, item]) => {
    const newItem = syncTree[uuid]
    // exists
    if (newItem) {
      if (!equals(newItem, item)) {
        // console.log(
        //   `Updated item type ${item._type}`,
        //   '\n',
        //   JSON.stringify(newItem),
        //   '\n',
        //   JSON.stringify(item),
        // )
        toAdd[uuid] = newItem
      }
      existingItems.add(uuid)
    }
    // deleted
    else {
      toDelete.push(uuid)
    }
  })

  /* iterate over the target tree to add the new items (were not present at
   * all in the base tree)
   */
  Object.entries(syncTree).forEach(([uuid, item]) => {
    // if item is in new tree but not among the existing items, add it now
    if (!existingItems.has(uuid)) {
      toAdd[uuid] = item
    }
  })

  const patch: CloudAPI.SyncPatch = {
    add: toAdd,
    delete: toDelete,
  }
  return patch
}

const serializeProjectManifest = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  manifestBase?: CloudSerializer.ManifestBase,
): CloudSerializer.ManifestResult => {
  // get serializer
  const serializer = serializeProject(objects, root)

  let patch: CloudAPI.SyncPatch
  let baseCommitSha: string | null
  if (manifestBase) {
    baseCommitSha = manifestBase.baseCommitSha
    patch = getSyncTreePatch(manifestBase.baseSyncTree, serializer.syncTree)
  } else {
    baseCommitSha = null
    patch = {
      add: serializer.syncTree,
    }
  }

  const manifest: CloudAPI.SyncManifest = {
    base_commit: baseCommitSha,
    patch,
  }
  const manifestResult: CloudSerializer.ManifestResult = {
    manifest,
    manifestBase,
    syncTree: serializer.syncTree,
  }
  return manifestResult
}

export default serializeProjectManifest
