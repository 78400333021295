import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  assertType,
  serializeObjectNullableRef,
} from 'lib/cloud-sync/serializer/helpers'
import { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

import serializeDynamicString from './serialize-dynamic-string'

const serializeParameter = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.Parameter,
): CloudAPI.SyncTreeItem => {
  // check type
  assertType(object, 'parameter')

  const syncTreeItem: CloudAPI.SyncTreeItem = {
    _uuid: object.uuid,
    _type: 'parameter',
    enabled: object.enabled,
    key: serializeObjectNullableRef(
      serializer,
      object.key as Project.GenericRef<Project.DynamicString>,
      serializeDynamicString,
    ),
    value: serializeObjectNullableRef(
      serializer,
      object.value as Project.GenericRef<Project.DynamicString>,
      serializeDynamicString,
    ),
  }
  return syncTreeItem
}

export default serializeParameter
