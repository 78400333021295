// keep the v4 object key name so we can mock and statically declare value for unit tests
import { v4 } from 'uuid'

export const getUuid = (): string => v4().toLowerCase()

export const isUuid4 = (s: string): boolean =>
  !!s.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/,
  )

export const getAllUuidsFromString = (s: string): string[] | null => {
  const uuid = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g
  return s.match(uuid)
}
