import { setProject } from 'store/actions'
import { PrimaryWorkerState } from 'worker/primary-worker/implementation/types.d'
import { NotificationHandler } from '../types.d'

const handleDidUpdateSyncBranches = async ({
  notification,
  next,
}: NotificationHandler<PrimaryWorkerState.ProjectStateStatusReturn>): Promise<
  void
> => {
  const {
    syncBranch,
    syncCommitMeta,
    syncBranches,
    realtimeIsConnected,
    cloudPendingChangesCount,
  } = notification.payload

  next(
    setProject({
      syncState: {
        syncBranch,
        syncCommitMeta,
        syncBranches,
        realtimeIsConnected,
        cloudPendingChangesCount,
      },
    }),
  )
}

export default handleDidUpdateSyncBranches
