import { addAlpha } from 'utils'
import { common, blue, darkRed, green } from '../colors'

const borders = {
  default: addAlpha(common.white, 0.07),
  accent: blue[200],
  error: darkRed[200],
  success: green[200],
}

export default borders
