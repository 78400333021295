import { DynamicValues } from 'lib/dynamic-values/types.d'
import { evaluateDynamicString } from 'lib/evaluation/evaluate'
import { Evaluation } from 'lib/evaluation/types.d'
import { LocalValueDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.LocalValueDynamicValue'

const defaultValue: LocalValueDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  defaultValue: null,
}

const implLocalValueDynamicValue: DynamicValues.Implementation<LocalValueDynamicValueInterface> = {
  title: 'Local Value',
  identifier,
  defaultValue,
  getAllRefs(dv) {
    // eslint-disable-next-line react/destructuring-assignment
    if (dv.defaultValue) {
      return [dv.defaultValue]
    }
    return null
  },
  getEvaluatedString: async (
    dv: LocalValueDynamicValueInterface,
    ctx: Evaluation.Ctx,
  ) => {
    const value = dv.defaultValue
      ? evaluateDynamicString(dv.defaultValue, ctx)
      : ''
    // @TODO handle real local values, not only the default
    return value
  },
  getTokenInfo: async (
    dv: LocalValueDynamicValueInterface,
    ctx: Evaluation.Ctx,
  ) => ({
    // @TODO Should Obfuscate secure values
    title: 'Local Value',
    text: dv.defaultValue
      ? await evaluateDynamicString(dv.defaultValue, ctx)
      : null,
  }),
}

export default implLocalValueDynamicValue
