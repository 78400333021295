import { parseUrlEncodedObject } from 'utils'
import { AppFetch } from 'utils/app-fetch/app-fetch-types.d'

interface GetOAuth2AccessTokenFromResponseResult {
  accessToken: string
  refreshToken: string | null
}

const getOAuth2AccessTokenFromResponse = async (
  response: AppFetch.AppFetchResponse,
  strict?: boolean,
): Promise<GetOAuth2AccessTokenFromResponseResult> => {
  /* 4.1.4. | 4.3.3. | 4.4.3. Access Token Response
   * https://tools.ietf.org/html/rfc6749#section-4.1.4
   * https://tools.ietf.org/html/rfc6749#section-4.3.3
   * https://tools.ietf.org/html/rfc6749#section-4.4.3
   *
   * If the access token request is valid and authorized, the
   * authorization server issues an access token and optional refresh
   * token as described in Section 5.1.  If the request client
   * authentication failed or is invalid, the authorization server returns
   * an error response as described in Section 5.2.
   *
   * An example successful response:
   *
   * HTTP/1.1 200 OK
   * Content-Type: application/json;charset=UTF-8
   * Cache-Control: no-store
   * Pragma: no-cache
   *
   * {
   *   "access_token":"2YotnFZFEjr1zCsicMWpAA",
   *   "token_type":"example",
   *   "expires_in":3600,
   *   "refresh_token":"tGzv3JOkF0XG5Qx2TlKWIA",
   *   "example_parameter":"example_value"
   * }
   *
   *
   * 5.1.  Successful Response
   * http://tools.ietf.org/html/rfc6749#section-5.1
   *
   * The authorization server issues an access token and optional refresh
   * token, and constructs the response by adding the following parameters
   * to the entity-body of the HTTP response with a 200 (OK) status code:
   *
   * access_token
   *   REQUIRED.  The access token issued by the authorization server.
   *
   * token_type
   *   REQUIRED.  The type of the token issued as described in
   *   Section 7.1.  Value is case insensitive.
   *
   * expires_in
   *   RECOMMENDED.  The lifetime in seconds of the access token.  For
   *   example, the value "3600" denotes that the access token will
   *   expire in one hour from the time the response was generated.
   *   If omitted, the authorization server SHOULD provide the
   *   expiration time via other means or document the default value.
   *
   * refresh_token
   *   OPTIONAL.  The refresh token, which can be used to obtain new
   *   access tokens using the same authorization grant as described
   *   in Section 6.
   *
   * scope
   *   OPTIONAL, if identical to the scope requested by the client;
   *   otherwise, REQUIRED.  The scope of the access token as
   *
   * The parameters are included in the entity-body of the HTTP response
   * using the "application/json" media type as defined by [RFC4627].  The
   * parameters are serialized into a JavaScript Object Notation (JSON)
   * structure by adding each parameter at the highest structure level.
   * Parameter names and string values are included as JSON strings.
   * Numerical values are included as JSON numbers.  The order of
   * parameters does not matter and can vary.
   *
   * The client MUST ignore unrecognized value names in the response.  The
   * sizes of tokens and other values received from the authorization
   * server are left undefined.  The client should avoid making
   * assumptions about value sizes.  The authorization server SHOULD
   * document the size of any value it issues.
   */

  if (!response.ok) {
    throw new Error(
      `Failed to load OAuth 2 access token (HTTP ${response.status})`,
    )
  }

  // get body object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let body: any = null
  const headers = new Headers(response.headers)
  const contentType = headers.get('content-type')
  if (contentType && contentType.match(/^application\/json/)) {
    body = await response.body.json()
  } else if (
    contentType &&
    contentType.match(/^application\/x-www-form-urlencoded/)
  ) {
    const bodyText = await response.body.text()
    body = parseUrlEncodedObject(bodyText)
  }

  if (!body || typeof body !== 'object') {
    throw new Error('Failed to parse OAuth 2 access token response body')
  }

  // Get tokens
  const newAccessToken =
    typeof body.access_token === 'string' ? body.access_token : null
  const newRefreshToken =
    typeof body.refresh_token === 'string' ? body.refresh_token : null

  // Check if an Access Token is found
  if (!newAccessToken) {
    throw new Error('No access token found in the response')
  }

  // Check "token_type"
  if (
    strict &&
    (typeof body.token_type !== 'string' ||
      body.token_type.toLowerCase() !== 'bearer')
  ) {
    throw new Error('Invalid token type (consider disabling strict mode)')
  }

  return {
    accessToken: newAccessToken,
    refreshToken: newRefreshToken || null,
  }
}

export default getOAuth2AccessTokenFromResponse
