import { PPWPayloadBase, PPWMessage, PPWActionCreator } from '../types.d'

const createWorkerAction = <
  RequestPayload extends PPWPayloadBase = PPWPayloadBase,
  ResponsePayload extends PPWPayloadBase = PPWPayloadBase,
  Type extends string = string
>(
  type: Type,
): PPWActionCreator<Type, RequestPayload, ResponsePayload> => {
  function requestMessageCreator(
    payload: RequestPayload,
  ): PPWMessage<Type, RequestPayload> {
    return { type, payload }
  }
  function responseMessageCreator(
    payload: ResponsePayload,
  ): PPWMessage<Type, ResponsePayload> {
    return { type, payload }
  }

  return {
    type,
    createRequest: requestMessageCreator,
    createResponse: responseMessageCreator,
    matchRequest: (
      message: PPWMessage<Type, PPWPayloadBase>,
    ): message is PPWMessage<Type, RequestPayload> => message.type === type,
    matchResponse: (
      message: PPWMessage<Type, PPWPayloadBase>,
    ): message is PPWMessage<Type, ResponsePayload> => message.type === type,
  }
}

export default createWorkerAction
