/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react'
import { Box, BoxProps } from 'reflexbox'
import { useSelector, useDispatch } from 'react-redux'
import { SpaceProps } from 'styled-system'

import { tabsStateActions } from 'store/actions'
import { currentTabSelectorFactory } from 'store/selectors'

import { TabBar } from '@rapidapi/ui-lib'
import { Project } from 'lib/project'

export type TabBarContainerConfig = SpaceProps & {
  identifier: string
  tabs: Project.ObjectMap<string>
  defaultTab: string
}

type TabBarContainerProps = BoxProps & {
  config: TabBarContainerConfig
  tabAlign?: string
  renderTab: (tabValue: string) => JSX.Element | null | false
  variant?: 'tab' | 'segment'
  tabBarProps?: SpaceProps
}

const TabBarContainer: React.FC<TabBarContainerProps> = ({
  config,
  tabAlign,
  renderTab,
  variant = 'tab',
  tabBarProps,
  ...more
}) => {
  const { tabs, identifier } = config

  const currentTabSelector = useMemo(
    () =>
      currentTabSelectorFactory(
        config.identifier,
        config.defaultTab,
        Object.keys(tabs),
      ),
    [config, tabs],
  )
  const currentTab = useSelector(currentTabSelector)

  const dispatch = useDispatch()
  const setCurrentTab = useCallback(
    (tab) => {
      dispatch(
        tabsStateActions.setCurrentTab({
          identifier,
          currentTab: tab,
        }),
      )
    },
    [identifier, dispatch],
  )

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Box {...(more as any)}>
      <TabBar
        tabs={tabs}
        tabAlign={tabAlign}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        variant={variant}
        {...tabBarProps}
      />
      {renderTab(currentTab)}
    </Box>
  )
}

export default TabBarContainer
