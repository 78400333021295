/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from 'react'
import { A, Button, Tag, Typography } from '@rapidapi/ui-lib'
import { Box } from 'reflexbox'
import { ContextLinkInfo, ContextLinks } from './types.d'

const DownloadContent: React.FC<ContextLinks> = ({
  content,
  primaryLinks,
  secondaryLinks,
  trigger,
}) => (
  <>
    <Box maxWidth={400} mx="auto" mb={5} textAlign="center">
      {content}
    </Box>

    <Box textAlign="center" mb={5} pb={5} flexBasis="100%">
      {primaryLinks.map((item, index) => (
        <Button
          mx={2}
          size="large"
          key={index.toString()}
          data-label={item.label}
          data-target={item.link}
          onClick={() => trigger(item.link)}
        >
          {item.label}
        </Button>
      ))}
    </Box>

    <Box textAlign="center">
      <Typography
        variant="caps"
        fontWeight="bold"
        color="secondary"
        display="block"
        mb={5}
      >
        Other Downloads
      </Typography>

      {Object.keys(secondaryLinks).map((os, osIndex) => (
        <React.Fragment key={osIndex.toString()}>
          {secondaryLinks[os].length > 0 && (
            <Typography
              variant="caps"
              fontWeight="bold"
              display="block"
              mt={5}
              mb={2}
            >
              {os}
            </Typography>
          )}
          <Box width={420} mx="auto" mb={5}>
            {secondaryLinks[os].map((item, index) => (
              <Tag key={index.toString()} m={1}>
                <A download target="_blank" href={item.link}>
                  {item.label}
                </A>
              </Tag>
            ))}
          </Box>
        </React.Fragment>
      ))}
    </Box>
  </>
)

const DownloadSectionContent: React.FC<{
  content?: string
  userAgent?: string
  includeElectronMac?: boolean
  targets: ContextLinkInfo[]
}> = ({
  content = '',
  userAgent = navigator.userAgent as string,
  targets,
  includeElectronMac,
}) => {
  const macNative =
    (targets &&
      targets.filter((item: ContextLinkInfo) => item.os === 'macNative')) ||
    []

  const mac =
    (targets &&
      targets.filter((item: ContextLinkInfo) => item.os === 'macos')) ||
    []

  const windows =
    (targets &&
      targets.filter((item: ContextLinkInfo) => item.os === 'windows')) ||
    []

  const linux =
    (targets &&
      targets.filter((item: ContextLinkInfo) => item.os === 'linux')) ||
    []

  const trigger = useCallback((url: string) => {
    window.location.assign(url)
  }, [])

  if (userAgent.toLowerCase().indexOf('linux') !== -1) {
    return (
      <DownloadContent
        {...{
          content,
          trigger,
          primaryLinks: [...linux].slice(2, 4),
          secondaryLinks: {
            'mac os': [...macNative, ...mac],
            windows,
            linux,
          },
        }}
      />
    )
  }

  if (userAgent.toLowerCase().indexOf('win') !== -1) {
    return (
      <DownloadContent
        {...{
          content,
          trigger,
          primaryLinks: windows,
          secondaryLinks: {
            'mac os': [...macNative, ...mac],
            windows: [],
            linux,
          },
        }}
      />
    )
  }

  return (
    <DownloadContent
      {...{
        content,
        trigger,
        primaryLinks: macNative,
        secondaryLinks: {
          'mac os': includeElectronMac ? mac : [],
          windows,
          linux,
        },
      }}
    />
  )
}

export default DownloadSectionContent
