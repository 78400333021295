import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'reflexbox'

import { settingsStateActions } from 'store/actions'
import { selectTheme } from 'store/selectors'
import { Icon, A, Tooltip } from '@rapidapi/ui-lib'
import { IconSymbol } from 'themes'

const ThemeButton: React.FC = () => {
  const currentTheme = useSelector(selectTheme)
  const themeIcon = useMemo((): IconSymbol => {
    if (currentTheme === 'light') {
      return 'theme-light'
    }

    if (currentTheme === 'dark') {
      return 'theme-dark'
    }

    return 'adjustment'
  }, [currentTheme])

  const dispatch = useDispatch()
  const onChangeTheme = useCallback(() => {
    const themeModes = ['light', 'dark', 'auto'] as const
    const currentIndex = themeModes.indexOf(currentTheme)
    const nextIndex = (currentIndex + 1) % themeModes.length
    dispatch(settingsStateActions.setTheme(themeModes[nextIndex]))
  }, [dispatch, currentTheme])

  return (
    <Tooltip content={`Theme setting (${currentTheme})`} placement="right">
      <Box py={2}>
        {/**  @todo change to  button, once `IconButton` supports the visual output the sidebar component requires.  */}
        <A variant="secondary" displayInline={false} onClick={onChangeTheme}>
          <Icon symbol={themeIcon} size={24} />
        </A>
      </Box>
    </Tooltip>
  )
}

export default ThemeButton
