import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  assertType,
  loadObjectNullableRef,
  loadObjectRefArray,
  loadPrimitiveBoolean,
  loadPrimitiveNumber,
  loadPrimitiveString,
} from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

import loadDynamicString from './load-dynamic-string'
import loadKeyValue from './load-key-value'
import loadRequestVariable from './load-request-variable'

const loadRequest = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.Request => {
  // check type
  assertType(object, 'request')

  const request: Project.Request = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'request',
    title: loadPrimitiveString(object.title),
    summary: loadPrimitiveString(object.summary, null),
    method: loadObjectNullableRef(
      loader,
      object.method as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
    urlFull: loadObjectNullableRef(
      loader,
      object.urlFull as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
    bodyString: loadObjectNullableRef(
      loader,
      object.bodyString as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
    headers: loadObjectRefArray(
      loader,
      object.headers as Project.GenericRef<Project.KeyValue>[],
      loadKeyValue,
    ),
    urlParameters: loadObjectRefArray(
      loader,
      object.urlParameters as Project.GenericRef<Project.KeyValue>[],
      loadKeyValue,
    ),
    variables: loadObjectRefArray(
      loader,
      object.variables as Project.GenericRef<Project.RequestVariable>[],
      loadRequestVariable,
    ),
    followRedirects: loadPrimitiveBoolean(object.followRedirects),
    redirectAuthorization: loadPrimitiveBoolean(object.redirectAuthorization),
    redirectMethod: loadPrimitiveBoolean(object.redirectMethod),
    timeoutInterval: loadPrimitiveNumber(object.timeoutInterval, null),
    sendCookies: loadPrimitiveBoolean(object.sendCookies),
    storeCookies: loadPrimitiveBoolean(object.storeCookies),
    clientCertificate: loadObjectNullableRef(
      loader,
      object.clientCertificate as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
  }
  return request
}

export default loadRequest
