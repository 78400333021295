import { Project } from 'lib/project'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { applyProjectSetter } from 'store/actions'

const useAddParameterValue = <T extends Project.AnyObject>(
  objectRef: Project.GenericRef<T>,
  objectProperty: keyof T,
): (() => void) => {
  const dispatch = useDispatch()
  return useCallback(() => {
    if (!objectRef || !objectRef.ref) {
      return
    }
    dispatch(
      applyProjectSetter<T>('addParameter')({
        objectRef,
        objectProperty,
      }),
    )
  }, [dispatch, objectRef, objectProperty])
}
export default useAddParameterValue
