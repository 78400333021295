import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { DataTable, DataTableField, DataTableItem } from '@rapidapi/ui-lib'
import { selectHttpExchangeForCurrentRequest } from 'store/selectors'
import { getHttpMessageBodyBinary } from 'lib/request-handling'
import { getHumanFileSize } from 'utils'
import TabBoxContainer from '../tab-box-container'

type HttpExchangeItem = {
  id: string
  name: string
  value: string
}

const fields: DataTableField<HttpExchangeItem>[] = [
  { key: 'Info', value: (item) => item?.name },
  { key: 'Value', value: (item) => item?.value },
]

const HttpExchangeInfoTab: React.FC = () => {
  const httpExchange = useSelector(selectHttpExchangeForCurrentRequest)

  const httpExchangeItems = useMemo(() => {
    if (!httpExchange) {
      return []
    }

    return [
      {
        uuid: 'date',
        data: {
          id: 'date',
          name: 'Date',
          value: httpExchange.date,
        },
      },
      {
        uuid: 'url',
        data: {
          id: 'url',
          name: 'URL',
          value: httpExchange.request.url,
        },
      },
      ...(httpExchange.response
        ? [
            {
              uuid: 'status',
              data: {
                id: 'status',
                name: 'Status',
                value: `${httpExchange.response.status} ${httpExchange.response.statusText}`,
              },
            },
          ]
        : []),
      {
        uuid: 'library',
        data: {
          id: 'library',
          name: 'Library',
          value: ((l): string => {
            switch (l) {
              case 'cf':
                return 'Paw Cloud Proxy'
              case 'pa':
                return 'Paw Local Proxy'
              default:
                return l
            }
          })(httpExchange.library),
        },
      },
      {
        uuid: 'responseTime',
        data: {
          id: 'responseTime',
          name: 'Headers Response Time',
          value: `${Math.round(httpExchange.responseTime * 1000)} ms`,
        },
      },
      {
        uuid: 'downloadTime',
        data: {
          id: 'downloadTime',
          name: 'Total Response Time',
          value: `${Math.round(httpExchange.downloadTime * 1000)} ms`,
        },
      },
      {
        uuid: 'responseBodySize',
        data: {
          id: 'responseBodySize',
          name: 'Response Body Size',
          value: `${getHumanFileSize(
            (httpExchange.response &&
              getHttpMessageBodyBinary(httpExchange.response)?.length) ||
              0,
          )}`,
        },
      },
    ] as DataTableItem<HttpExchangeItem>[]
  }, [httpExchange])

  return (
    <TabBoxContainer p={5} scrollable>
      <DataTable fields={fields} items={httpExchangeItems} />
    </TabBoxContainer>
  )
}

export default HttpExchangeInfoTab
