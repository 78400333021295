import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectProjectRefFactory,
  selectProjectRefArrayFactory,
  selectCurrentRequest,
  selectProjectPropertyValueFactory,
  selectProjectPropertyValueRefFactory,
  selectProjectOptionalRefFactory,
  selectCurrentRequestRef,
} from 'store/selectors'
import { Project } from 'lib/project/types.d'

export const useCurrentRequest = (): Project.Request | null =>
  useSelector(selectCurrentRequest)

export const useCurrentRequestRef = (): Project.GenericRef<
  Project.Request
> | null => {
  const requestRef = useSelector(selectCurrentRequestRef)
  const requestUuid = requestRef?.ref
  return useMemo(() => (requestUuid ? { ref: requestUuid } : null), [
    requestUuid,
  ])
}
export const useAnyObject = (
  ref: Project.GenericRef<Project.AnyObject>,
): Project.AnyObject => {
  const selector = useMemo(() => selectProjectRefFactory(ref), [ref])
  return useSelector(selector)
}

export const useAnyObjectProperty = <T extends Project.AnyObject>(
  ref: Project.GenericRef<T>,
  property: keyof T,
  expect?: 'string' | 'number' | 'boolean',
  allowsNull?: boolean,
): unknown | null => {
  const selector = useMemo(
    () => selectProjectPropertyValueFactory(ref, property, expect, allowsNull),
    [ref, property, expect, allowsNull],
  )
  return useSelector(selector)
}

export const useRequestObject = (
  ref: Project.GenericRef<Project.Request>,
): Project.Request => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectProjectRefFactory(ref), [ref.ref])
  return useSelector(selector)
}

export const useGroupObject = (
  ref: Project.GenericRef<Project.RequestGroup>,
): Project.RequestGroup => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectProjectRefFactory(ref), [ref.ref])
  return useSelector(selector)
}

export const useKeyValueObject = (
  ref: Project.GenericRef<Project.KeyValue>,
): Project.KeyValue => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectProjectRefFactory(ref), [ref.ref])
  return useSelector(selector)
}

export const useDynamicStringObject = (
  ref: Project.GenericRef<Project.DynamicString>,
): Project.DynamicString => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectProjectRefFactory(ref), [ref.ref])
  return useSelector(selector)
}

export const useDynamicStringOptionalObject = (
  ref: Project.GenericRef<Project.DynamicString> | null | undefined,
): Project.DynamicString | null => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectProjectOptionalRefFactory(ref), [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ref?.ref,
  ])
  return useSelector(selector)
}

export const useDynamicStringOptionalFromRef = <T extends Project.AnyObject>(
  dynamicStringRef:
    | Project.GenericRef<Project.DynamicString>
    | null
    | undefined,
  objectRef: Project.GenericRef<T> | null | undefined,
  objectProperty: keyof T | null | undefined,
): Project.DynamicString | null => {
  const selector = useMemo(() => {
    if (objectRef && objectProperty) {
      return selectProjectPropertyValueRefFactory(
        objectRef,
        objectProperty,
        true,
      )
    }
    return selectProjectOptionalRefFactory(dynamicStringRef)
  }, [objectRef, objectProperty, dynamicStringRef])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useSelector(selector) as any
}

export const useDynamicValueObject = <T>(
  ref: Project.GenericRef<Project.DynamicValue<T>>,
): Project.DynamicValue<T> => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectProjectRefFactory(ref), [ref.ref])
  return useSelector(selector)
}

export const useParameterObject = (
  ref: Project.GenericRef<Project.Parameter>,
): Project.Parameter => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectProjectRefFactory(ref), [ref.ref])
  return useSelector(selector)
}

export const useEnvironmentDomainObject = (
  ref: Project.GenericRef<Project.EnvironmentDomain>,
): Project.EnvironmentDomain => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectProjectRefFactory(ref), [ref.ref])
  return useSelector(selector)
}

export const useEnvironmentVariableObject = (
  ref: Project.GenericRef<Project.EnvironmentVariable>,
): Project.EnvironmentVariable => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectProjectRefFactory(ref), [ref.ref])
  return useSelector(selector)
}

export const useObjectArray = <T extends Project.AnyObject>(
  refs: Project.GenericRef<T>[],
): T[] => {
  const selector = useMemo(() => selectProjectRefArrayFactory(refs), [refs])
  return useSelector(selector)
}
