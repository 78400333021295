import React from 'react'
import { Flex } from 'reflexbox'

import { useCurrentRequest, useKeyValueObject } from 'utils/hooks'
import { Project } from 'lib/project'
import { DataTable, DataTableField, IconButton } from '@rapidapi/ui-lib'
import { RequestTabViewProps } from 'ecosystems/project/request-editor/request-tabs/request-tabs-props.d'
import { DynamicStringEditor } from 'ecosystems/field-editors'
import { TabBoxContainer } from 'ecosystems/http-exchange-viewer/http-exchange-tabs'
import useChangeOrder from 'store/hooks/use-change-order.hook'
import useAddKeyValue from 'store/hooks/use-add-key-value.hook'
import useChangeItemData from 'store/hooks/use-change-item-data.hook'
import useDeleteKeyValue from 'store/hooks/use-delete-key-value.hook'

const fields: DataTableField<Project.KeyValue>[] = [
  {
    key: 'Query Param',
    value: (data) =>
      (data?.name && (
        <DynamicStringEditor
          dynamicStringRef={data.name}
          placeholder="Add Query Param"
          autocompleteCategory="LMCompletionCategoryHTTPRequestParameters"
          hover
        />
      )) ??
      '',
  },
  {
    key: 'Value',
    value: (data) =>
      (data?.value && (
        <DynamicStringEditor
          dynamicStringRef={data.value}
          placeholder="Add Value"
          hover
        />
      )) ??
      '',
  },
]

const useItemDataSelectorList = (
  item: Project.GenericRef<Project.KeyValue>,
) => {
  const data = useKeyValueObject(item)

  return {
    uuid: data.uuid,
    data,
    disabled: false,
    checked: data.enabled,
  }
}

const RequestQueryTab: React.FC<RequestTabViewProps> = () => {
  const request = useCurrentRequest()
  const urlParameters = request?.urlParameters.map((data) => ({
    uuid: data.ref,
    data,
  }))

  // updates the enabled/disabled status
  const onChangeItemData = useChangeItemData()

  // adds a new item at the end of the table
  const addNewKeyValue = useAddKeyValue(
    { ref: request ? request.uuid : '' },
    'urlParameters',
  )

  // deletes an item
  const deleteKeyValue = useDeleteKeyValue(
    { ref: request ? request.uuid : '' },
    'urlParameters',
  )

  // changes items order
  const onChangeOrder = useChangeOrder<Project.Request>(
    { ref: request ? request.uuid : '' },
    'urlParameters',
  )

  return (
    <TabBoxContainer p={5} scrollable>
      <Flex flexDirection="column">
        <DataTable
          fields={fields}
          items={urlParameters}
          itemDataSelector={useItemDataSelectorList}
          checkboxProps={{ marginTop: '5px' }}
          onChangeItemData={onChangeItemData}
          endAdornment={(item) => (
            <IconButton
              icon="close"
              size="small"
              type="button"
              onClick={() => deleteKeyValue(item.uuid)}
            />
          )}
          onChangeOrder={onChangeOrder}
          lastRow={{
            placeholders: ['Add Query Param', 'Add Value'],
            onClick: addNewKeyValue,
          }}
          hasCheckbox
          reorderable
        />
      </Flex>
    </TabBoxContainer>
  )
}

export default RequestQueryTab
