/* eslint-disable react/jsx-props-no-spreading */
import css from '@styled-system/css'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Flex } from 'reflexbox'
import styled from 'themes'
import { ProgressIndicator, Button, Typography } from '@rapidapi/ui-lib'
import {
  getActivePreviewId,
  subscribe,
  initFromSearch,
  getShareUrl,
  switchToMainAppFromPreviewEffect,
} from './dev-previews'
import AppHeader, { AppHeaderProps } from './app-header'

export type AppTemplatePageProps = {
  title: string
  description?: string
}

export type AppTemplateProps = {
  appHeaderProps?: AppHeaderProps
  page?: AppTemplatePageProps
  loading?: boolean
}

const TemplateStyled = styled(Flex)({
  width: '100%',
  maxWidth: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  display: 'grid',
  gridTemplateAreas: '"header aside main"',
  gridTemplateColumns: '[header] min-content [aside] min-content [main] auto',
  gridTemplateRows: '1fr',
  flex: 1,
})

const PreviewBox = styled(Box)(({ theme: { colors } }) =>
  css({
    color: colors.common.white,
    bg: colors.blue['200'],
    padding: 1,
  }),
)

const initialPreviewId = getActivePreviewId()

const AppTemplate: React.FC<AppTemplateProps> = ({
  page = { title: 'Paw Cloud' },
  loading = false,
  appHeaderProps,
  children,
}) => {
  const [state, setState] = React.useState<{
    previewId: string | undefined
  }>({
    previewId: initialPreviewId,
  })

  React.useEffect(() => {
    const sub = subscribe((e) => {
      if (e.detail.id) {
        setState({
          previewId: e.detail.id,
        })
      } else {
        setState({
          previewId: undefined,
        })
      }
    })

    return () => {
      sub.unsub()
    }
  }, [])

  React.useEffect(() => {
    initFromSearch()
  }, [])

  const title = useMemo(() => {
    const titles: string[] = []
    if (page.title) {
      titles.push(page.title)
    }
    if (state.previewId) {
      titles.unshift(state.previewId)
    }
    titles.push('Paw Cloud')
    return titles.join(' | ')
  }, [page.title, state.previewId])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {state.previewId && (
        <PreviewBox display="flex" alignItems="center">
          <Typography
            color="inherit"
            style={{
              whiteSpace: 'pre',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {state.previewId}
          </Typography>
          <Button
            ml="auto"
            onClick={() => {
              // eslint-disable-next-line no-console
              navigator.clipboard.writeText(getShareUrl()).catch(console.error)
            }}
          >
            Copy Preview URL
          </Button>
          <Button variant="danger" onClick={switchToMainAppFromPreviewEffect}>
            Exit Preview
          </Button>
        </PreviewBox>
      )}

      <TemplateStyled>
        <AppHeader {...appHeaderProps} />
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            mt="30vh"
            width="100%"
            style={{ gridArea: 'main' }}
          >
            <ProgressIndicator variant="indeterminate" type="linear" />
          </Box>
        ) : (
          children
        )}
      </TemplateStyled>
    </>
  )
}

export default AppTemplate
