/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AppendHTTPExchangeForRequestPayload,
  DeleteHttpExchangePayload,
  HTTPExchangesState,
  MoveCursorCurrentHttpExchangePayload,
  SetHTTPExchangesForRequestPayload,
  SetCurrentHttpExchangeForRequestPayload,
} from './types.d'

const initialState: HTTPExchangesState = {
  httpExchanges: {},
  currentHttpExchange: {},
}

const httpExchangesSlice = createSlice({
  name: 'httpExchanges',
  initialState,
  reducers: {
    setHttpExchangesForRequest: (
      state: HTTPExchangesState,
      {
        payload: { requestUuid, httpExchanges },
      }: PayloadAction<SetHTTPExchangesForRequestPayload>,
    ) => {
      state.httpExchanges[requestUuid] = httpExchanges

      // reset current http exchange if not already there
      const currentHttpExchange = state.currentHttpExchange[requestUuid]
      if (
        httpExchanges.length > 0 &&
        (!currentHttpExchange ||
          httpExchanges.findIndex((item) => item.uuid === currentHttpExchange) <
            0)
      ) {
        const [httpExchange] = httpExchanges
        state.currentHttpExchange[requestUuid] = httpExchange.uuid
      }
    },
    appendHttpExchangesForRequest: (
      state: HTTPExchangesState,
      {
        payload: { requestUuid, httpExchange },
      }: PayloadAction<AppendHTTPExchangeForRequestPayload>,
    ) => {
      if (state.httpExchanges[requestUuid]) {
        state.httpExchanges[requestUuid].unshift(httpExchange)
      } else {
        state.httpExchanges[requestUuid] = [httpExchange]
      }
      state.currentHttpExchange[requestUuid] = httpExchange.uuid
    },
    removeHttpExchangeForRequest: (
      state: HTTPExchangesState,
      {
        payload: { requestUuid, httpExchangeUuid },
      }: PayloadAction<DeleteHttpExchangePayload>,
    ) => {
      state.httpExchanges[requestUuid] =
        state.httpExchanges[requestUuid]?.filter(
          ({ uuid }) => uuid !== httpExchangeUuid,
        ) || []
    },
    setCurrentHttpExchange: (
      state: HTTPExchangesState,
      {
        payload: { requestUuid, httpExchangeUuid },
      }: PayloadAction<SetCurrentHttpExchangeForRequestPayload>,
    ) => {
      state.currentHttpExchange[requestUuid] = httpExchangeUuid
    },
    moveCursorCurrentHttpExchange: (
      state: HTTPExchangesState,
      {
        payload: { requestUuid, diff },
      }: PayloadAction<MoveCursorCurrentHttpExchangePayload>,
    ) => {
      const currentHttpExchange = state.currentHttpExchange[requestUuid]
      const httpExchanges = state.httpExchanges[requestUuid]
      if (!httpExchanges) {
        return
      }
      const currentIndex = currentHttpExchange
        ? httpExchanges.findIndex(({ uuid }) => uuid === currentHttpExchange)
        : -1
      let newSelection
      if (currentIndex < 0) {
        if (diff < 0) {
          newSelection = httpExchanges[0].uuid
        } else {
          newSelection = httpExchanges[httpExchanges.length - 1].uuid
        }
      } else {
        newSelection =
          httpExchanges[
            (httpExchanges.length + currentIndex + diff) % httpExchanges.length
          ].uuid
      }
      state.currentHttpExchange[requestUuid] = newSelection
    },
  },
})

export default httpExchangesSlice
