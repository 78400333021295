/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react'
import { SpaceProps } from 'styled-system'
import { Box, Flex } from 'reflexbox'
import { useDispatch } from 'react-redux'

import { RadioButton, Typography } from '@rapidapi/ui-lib'
import { Project } from 'lib/project'
import { setProjectValue } from 'store/actions'
import {
  useAnyObjectProperty,
  useCurrentRequest,
  useObjectArray,
} from 'utils/hooks'

type RequestVariablePickerEditorProps<
  T extends Project.AnyObject
> = SpaceProps & {
  objectRef: Project.GenericRef<T>
  objectProperty: keyof T
  label?: string
}

type RequestVariablePickerEditorReturnType = React.ReactElement<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> | null

const RequestVariablePickerEditor = <T extends Project.AnyObject>({
  objectRef,
  objectProperty,
  label,
  ...props
}: RequestVariablePickerEditorProps<
  T
>): RequestVariablePickerEditorReturnType => {
  const request = useCurrentRequest()
  const variables = useObjectArray(request?.variables || [])

  // get selected variable
  const variableUuid = useAnyObjectProperty(
    objectRef,
    objectProperty,
    'string' /* expect */,
    true /* allowsNull */,
  ) as string

  // dispatch update
  const dispatch = useDispatch()
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newVariableUuid = event.target.value
      if (newVariableUuid) {
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              [objectProperty]: newVariableUuid,
            },
          }),
        )
      }
    },
    [dispatch, objectRef, objectProperty],
  )

  return (
    <Box>
      {label && (
        <Typography variant="body2" mb={1} color="primary">
          {label}
        </Typography>
      )}
      <Box maxHeight={220} overflow="auto">
        <Flex flexDirection="column" alignItems="stretch" {...props}>
          {variables.map(({ uuid, name, summary }) => (
            <Box key={uuid} py={1}>
              <RadioButton
                type="radio"
                label={name}
                hint={summary || undefined}
                checked={uuid === variableUuid}
                value={uuid}
                onChange={onChange}
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default RequestVariablePickerEditor
