/* eslint-disable no-param-reassign */
import { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'
import addObject from './add-object'
import { getObject } from '../getters'

export interface InsertAndSetRefArgs<ChildT extends Project.AnyObject> {
  parentRef: Project.GenericRef<Project.AnyObject>
  parentProperty: string
  child: ChildT
}

const insertAndSetRef = <ChildT extends Project.AnyObject>(
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { parentRef, parentProperty, child }: InsertAndSetRefArgs<ChildT>,
): void => {
  // insert child first
  const childRef = addObject(objects, {
    ...child,
    uuid: child.uuid || getUuid(),
  })

  // update dynamic string
  const parent = getObject(parentRef, objects, false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(parent as any)[parentProperty] = childRef
}

export default insertAndSetRef
