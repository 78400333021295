import { createAction } from '@reduxjs/toolkit'
import { Project } from 'lib/project'

export const userActionAddRequestAction = createAction<{
  parent?: Project.GenericRef<Project.RequestGroup> | null
  url?: string
}>('userActions/addRequestAction')

export const userActionAddRequestGroupAction = createAction<{
  isRootElement?: boolean
  parent?: Project.GenericRef<Project.RequestGroup> | null
}>('userActions/addRequestGroupAction')

export const userActionAddEnvironmentDomainAction = createAction(
  'userActions/addEnvironmentDomainAction',
)

export const userActionDeleteRequestTreeItemAction = createAction<{
  ref: Project.GenericRef<Project.AnyRequestTreeItem>
}>('userActions/deleteRequestTreeItem')

export const userActionDeleteCurrentRequestTreeItemAction = createAction(
  'userActions/deleteCurrentRequestTreeItem',
)

export const userActionDuplicateRequestAction = createAction<{
  requestRef?: Project.GenericRef<Project.Request>
}>('userActions/duplicateRequest')
