/**
 * `cloud-api` contains all methods to connect with the Paw Cloud API (https://paw.cloud),
 * including authentication and project loading and updating.
 *
 * Submodules:
 * - `fetch`: lower-level functions to send HTTP requests to the API, either
 *   directly or via the Electron Proxy.
 * - `api-fetch`: send requests for the Paw Cloud v3 JSON API (https://paw.cloud/api/v3/)
 *   requests are authenticated
 * - `endpoints`: higher-level functions to perform specific actions (e.g. get an access token,
 *   fetch project data, push project update, etc.)
 */

export * from './endpoints'
export * from './types.d'
