import { Project } from 'lib/project/types.d'

import addRequest from './add-request'
import addRequestGroup from './add-request-group'
import addEnvironmentDomain from './add-environment-domain'
import addEnvironment from './add-environment'
import addEnvironmentVariable from './add-environment-variable'
import addDynamicString from './add-dynamic-string'
import addDynamicValue from './add-dynamic-value'
import addKeyValue from './add-key-value'
import addParameter from './add-parameter'
import deleteObject from './delete-object'
import deleteRequest from './delete-request'
import deleteRequestGroup from './delete-request-group'
import deleteRequestTreeItem from './delete-request-tree-item'
import deleteKeyValue from './delete-key-value'
import deleteRequestVariable from './delete-request-variable'
import deleteEnvironment from './delete-environment'
import deleteEnvironmentDomain from './delete-environment-domain'
import deleteEnvironmentVariable from './delete-environment-variable'
import duplicateAnything from './duplicate-anything'
import duplicateDynamicString from './duplicate-dynamic-string'
import duplicateDynamicValue from './duplicate-dynamic-value'
import duplicateObject from './duplicate-object'
import duplicateParameter from './duplicate-parameter'
import duplicateRequest from './duplicate-request'
import duplicateKeyValue from './duplicate-key-value'
import duplicateEnvironment from './duplicate-environment'
import updateDynamicString from './update-dynamic-string'
import updateRequestAuthDynamicString from './update-request-auth-dynamic-string'
import updateKeyValueList from './update-key-value-list'
import updateRequestsList from './update-requests-list'
import insertAndSetRef from './insert-and-set-ref'
import insertDynamicValues from './insert-dynamic-values'

const namedSetters = {
  addRequest,
  addRequestGroup,
  addEnvironmentDomain,
  addEnvironment,
  addEnvironmentVariable,
  addDynamicString,
  addDynamicValue,
  addKeyValue,
  addParameter,
  deleteObject,
  deleteRequest,
  deleteRequestGroup,
  deleteRequestTreeItem,
  deleteKeyValue,
  deleteRequestVariable,
  deleteEnvironment,
  deleteEnvironmentDomain,
  deleteEnvironmentVariable,
  duplicateAnything,
  duplicateDynamicString,
  duplicateDynamicValue,
  duplicateObject,
  duplicateParameter,
  duplicateRequest,
  duplicateKeyValue,
  updateDynamicString,
  updateRequestAuthDynamicString,
  updateKeyValueList,
  updateRequestsList,
  insertAndSetRef,
  duplicateEnvironment,
  insertDynamicValues,
}

export type SetterFn<T> = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  args?: T,
) => void

export type SetterNames = 'updateCombo' | keyof typeof namedSetters

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNamedSetter = (name: SetterNames): SetterFn<any> => {
  // updateCombo is treated differently to avoid dependency cycles
  if (name === 'updateCombo') {
    throw new Error(`Invalid use of updateCombo`)
  }

  const setter = namedSetters[name]
  if (!setter) {
    throw new Error(`Invalid setter name ${name}`)
  }
  return setter
}
