/* eslint-disable react/jsx-props-no-spreading */
import {
  Typography,
  DynamicField,
  Popover,
  Elevation,
  Menu,
  MenuItem,
  Icon,
  DynamicFieldSuggestion,
  DynamicFieldProps,
} from '@rapidapi/ui-lib'
import { DynamicValues, Project } from 'lib'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import styled, { IconSymbol } from 'themes'
import { getTokenId, parseTokenId } from './helpers'
import { DynamicValueEditor } from '../dynamic-value-editor'
import completions from './completions.json'

const StyledBox = styled(Box)(({ fullWidth }: { fullWidth?: boolean }) => ({
  position: 'relative',
  width: fullWidth ? '100%' : 'fit-content',
}))

type DynamicStringInputProps = Omit<DynamicFieldProps, 'suggestions'> & {
  dynamicValues: Project.ObjectMap<string>
  stringValue: string
  suggestions: DynamicValues.DynamicValueSuggestion[]
  label?: string
  hint?: string
  hintIcon?: IconSymbol
  autocompleteCategory?:
    | 'LMCompletionCategoryHTTPRequestHeaders'
    | 'LMCompletionCategoryHTTPRequestHeadersValue'
    | 'LMCompletionCategoryHTTPRequestParameters'
}

const DynamicStringInput: React.FC<DynamicStringInputProps> = ({
  onChange,
  dynamicValues,
  stringValue,
  multiline = false,
  fullWidth = true,
  height,
  label,
  hint,
  hintIcon,
  autocompleteCategory,
  suggestions,
  ...other
}) => {
  const fieldRef = useRef<HTMLDivElement | null>(null)
  const anchorBoxRef = useRef<HTMLDivElement | null>(null)
  const [anchorBoxPosition, setAnchorBoxPosition] = useState<{
    top: number
    left: number
    width: number
    height: number
  }>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  })

  const autocomplete: DynamicFieldSuggestion[] = useMemo(
    () =>
      completions
        .filter(({ category }) => category === autocompleteCategory)
        .map(({ word }) => ({
          id: word,
          title: word,
          type: 'text',
        })),
    [autocompleteCategory],
  )

  const dfSuggestions = useMemo(
    () =>
      suggestions
        .map(
          ({ id, dvTitle }): DynamicFieldSuggestion => ({
            id: getTokenId({ ref: id }),
            title: dvTitle,
          }),
        )
        .concat(autocomplete),
    [suggestions, autocomplete],
  )

  const [editingDVRef, setEditingDVRef] = useState<Project.GenericRef<
    Project.DynamicValue
  > | null>(null)

  const onDynamicValueClick = useCallback(
    (anchorElement: HTMLDivElement, id: string) => {
      const dynamicValueRef = parseTokenId(id)
      if (dynamicValueRef && fieldRef.current) {
        const fieldBounds = fieldRef.current.getBoundingClientRect()
        const anchorBounds = anchorElement.getBoundingClientRect()
        setAnchorBoxPosition({
          top: anchorBounds.top - fieldBounds.top,
          left: anchorBounds.left - fieldBounds.left,
          width: anchorBounds.width,
          height: anchorBounds.height,
        })
        setEditingDVRef(dynamicValueRef)
      }
    },
    [],
  )
  const [showMenu, setShowMenu] = useState(false)
  const onMenuClose = useCallback(() => {
    setShowMenu(false)
  }, [setShowMenu])
  const onContextMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      if (fieldRef.current) {
        const fieldBounds = fieldRef.current.getBoundingClientRect()
        setAnchorBoxPosition({
          top: event.clientY - fieldBounds.top - 5,
          left: event.clientX - fieldBounds.left - 5,
          width: 10,
          height: 10,
        })
        setShowMenu(true)
      }
    },
    [setShowMenu],
  )
  return (
    <StyledBox ref={fieldRef} fullWidth={fullWidth}>
      {label && (
        <Typography variant="body2" mb={1} color="primary">
          {label}
        </Typography>
      )}
      <DynamicField
        dynamicValues={dynamicValues}
        value={stringValue}
        onDynamicValueClick={onDynamicValueClick}
        suggestions={dfSuggestions}
        height={multiline && height === undefined ? 160 : height}
        {...{
          fullWidth,
          multiline,
          onChange,
          onContextMenu,
          ...other,
        }}
      />
      <Box
        ref={anchorBoxRef}
        css={{
          display: editingDVRef || showMenu ? 'block' : 'none',
          position: 'absolute',
          top: anchorBoxPosition.top,
          left: anchorBoxPosition.left,
          width: anchorBoxPosition.width,
          height: anchorBoxPosition.height,
        }}
      />
      {editingDVRef && (
        <Popover
          open
          anchorEl={anchorBoxRef.current}
          onClose={() => setEditingDVRef(null)}
        >
          <Elevation width={500}>
            <DynamicValueEditor objectRef={editingDVRef} />
          </Elevation>
        </Popover>
      )}
      {showMenu && (
        <Menu
          popoverProps={{
            placement: 'bottom',
            open: showMenu,
            anchorEl: anchorBoxRef.current,
            onClose: onMenuClose,
          }}
        >
          {suggestions.map(({ id, dvTitle }) => (
            <MenuItem key={id}>{dvTitle}</MenuItem>
          ))}
        </Menu>
      )}
      {(hintIcon || hint) && (
        <Flex alignItems="flex-start" justifyContent="flex-start" mt={1}>
          {hintIcon && (
            <Icon
              symbol={hintIcon}
              size={14}
              mr={1}
              color="content.secondary"
            />
          )}
          {hint && (
            <Box minWidth="auto">
              <Typography variant="caption" color="secondary">
                {hint}
              </Typography>
            </Box>
          )}
        </Flex>
      )}
    </StyledBox>
  )
}
export default DynamicStringInput
