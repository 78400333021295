import { useCallback, useState } from 'react'

import {
  CloudAPI,
  createProject,
  getProjectBranches,
  pushCommit,
} from 'lib/cloud-sync/cloud-api'
import { serializeProjectManifest } from 'lib/cloud-sync/serializer'
import { getNewProject } from 'lib/project/setters'
import { getOAuthToken } from './cloud-api-auth'

type Status = 'idle' | 'loading' | 'succeeded' | 'failed'

type UseGetCreateProjectResult = {
  start: (projectName: string, teamId: number) => Promise<void>
  status: Status
  error: Error | null
  response: CloudAPI.ProjectInfoResponse | null
}

const useCreateProject = (): UseGetCreateProjectResult => {
  const [status, setStatus] = useState<Status>('idle')
  const [error, setError] = useState<Error | null>(null)
  const [response, setResponse] = useState<CloudAPI.ProjectInfoResponse | null>(
    null,
  )

  const start = useCallback(async (projectName: string, teamId: number) => {
    setStatus('loading')
    try {
      // get token
      const token = getOAuthToken()
      if (!token) {
        setError(new Error('User is not authenticated'))
        setStatus('failed')
        return
      }

      // create project via Paw Cloud API
      const projectInfo = await createProject(projectName, teamId, token)

      // get branches for the newly created project
      const branches = await getProjectBranches(projectInfo.id, token)
      const defaultBranch = Object.keys(branches)[0]

      // create project data and insert initial elements
      const { objects, root } = getNewProject()
      const serializer = serializeProjectManifest(objects, root)

      // push commit with initial project data
      const commitBody = {
        manifest: serializer.manifest,
      }
      const pushResponse = await pushCommit(
        projectInfo.id,
        token,
        defaultBranch,
        branches[defaultBranch].oid,
        true,
        null,
        commitBody,
      )

      // check if pushed to the original branch
      if (defaultBranch && pushResponse.branch !== defaultBranch) {
        // if so, merge into the original branch
        const mergeBody = {
          manifest: {
            base_commit: pushResponse.oid,
            patch: {},
          },
          message: 'Initial Commit',
        }
        await pushCommit(
          projectInfo.id,
          token,
          defaultBranch,
          branches[defaultBranch].oid,
          true,
          pushResponse.oid,
          mergeBody,
        )
      }

      // success
      setResponse(projectInfo)
      setStatus('succeeded')
    } catch (anError) {
      // failure
      setError(anError)
      setStatus('failed')
    }
  }, [])

  return {
    start,
    status,
    error,
    response,
  }
}

export default useCreateProject
