import config from 'config'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { settingsStateActions } from 'store/actions'
import { AppUpdateNotifierState } from 'store/slices/ui/settings/types.d'
import {
  IPCRendererChannelType,
  UseAppUpdateHooksType,
  appUpdateStatus,
  ipcRendererChannel,
} from './app-update-constants'

const { UPDATE_ERROR } = appUpdateStatus
const {
  CHNL_CHECK_UPDATE,
  CHNL_DOWNLOAD_UPDATE,
  CHNL_DOWNLOADING_UPDATE,
  CHNL_DOWNLOADED_UPDATE,
  CHL_SET_REMINDER,
  CHNL_UPDATE_ERROR,
  CHNL_RELAUNCH,
} = ipcRendererChannel

/**
 * @const electropaw
 * @summary
 * this api is only available when the application is wrapped within the electron shell.
 * it abstracts communication between electron and react, for more information see below:
 * - @see contextBridge {@link https://www.electronjs.org/docs/api/context-bridge}
 * - @see preload.js {@link /electron/src/main/preload.ts}
 *
 */
export const { electropaw } = window

const useAppUpdateHooks = (): UseAppUpdateHooksType => {
  const ipcRenderer = electropaw
  const dispatch = useDispatch()

  const checkForUpdates = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      if (!ipcRenderer) {
        return
      }

      settingsStateActions.setAppUpdateNotifierContext({
        status: undefined,
        error: null,
      })

      ipcRenderer.invoke<IPCRendererChannelType, null>(CHNL_CHECK_UPDATE)
    },
    [ipcRenderer],
  )

  const downloadUpdate = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      if (!ipcRenderer) {
        return
      }
      ipcRenderer.invoke<IPCRendererChannelType, null>(CHNL_DOWNLOAD_UPDATE)
    },
    [ipcRenderer],
  )

  const applyUpdate = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      if (!ipcRenderer) {
        return
      }

      dispatch(
        settingsStateActions.setAppUpdateNotifierContext({
          status: undefined,
          error: null,
        }),
      )
      ipcRenderer.invoke<IPCRendererChannelType, null>(CHNL_RELAUNCH)
    },
    [ipcRenderer, dispatch],
  )

  const dismiss = useCallback(() => {
    dispatch(
      settingsStateActions.setAppUpdateNotifierContext({
        status: undefined,
        error: null,
      }),
    )

    ipcRenderer.invoke<IPCRendererChannelType, null>(CHL_SET_REMINDER)
  }, [dispatch, ipcRenderer])

  const retryUpdate = useCallback(() => {
    dispatch(
      settingsStateActions.setAppUpdateNotifierContext({
        current: config.version || '',
        latest: undefined,
        status: undefined,
        error: null,
      }),
    )
    ipcRenderer.invoke<IPCRendererChannelType, null>(CHNL_CHECK_UPDATE)
  }, [ipcRenderer, dispatch])

  useEffect(() => {
    if (!ipcRenderer) {
      return undefined
    }

    ;(async () => {
      try {
        const data = await ipcRenderer.appUpdate<
          IPCRendererChannelType,
          AppUpdateNotifierState
        >(CHNL_DOWNLOADED_UPDATE)

        dispatch(settingsStateActions.setAppUpdateNotifierContext(data))
      } catch (error) {
        const err = error as Error
        dispatch(
          settingsStateActions.setAppUpdateNotifierContext({
            error: new Error(err.message),
            status: UPDATE_ERROR,
          }),
        )
      }
    })()
    return () => ipcRenderer.removeListeners('updateDownloaded')
  }, [ipcRenderer, dispatch])

  useEffect(() => {
    if (!ipcRenderer) {
      return undefined
    }

    ;(async () => {
      try {
        const data = await ipcRenderer.appUpdate<
          IPCRendererChannelType,
          AppUpdateNotifierState
        >(CHNL_UPDATE_ERROR)

        dispatch(settingsStateActions.setAppUpdateNotifierContext(data))
      } catch (error) {
        const err = error as Error
        dispatch(
          settingsStateActions.setAppUpdateNotifierContext({
            error: new Error(err.message),
            status: UPDATE_ERROR,
          }),
        )
      }
    })()
    return () =>
      ipcRenderer.removeListeners<IPCRendererChannelType>(CHNL_UPDATE_ERROR)
  }, [ipcRenderer, dispatch])

  useEffect(() => {
    if (!ipcRenderer) {
      return undefined
    }

    ;(async () => {
      try {
        const data = await ipcRenderer.appUpdate<
          IPCRendererChannelType,
          AppUpdateNotifierState
        >(CHNL_DOWNLOADING_UPDATE)
        dispatch(settingsStateActions.setAppUpdateNotifierContext(data))
      } catch (error) {
        const err = error as Error
        dispatch(
          settingsStateActions.setAppUpdateNotifierContext({
            error: new Error(err.message),
            status: UPDATE_ERROR,
          }),
        )
      }
    })()
    return () => ipcRenderer.removeListeners('updateError')
  }, [ipcRenderer, dispatch])

  return {
    applyUpdate,
    checkForUpdates,
    dismiss,
    downloadUpdate,
    ipcRenderer,
    retryUpdate,
  }
}

export default useAppUpdateHooks
