import { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'
import { getParameter } from '../getters'
import addObject from './add-object'

// eslint-disable-next-line import/no-cycle
import duplicateDynamicString from './duplicate-dynamic-string'

const duplicateParameter = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceRef }: { sourceRef: Project.GenericRef<Project.Parameter> },
): Project.GenericRef<Project.Parameter> => {
  // get the original/source
  const sourceParameter = getParameter(sourceRef, objects, false)

  // create a new parameter
  const parameter: Project.Parameter = {
    uuid: getUuid(),
    type: 'parameter',
    enabled: sourceParameter.enabled,
    key: sourceParameter.key
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceParameter.key,
        })
      : null,
    value: sourceParameter.value
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceParameter.value,
        })
      : null,
  }
  return addObject(objects, parameter)
}

export default duplicateParameter
