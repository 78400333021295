import { Project } from 'lib/project/types.d'
import { getDynamicString } from '../getters'
import addDynamicString from './add-dynamic-string'

// eslint-disable-next-line import/no-cycle
import duplicateDynamicValue from './duplicate-dynamic-value'

const duplicateDynamicString = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceRef }: { sourceRef: Project.GenericRef<Project.DynamicString> },
): Project.GenericRef<Project.DynamicString> => {
  // get the original/source
  const sourceDynamicString = getDynamicString(sourceRef, objects, false)

  // duplicate values (dynamic values)
  const newStrings = sourceDynamicString.strings.map((item) => {
    if (typeof item === 'string') {
      return item
    }
    return duplicateDynamicValue(objects, root, { sourceRef: item })
  })

  // create a new dynamic string with the new strings
  return addDynamicString(objects, root, { strings: newStrings })
}

export default duplicateDynamicString
