import React from 'react'
import { flexbox, layout } from 'styled-system'
import { Flex } from 'reflexbox'
import styled from '@emotion/styled'

const MainContentStyled = styled(Flex)(flexbox, layout, {
  gridArea: 'main',
  position: 'relative',
  overflow: 'hidden',
})

const AppMainContent: React.FC = ({ children }) => (
  <MainContentStyled as="main" flexGrow={1} flexShrink={1}>
    {children}
  </MainContentStyled>
)

export default AppMainContent
