import { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'

import { getAllObjectOfType, getRequestGroup } from '../getters'
import addObject from './add-object'

export interface AddRequestGroupArgs {
  parent?: Project.GenericRef<Project.RequestGroup>
  uuid?: string
  useNumName?: boolean
  args?: Partial<Project.RequestGroup>
}

const addRequestGroup = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { parent, uuid, useNumName, args }: AddRequestGroupArgs,
): Project.GenericRef<Project.RequestGroup> => {
  // title
  let title = 'New Group'
  if (useNumName) {
    title = `Group ${getAllObjectOfType(objects, 'group').length + 1}`
  }

  const group: Project.RequestGroup = {
    uuid: uuid || getUuid(),
    type: 'group',
    title,
    summary: '',
    children: [],
    ...args,
  }
  const ref = addObject(objects, group)

  if (parent) {
    const parentGroup = getRequestGroup(parent, objects, false)
    parentGroup.children.push(ref)
  } else {
    const project = objects[root.ref] as Project.Project
    project.requests.push(ref)
  }

  return ref
}

export default addRequestGroup
