import { DynamicValues } from 'lib/dynamic-values/types.d'
import { getCurrentValueForVariable } from 'lib/environments'
import { evaluateDynamicString } from 'lib/evaluation'
import { Project } from 'lib/project/types.d'
import { getEnvironmentVariable, getDomainForVariable } from 'lib/project'
import { EnvironmentVariableDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.EnvironmentVariableDynamicValue'

const defaultValue: EnvironmentVariableDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  environmentVariable: null,
}

const defaultTokenInfo: DynamicValues.TokenInfo = {
  title: 'Environment Variable',
  text: null,
}

const editForm: DynamicValues.EditForm<EnvironmentVariableDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'environmentVariable',
      fieldType: 'environmentVariable',
      label: 'Variable',
    },
  ],
}

const implEnvironmentVariableDynamicValue: DynamicValues.Implementation<EnvironmentVariableDynamicValueInterface> = {
  title: 'Environment Variable',
  identifier,
  defaultValue,
  editForm,
  getAllRefs() {
    return null
  },
  getEvaluatedString: async ({ environmentVariable }, ctx) => {
    if (!environmentVariable) {
      return ''
    }
    const variableRef: Project.GenericRef<Project.EnvironmentVariable> = {
      ref: environmentVariable,
    }
    const value = getCurrentValueForVariable(
      variableRef,
      ctx.project.objects,
      ctx.currentEnvironments,
    )
    if (!value || !value.value) {
      return ''
    }
    return evaluateDynamicString(value.value, ctx)
  },
  getTokenInfo: async ({ environmentVariable }, ctx) => {
    if (!environmentVariable) {
      return defaultTokenInfo
    }
    const variableRef: Project.GenericRef<Project.EnvironmentVariable> = {
      ref: environmentVariable,
    }
    const variable = getEnvironmentVariable(
      variableRef,
      ctx.project.objects,
      true,
    )
    if (!variable) {
      return {
        title: 'Missing variable',
        text: null,
      }
    }
    const domain = getDomainForVariable(variableRef, ctx.project.objects)
    if (!variable || !domain) {
      return defaultTokenInfo
    }
    return {
      title: domain.name || 'Environment Variable',
      text: variable.name || null,
    }
  },
}

export default implEnvironmentVariableDynamicValue
