import {
  implBasicAuthDynamicValue,
  implOAuth1HeaderDynamicValue,
  implOAuth2DynamicValue,
} from 'lib/dynamic-values'
import { Project } from 'lib/project'
import { getUuid } from 'lib/utils'

import { AuthTabComponentList, AuthTabKey } from './auth-tabs-types.d'
import AuthTypeDynamicValue from './auth-type-dynamic-value'
import AuthTypeCustom from './auth-type-custom'
import AuthTypeNone from './auth-type-none'

export const authTabComponents: AuthTabComponentList = {
  none: AuthTypeNone,
  custom: AuthTypeCustom,
  basic: AuthTypeDynamicValue,
  oauth1: AuthTypeDynamicValue,
  oauth2: AuthTypeDynamicValue,
}

export const getAuthTabKey = (
  dvIdentifier: string | null,
  authIsEmpty: boolean,
): AuthTabKey => {
  switch (dvIdentifier) {
    case implBasicAuthDynamicValue.identifier:
      return 'basic'
    case implOAuth1HeaderDynamicValue.identifier:
      return 'oauth1'
    case implOAuth2DynamicValue.identifier:
      return 'oauth2'
    default:
      return authIsEmpty ? 'none' : 'custom'
  }
}

export const getAuthTabName = (tabKey: AuthTabKey): string => {
  switch (tabKey) {
    case 'none':
      return 'None'
    case 'basic':
      return 'Basic Auth'
    case 'oauth1':
      return 'OAuth 1'
    case 'oauth2':
      return 'OAuth 2'
    default:
      return 'Custom'
  }
}

export const getNewAuthDynamicValue = (
  tabValue: AuthTabKey,
): {
  strings: (string | Project.GenericRef<Project.DynamicValue>)[] | null
  insertDynamicValues: Project.DynamicValue[]
} => {
  if (tabValue === 'basic') {
    const dv: Project.DynamicValue = {
      ...implBasicAuthDynamicValue.defaultValue,
      uuid: getUuid(),
    }
    const dvRef: Project.GenericRef<Project.DynamicValue> = { ref: dv.uuid }
    return {
      strings: [dvRef],
      insertDynamicValues: [dv],
    }
  }

  if (tabValue === 'oauth1') {
    const dv: Project.DynamicValue = {
      ...implOAuth1HeaderDynamicValue.defaultValue,
      uuid: getUuid(),
    }
    const dvRef: Project.GenericRef<Project.DynamicValue> = { ref: dv.uuid }
    return {
      strings: [dvRef],
      insertDynamicValues: [dv],
    }
  }

  if (tabValue === 'oauth2') {
    const dv: Project.DynamicValue = {
      ...implOAuth2DynamicValue.defaultValue,
      uuid: getUuid(),
    }
    const dvRef: Project.GenericRef<Project.DynamicValue> = { ref: dv.uuid }
    return {
      strings: [dvRef],
      insertDynamicValues: [dv],
    }
  }

  return {
    strings: null,
    insertDynamicValues: [],
  }
}
