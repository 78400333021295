import { addAlpha } from 'utils'
import { darkGray } from '../colors'

const fills = {
  primary: addAlpha(darkGray[500], 0.2),
  secondary: addAlpha(darkGray[500], 0.16),
  tertiary: addAlpha(darkGray[500], 0.12),
  quanternary: addAlpha(darkGray[500], 0.08),
}

export default fills
