import { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getRequestVariable = <Optional extends true | false>(
  ref: Project.GenericRef<Project.RequestVariable>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true
  ? Project.RequestVariable | null
  : Project.RequestVariable =>
  getObject(ref, objects, isOptional, 'requestVariable')

export default getRequestVariable
