import { createAction } from '@reduxjs/toolkit'
import { CloudAPI, RequestHandling } from 'lib'
import { Environments } from 'lib/environments'
import { PawExtension } from 'worker/primary-worker/implementation/extensions/types.d'
import extensionSlice from './slice'
import ExtensionStore from './types.d'

export const extensionsActions = extensionSlice.actions

/**
 * @export extensionsLoadListAction
 * @summary Worker middleware action to load multiple extensions.
 */
export const extensionsLoadListAction = createAction<{
  scripts: string[]
}>('extensions/loadList')

/**
 * @export extensionsUnloadItemAction
 * @summary Worker middleware action to unload an extension.
 */
export const extensionsUnloadItemAction = createAction<ExtensionStore.Model>(
  'extensions/unloadItem',
)

/**
 * @export extensionsGenerateCodeAction
 * @summary Worker middleware action to generate code for a code generator extension.
 */
export const extensionsGenerateCodeAction = createAction<{
  extension: ExtensionStore.Model
  requestUuid: string | string[]
  options?: PawExtension.Options
  userInfo?: CloudAPI.UserAccountResponse | null
  currentEnvironments: Environments.CurrentEnvironmentMap
}>('extensions/generateCode')

/**
 * @export extensionsExportRequestsAction
 * @summary Worker middleware action to generate code for a code generator extension.
 */
export const extensionsExportRequestsAction = createAction<{
  extension: ExtensionStore.Model
  requestUuid: string | string[]
  options?: PawExtension.Options
  userInfo?: CloudAPI.UserAccountResponse | null
  generateFile?: boolean
  currentEnvironments: Environments.CurrentEnvironmentMap
}>('extensions/exportContent')

/**
 * @export extensionsImportRequestsAction
 * @summary Worker middleware action to import code through an extension.
 */
export const extensionsImportRequestsAction = createAction<{
  extension: ExtensionStore.Importer
  content: RequestHandling.FileStored
}>('extensions/importRequests')
