import { Project } from 'lib/project'
import parseUrlEncodedObject from './parse-url-encoded-object'

interface ParseUrlResult {
  url: string
  baseUrl: string
  query: Project.ObjectMap<string>
}

const parseUrl = (url: string): ParseUrlResult => {
  const urlMatch = url.match(/^([^?]*)(?:\?(.*))?$/)

  // get components
  const baseUrlString = urlMatch && urlMatch[1]
  const queryString = urlMatch && urlMatch[2]

  // return
  return {
    url,
    baseUrl: baseUrlString || '',
    query: queryString ? parseUrlEncodedObject(queryString) : {},
  }
}

export default parseUrl
