import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import { assertType, loadPrimitiveString } from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

const loadEnvironmentVariable = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.EnvironmentVariable => {
  // check type
  assertType(object, 'environmentVariable')

  const envVariable: Project.EnvironmentVariable = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'environmentVariable',
    name: loadPrimitiveString(object.name),
    domain: object.domain as Project.GenericRef<Project.EnvironmentDomain>,
  }
  return envVariable
}

export default loadEnvironmentVariable
