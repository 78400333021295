import { Project } from 'lib'
import { getAllStrings } from 'lib/project/getters'
import {
  BodyFormKeyValueDynamicValueInterface,
  BodyMultipartFormDataDynamicValue,
  implBodyFormKeyValueDynamicValue,
  implBodyMultipartFormDataDynamicValue,
  implJSONDynamicValue,
  implJSONTreeDynamicValue,
  JSONDynamicValueInterface,
  JSONTreeDynamicValueInterface,
  implGraphQLDynamicValue,
  GraphQLDynamicValueInterface,
} from 'lib/dynamic-values'
import { getUuid } from 'lib/utils'
import { parse as json5Parse } from 'json5'
import { parse as qsParse, stringify as qsStringify } from 'qs'
import { mapDynamicFieldValueToStrings } from 'ecosystems/field-editors/dynamic-string-editor/helpers'
import BodyTypeText from './body-type-text'
import BodyTypeGQL from './body-type-gql'
import BodyTypeDynamicValue from './body-type-dynamic-value'
import BodyTypeJSONTree from './body-type-json-tree'

import type { BodyTabComponentList, BodyTabKey } from './body-tabs-types.d'

type JSON_T =
  | string
  | number
  | boolean
  | null
  | JSON_T[]
  | { [key: string]: JSON_T }

type Opts = {
  from: BodyTabKey
  to: BodyTabKey
  objects: Project.ObjectMap
  currentBodyDynamicString: Project.DynamicString
  currentBodyDynamicValues: Project.DynamicValue[]
}

function never(_: never) {
  return _
}

const mapDynamicStringStringsOrStringToStrings = (
  strings: Project.DynamicString['strings'],
): Project.DynamicString['strings'] =>
  strings.flatMap((string) =>
    typeof string === 'string'
      ? mapDynamicFieldValueToStrings(string)
      : [string],
  )

export const bodyTabComponents: BodyTabComponentList = {
  text: BodyTypeText,
  json: BodyTypeDynamicValue,
  jsonTree: BodyTypeJSONTree,
  urlEncoded: BodyTypeDynamicValue,
  multipart: BodyTypeDynamicValue,
  file: BodyTypeDynamicValue,
  gqlQuery: BodyTypeGQL,
}

export const getBodyTabKey = (dvIdentifier: string | null): BodyTabKey => {
  if (!dvIdentifier) {
    return 'text'
  }
  switch (dvIdentifier) {
    case implJSONDynamicValue.identifier:
      return 'json'
    case implJSONTreeDynamicValue.identifier:
      return 'jsonTree'
    case implBodyFormKeyValueDynamicValue.identifier:
      return 'urlEncoded'
    case implGraphQLDynamicValue.identifier:
      return 'gqlQuery'
    case implBodyMultipartFormDataDynamicValue.identifier:
      return 'multipart'
    default:
      return 'text'
  }
}

export const getNewBodyDynamicValue = (
  tabValue: BodyTabKey,
): {
  strings: (string | Project.GenericRef<Project.DynamicValue>)[] | null
  inserts: Project.AnyObject[]
} => {
  if (tabValue === 'text') {
    return {
      strings: [''],
      inserts: [],
    }
  }

  if (tabValue === 'urlEncoded') {
    const dv: Project.DynamicValue = {
      uuid: getUuid(),
      type: 'dynamicValue',
      identifier: implBodyFormKeyValueDynamicValue.identifier,
      keyValues: [],
    } as BodyFormKeyValueDynamicValueInterface
    const dvRef: Project.GenericRef<Project.DynamicValue> = { ref: dv.uuid }
    return {
      strings: [dvRef],
      inserts: [dv],
    }
  }

  if (tabValue === 'multipart') {
    const dv: Project.DynamicValue = {
      uuid: getUuid(),
      type: 'dynamicValue',
      identifier: implBodyMultipartFormDataDynamicValue.identifier,
      keyValues: [],
    } as BodyMultipartFormDataDynamicValue
    const dvRef: Project.GenericRef<Project.DynamicValue> = { ref: dv.uuid }
    return {
      strings: [dvRef],
      inserts: [dv],
    }
  }

  if (tabValue === 'gqlQuery') {
    const ds: Project.DynamicString = {
      uuid: getUuid(),
      type: 'dynamicString',
      strings: ['query {}'],
    }
    const dv: Project.DynamicValue<GraphQLDynamicValueInterface> = {
      uuid: getUuid(),
      type: 'dynamicValue',
      gqlQuery: { ref: ds.uuid },
      gqlVariables: '{}',
      identifier: 'com.luckymarmot.GraphQLDynamicValue',
      optUseRequestVariables: null,
      optAutoFetchSchema: null,
    }
    const dvRef: Project.GenericRef<Project.DynamicValue> = { ref: dv.uuid }
    return {
      strings: [dvRef],
      inserts: [dv, ds],
    }
  }
  if (tabValue === 'json') {
    const dv: Project.DynamicValue = {
      uuid: getUuid(),
      type: 'dynamicValue',
      identifier: implJSONDynamicValue.identifier,
      json: '{}',
    } as JSONDynamicValueInterface
    const dvRef: Project.GenericRef<Project.DynamicValue> = { ref: dv.uuid }
    return {
      strings: [dvRef],
      inserts: [dv],
    }
  }

  if (tabValue === 'jsonTree') {
    const dv: Project.DynamicValue = {
      uuid: getUuid(),
      type: 'dynamicValue',
      identifier: implJSONTreeDynamicValue.identifier,
      json: '{}',
    } as JSONTreeDynamicValueInterface
    const dvRef: Project.GenericRef<Project.DynamicValue> = { ref: dv.uuid }
    return {
      strings: [dvRef],
      inserts: [dv],
    }
  }

  return {
    strings: null,
    inserts: [],
  }
}

function parseStringToParams(
  string: string,
): {
  param: Project.Parameter
  key: Project.DynamicString
  value: Project.DynamicString
}[] {
  let rootUrlParams: URLSearchParams
  let json: JSON_T | undefined

  try {
    json = json5Parse(string)
    rootUrlParams = new URLSearchParams(qsStringify(json) ?? '')
  } catch (e) {
    const qs = qsParse(string)
    rootUrlParams = new URLSearchParams(qsStringify(qs))
    /* noop */
  }

  const params: {
    param: Project.Parameter
    key: Project.DynamicString
    value: Project.DynamicString
  }[] = []

  const makeParam = (
    keyStrings: Project.DynamicString['strings'],
    valueStrings: Project.DynamicString['strings'],
  ) => {
    const paramKeyDS: Project.DynamicString = {
      uuid: getUuid(),
      strings: mapDynamicStringStringsOrStringToStrings(keyStrings),
      type: 'dynamicString',
    }

    const paramValueDS: Project.DynamicString = {
      uuid: getUuid(),
      strings: mapDynamicStringStringsOrStringToStrings(valueStrings),
      type: 'dynamicString',
    }
    const paramDV = {
      type: 'parameter',
      enabled: true,
      uuid: getUuid(),
      key: {
        ref: paramKeyDS.uuid,
      },
      value: {
        ref: paramValueDS.uuid,
      },
    } as Project.Parameter
    return {
      param: paramDV,
      key: paramKeyDS,
      value: paramValueDS,
    }
  }
  const entriesArray = Array.from(rootUrlParams.entries())

  if (
    // special case, flips single key without value to value and assigns it to key0
    entriesArray.length === 1 &&
    entriesArray[0]?.[0] === string &&
    entriesArray[0]?.[1] === ''
  ) {
    makeParam(['0'], [entriesArray[0]?.[0]])
  } else {
    rootUrlParams.forEach((value, key) => {
      params.push(makeParam([key], [value]))
    })
  }

  if (params.length === 0 && string) {
    params.push(makeParam(['0'], [string]))
  }
  return params
}

function parseQsWithDecoder(stringValue: string) {
  return qsParse(stringValue, {
    decoder: function urlDecoder(str, decoder) {
      const strWithoutPlus = str.replace(/\+/g, ' ')

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str)
      }
      const keywords: Record<string, unknown> = {
        true: true,
        false: false,
        null: null,
        undefined,
      }
      if (str in keywords) {
        return keywords[str]
      }
      // utf-8
      try {
        return decoder(strWithoutPlus)
      } catch (e) {
        return strWithoutPlus
      }
    },
  })
}

function stringToJsonTree(stringValue: string, opts: Opts): ConvertFnReturn {
  let json: JSON_T

  try {
    json = stringValue?.trim() ? json5Parse(stringValue) : null
  } catch {
    try {
      const qs = parseQsWithDecoder(stringValue)
      if (qs === undefined) {
        json = null
      } else {
        json = qs as JSON_T
      }
    } catch {
      json = null
    }
  }

  function traverseJson(parsed: JSON_T, addKeysToPrimitives: boolean): JSON_T {
    switch (typeof parsed) {
      case 'object':
        return parsed === null ? '' : parsed

      case 'boolean':
      case 'number':
      case 'string':
        return addKeysToPrimitives ? { 0: parsed } : parsed
      default:
        never(parsed)
        return ''
    }
  }

  const finalString = JSON.stringify(traverseJson(json, true) || {})

  const dv: Project.DynamicValue = {
    uuid: getUuid(),
    type: 'dynamicValue',
    identifier: implJSONTreeDynamicValue.identifier,
    json:
      finalString === '{}' && stringValue && stringValue !== finalString
        ? JSON.stringify(traverseJson(stringValue, true))
        : finalString,
  } as JSONTreeDynamicValueInterface

  const dvRef: Project.GenericRef<Project.DynamicValue> = {
    ref: dv.uuid,
  }
  return {
    strings: [dvRef],
    inserts: [dv, ...opts.currentBodyDynamicValues],
  }
}

function stringToUrlParams(stringValue: string, opts: Opts): ConvertFnReturn {
  const params = parseStringToParams(stringValue)
  const dv: Project.DynamicValue = {
    uuid: getUuid(),
    type: 'dynamicValue',
    identifier:
      opts.to === 'multipart'
        ? implBodyMultipartFormDataDynamicValue.identifier
        : implBodyFormKeyValueDynamicValue.identifier,
    keyValues: params.flatMap((param) => ({
      ref: param.param.uuid,
    })),
  } as BodyMultipartFormDataDynamicValue | BodyFormKeyValueDynamicValueInterface
  const dvRef: Project.GenericRef<Project.DynamicValue> = {
    ref: dv.uuid,
  }
  return {
    strings: [dvRef],
    inserts: [
      ...params.flatMap((param) => Object.values(param)),
      dv,
      ...opts.currentBodyDynamicValues,
    ],
  }
}

function stringToJson(stringValue: string, opts: Opts): ConvertFnReturn {
  let jsonString: string

  try {
    jsonString = JSON.stringify(json5Parse(stringValue))
  } catch (e) {
    try {
      const qs = parseQsWithDecoder(stringValue)
      if (Object.keys(qs).length) {
        jsonString = JSON.stringify(qs)
      } else {
        jsonString = stringValue
      }
    } catch {
      jsonString = stringValue
    }
  }

  const dv: Project.DynamicValue = {
    uuid: getUuid(),
    type: 'dynamicValue',
    identifier: implJSONDynamicValue.identifier,
    json: jsonString === '' ? '{}' : jsonString,
  } as JSONDynamicValueInterface
  const dvRef: Project.GenericRef<Project.DynamicValue> = {
    ref: dv.uuid,
  }
  return {
    strings: [dvRef],
    inserts: [dv, ...opts.currentBodyDynamicValues],
  }
}

function getTopLevelStringAndRefsFromDynamicString(
  opts: Opts,
): [string, Opts['objects']] {
  let topLevel = true
  const refs: typeof opts.objects = {}
  const getString = (ds: Project.DynamicString): string | null =>
    getAllStrings(ds, (item) => {
      refs[item.ref] = opts.objects[item.ref]
      if (!topLevel) {
        return `#${item.ref}#`
      }
      topLevel = false
      const dv = opts.objects[item.ref] as Project.DynamicValue

      switch (opts.from) {
        case 'text':
          return `#${item.ref}#`
        case 'json':
          return (dv as JSONDynamicValueInterface).json ?? ''
        case 'jsonTree':
          return (dv as JSONTreeDynamicValueInterface).json ?? ''
        case 'urlEncoded':
        case 'multipart':
          return (dv as
            | BodyMultipartFormDataDynamicValue
            | BodyFormKeyValueDynamicValueInterface).keyValues
            .map((kv) => {
              const param = opts.objects[kv.ref] as Project.Parameter
              if (!param.key || !param.value || !param.enabled) {
                return ''
              }
              const key = getString(
                opts.objects[param.key.ref] as Project.DynamicString,
              )
              const value = getString(
                opts.objects[param.value.ref] as Project.DynamicString,
              )
              return key ? `${key}=${value}` : ''
            })
            .join('&')
        default:
          never(dv as never)
          return `#${item.ref}#`
      }
    })
  return [getString(opts.currentBodyDynamicString) ?? '', refs]
}

function jsonToString(string: string): string {
  let json: JSON_T
  try {
    json = json5Parse(string)
    return JSON.stringify(json)
  } catch (e) {
    try {
      const qs = parseQsWithDecoder(string)
      if (Object.keys(qs).length) {
        return JSON.stringify(qs)
      }
      return string
    } catch {
      return string
    }
  }
}

export const convertBodyDynamicValue = (opts: Opts): ConvertFnReturn => {
  const noopConversion = {
    strings: opts.currentBodyDynamicString.strings,
    inserts: opts.currentBodyDynamicValues,
  }

  if (opts.to === opts.from) {
    return noopConversion
  }

  const [stringValue] = getTopLevelStringAndRefsFromDynamicString(opts)

  function process() {
    switch (opts.from) {
      case 'text':
        switch (opts.to) {
          case 'text': {
            return noopConversion
          }
          case 'json':
            return stringToJson(stringValue, opts)
          case 'jsonTree':
            return stringToJsonTree(stringValue, opts)
          case 'urlEncoded':
          case 'multipart': {
            return stringToUrlParams(stringValue, opts)
          }
          case 'file':
          case 'gqlQuery':
            return getNewBodyDynamicValue(opts.to)
          default:
            never(opts.to)
            return getNewBodyDynamicValue(opts.to)
        }
      case 'json': {
        switch (opts.to) {
          case 'text':
            return {
              strings: [stringValue === '{}' ? '' : jsonToString(stringValue)],
            }
          case 'json':
            return noopConversion
          case 'jsonTree': {
            return stringToJsonTree(stringValue, opts)
          }
          case 'urlEncoded':
          case 'multipart':
            return stringToUrlParams(
              stringValue === '{}' ? '' : stringValue,
              opts,
            )
          case 'file':
          case 'gqlQuery':
            return getNewBodyDynamicValue(opts.to)
          default:
            never(opts.to)
            return getNewBodyDynamicValue(opts.to)
        }
      }
      case 'jsonTree': {
        switch (opts.to) {
          case 'text':
            return {
              strings: [stringValue === '{}' ? '' : stringValue],
            }
          case 'json':
            return stringToJson(stringValue, opts)
          case 'jsonTree':
            return noopConversion
          case 'urlEncoded':
          case 'multipart':
            return stringToUrlParams(
              stringValue === '{}' ? '' : stringValue,
              opts,
            )

          case 'file':
          case 'gqlQuery':
            return getNewBodyDynamicValue(opts.to)
          default:
            never(opts.to)
            return getNewBodyDynamicValue(opts.to)
        }
      }
      case 'urlEncoded':
      case 'multipart': {
        switch (opts.to) {
          case 'text':
            return {
              strings: mapDynamicStringStringsOrStringToStrings([stringValue]),
            }

          case 'jsonTree':
          case 'json': {
            const json = parseQsWithDecoder(stringValue)
            const convert = {
              jsonTree: stringToJsonTree,
              json: stringToJson,
            }[opts.to]

            return Object.keys(json).length
              ? convert(JSON.stringify(json), opts)
              : convert(stringValue, opts)
          }
          case 'multipart':
          case 'urlEncoded':
            return stringToUrlParams(stringValue, opts)
          case 'file':
          case 'gqlQuery':
            return getNewBodyDynamicValue(opts.to)
          default:
            never(opts.to)
            return getNewBodyDynamicValue(opts.to)
        }
      }
      case 'gqlQuery':
      case 'file':
        return getNewBodyDynamicValue(opts.to)
      default:
        never(opts.from)
        return { strings: null, inserts: [] }
    }
  }

  const result = process()

  return {
    strings: result.strings
      ? mapDynamicStringStringsOrStringToStrings(result.strings)
      : result.strings,
    inserts: 'inserts' in result ? result.inserts : [],
  }
}

interface ConvertFnReturn {
  strings: (string | Project.GenericRef<Project.DynamicValue>)[] | null
  inserts: Project.AnyObject[]
}
