import { createAction } from '@reduxjs/toolkit'

import { Project } from 'lib/project/types.d'
import {
  AcknowledgePendingChangesAction,
  ApplySetterAction,
  ProjectState,
  SetValueAction,
} from 'store/types.d'
import { WorkerProjectSync } from 'worker/primary-worker/implementation/project-sync/worker-project-sync.types'

export const setProject = createAction<Partial<ProjectState>>(
  'project/setProject',
)

export const setProjectValue = createAction<SetValueAction<Project.AnyObject>>(
  'project/setProjectValue',
)

export const projectSetterAction = createAction<
  ApplySetterAction<Project.AnyObject, Project.ObjectMap<unknown>>
>('project/applyProjectSetter')

type NarrowAction<T, N> = T extends { setter: N } ? T : never

export const applyProjectSetter = <
  T,
  Y = Project.ObjectMap<unknown>,
  Setter extends ApplySetterAction<T, Y>['setter'] = ApplySetterAction<
    T,
    Y
  >['setter']
>(
  setterName: Setter,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
) => <
  Action extends ApplySetterAction<T, Y> = NarrowAction<
    ApplySetterAction<T, Y>,
    Setter
  >
>(
  args: Action['args'],
) =>
  projectSetterAction({
    setter: setterName as ApplySetterAction<T, Y>['setter'],
    args: args as ApplySetterAction<T, Y>['args'],
  })

export const acknowledgePendingChanges = createAction<
  AcknowledgePendingChangesAction
>('project/acknowledgePendingChanges')

export const undoAction = createAction('project/undo')

export const redoAction = createAction('project/redo')

export const loadProjectAction = createAction<number>('project/loadProject')

export const unloadProjectAction = createAction<number>('project/unloadProject')

export const syncProjectAction = createAction<
  WorkerProjectSync.SyncOptions | undefined
>('project/syncProjectAction')

export const updateSyncingActionCount = createAction<number>(
  'project/updateSyncingActionCount',
)
