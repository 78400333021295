import { PayloadAction } from '@reduxjs/toolkit'
import { extensionsActions } from 'store/actions'
import ExtensionStore from 'store/slices/extensions/types.d'
import { PawExtension } from 'worker/primary-worker/implementation/extensions/types.d'
import {
  extensionsExportRequestsWorkerAction,
  // extensionsGenerateCodeWorkerAction,
} from 'worker/primary-worker/actions'

import { Environments } from 'lib/environments/types.d'
import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { ActionHandler } from '../types.d'

const errorMessage = `
⚠️ ContextJS or RequestJS might not be ready.
  Please try again.
`

const extensionsExportRequestsActionHandler = async ({
  action: {
    payload: {
      extension,
      requestUuid,
      options,
      currentEnvironments,
      userInfo,
      generateFile,
    },
  },
  next,
  worker,
}: ActionHandler<
  PayloadAction<{
    extension: ExtensionStore.Model
    requestUuid: string | string[]
    options?: PawExtension.Options
    userInfo?: CloudAPI.UserAccountResponse | null
    currentEnvironments: Environments.CurrentEnvironmentMap
    generateFile?: boolean
  }>
>): Promise<void> => {
  // if no worker, throw
  if (!worker) {
    throw new Error('Worker is missing')
  }

  try {
    const generatedCode = await worker.runAction(
      extensionsExportRequestsWorkerAction,
      {
        extension,
        options,
        requestUuid,
        userInfo,
        currentEnvironments,
      },
    )

    next(
      extensionsActions.setExportedRequestsOuput({
        content: generatedCode,
        language: extension.language || 'text',
        extname:
          extension.fileExtension?.length > 0
            ? extension.fileExtension[0]
            : '.txt',
        generateFile,
      }),
    )
  } catch (error) {
    const { message } = error as Error

    window.console.warn(`Code generation failed: ${message}`)
    next(
      extensionsActions.setExportedRequestsOuput({
        content: errorMessage,
        extname: 'txt',
        language: 'text',
      }),
    )
  }
}

export default extensionsExportRequestsActionHandler
