/* eslint-disable import/prefer-default-export, import/no-cycle */
import { DynamicString } from './dynamic-string.d'
import { Request } from './index.d'

enum KeyValueMode {
  Normal = 0,
  NormalAlwaysAddEqualSign = 1,
  Raw = 2,
}

export declare class KeyValue {
  readonly id: string
  readonly request: Request
  readonly isHeader: boolean
  readonly isUrlParameter: boolean
  name: DynamicString | null
  value: DynamicString | null
  enabled: boolean
  mode: KeyValueMode
}
