import { DynamicValues } from 'lib/dynamic-values/types.d'
import getImplementations from 'lib/dynamic-values/get-implementations'
import { getUuid } from 'lib/utils'

const getAllSuggestions = (): DynamicValues.DynamicValueSuggestion[] =>
  getImplementations().map((impl) => {
    const suggestion: DynamicValues.DynamicValueSuggestion = {
      id: getUuid(),
      dvIdentifier: impl.identifier,
      dvTitle: impl.title,
    }
    return suggestion
  })

export default getAllSuggestions
