import { evaluateDynamicString } from 'lib/evaluation/evaluate'
import { DynamicValues } from 'lib/dynamic-values/types.d'
import { Evaluation } from 'lib/evaluation/types.d'
import {
  base64DecodeString,
  base64EncodeString,
  Base64EncodeOptions,
} from 'lib/utils/encoding'
import getStringEllipsis from 'lib/utils/get-string-ellipsis'

import { Base64EncodingDynamicValueInterface } from './types.d'
import {
  base64EncodingDynamicValueLineBreakCRLFMode,
  base64EncodingDynamicValueLineBreakCRMode,
  base64EncodingDynamicValueLineBreakLFMode,
  base64EncodingDynamicValueModeDecode,
  base64EncodingDynamicValueModeEncode,
  base64EncodingDynamicValueNewlinesAfter64Mode,
  base64EncodingDynamicValueNewlinesAfter76Mode,
  base64EncodingDynamicValueNewlinesDisabledMode,
} from './constants'

const identifier = 'com.luckymarmot.Base64EncodingDynamicValue'

const defaultValue: Base64EncodingDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  input: null,
  mode: null,
  newlinesMode: null,
  lineBreakMode: null,
}

const editForm: DynamicValues.EditForm<Base64EncodingDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'input',
      fieldType: 'dynamicString',
      label: 'Input',
    },
    {
      fieldKey: 'mode',
      fieldType: 'choice',
      label: 'Mode',
      choices: [
        {
          value: base64EncodingDynamicValueModeEncode,
          label: 'Encode',
        },
        {
          value: base64EncodingDynamicValueModeDecode,
          label: 'Decode',
        },
      ] as DynamicValues.EditFormRadioChoice[],
    },
    {
      fieldKey: 'newlinesMode',
      fieldType: 'choice',
      label: 'Newlines',
      choices: [
        {
          value: base64EncodingDynamicValueNewlinesDisabledMode,
          label: 'None',
        },
        {
          value: base64EncodingDynamicValueNewlinesAfter64Mode,
          label: '64 chars',
        },
        {
          value: base64EncodingDynamicValueNewlinesAfter76Mode,
          label: '76 chars',
        },
      ] as DynamicValues.EditFormRadioChoice[],
    },
    {
      fieldKey: 'lineBreakMode',
      fieldType: 'choice',
      label: 'Line Breaks',
      choices: [
        {
          value: base64EncodingDynamicValueLineBreakCRLFMode,
          label: 'CRLF (\\r\\n)',
        },
        {
          value: base64EncodingDynamicValueLineBreakLFMode,
          label: 'LF (\\n)',
        },
        {
          value: base64EncodingDynamicValueLineBreakCRMode,
          label: 'CR (\\r)',
        },
      ] as DynamicValues.EditFormRadioChoice[],
    },
  ],
}

const implBase64EncodingDynamicValue: DynamicValues.Implementation<Base64EncodingDynamicValueInterface> = {
  title: 'Base64 Encoding',
  identifier,
  defaultValue,
  editForm,
  getAllRefs(dv: Base64EncodingDynamicValueInterface) {
    return dv.input ? [dv.input] : null
  },

  getEvaluatedString: async (
    dv: Base64EncodingDynamicValueInterface,
    ctx: Evaluation.Ctx,
  ) => {
    // input
    const input = dv.input ? await evaluateDynamicString(dv.input, ctx) : ''

    // decode
    if (dv.mode === base64EncodingDynamicValueModeDecode) {
      return base64DecodeString(input, 'utf-8')
    }

    // encode
    const options: Base64EncodeOptions = {}
    switch (dv.newlinesMode) {
      case base64EncodingDynamicValueNewlinesAfter64Mode:
        options.outputLineLength = 64
        break
      case base64EncodingDynamicValueNewlinesAfter76Mode:
        options.outputLineLength = 76
        break
      default:
        options.outputLineLength = undefined
        break
    }
    switch (dv.lineBreakMode) {
      case base64EncodingDynamicValueLineBreakCRMode:
        options.outputNewLine = 'CR'
        break
      case base64EncodingDynamicValueLineBreakLFMode:
        options.outputNewLine = 'LF'
        break
      default:
        options.outputNewLine = undefined
        break
    }
    return base64EncodeString(input, 'utf-8', options)
  },
  getTokenInfo: async (
    dv: Base64EncodingDynamicValueInterface,
    ctx: Evaluation.Ctx,
  ) => ({
    title: 'Base64 Encoding',
    text: getStringEllipsis(
      dv.input ? await evaluateDynamicString(dv.input, ctx) : '',
      40,
    ),
  }),
}

export default implBase64EncodingDynamicValue
