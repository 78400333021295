import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/root-reducer'
import { MonacoEditorSettings } from 'store/slices/ui/settings/types.d'
import { AppUpdateNotifierState, SettingsState } from './types.d'

export const selectSettings = (state: RootState): SettingsState =>
  state.uiState.settingsState

export const selectPanelSettings = createSelector(
  selectSettings,
  (settings) => settings.panel,
)

export const selectTheme = createSelector(
  selectSettings,
  (settings) => settings.theme,
)

export const selectHotkeysHelp = createSelector(
  selectSettings,
  (settings) => settings.hotkeysHelp,
)

export const selectEditorConfig = createSelector(
  selectSettings,
  // due to deprecation, metioned in `store/slices/ui/settings/slice.ts#L31-L36`
  // the statuc declartion of values below forces the user to migrate to the new
  // settings structure without breaking the experience but will be able to persist
  // new settings values based on the new structure.
  (settings): MonacoEditorSettings => ({
    ...settings.editor,
    app: {
      vsPath: '/vs',
      ...settings.editor.app,
    },
    options: {
      readOnly: true,
      minimap: {
        enabled: false,
      },
      scrollbar: {
        verticalScrollbarSize: 5,
        horizontalScrollbarSize: 5,
      },
      renderLineHighlight: 'none' as const,
      ...settings.editor.options,
      theme: settings.theme === 'light' ? 'PawLight' : 'PawDark',
    },
  }),
)

export const selectAgentNotifierLastDismissedAt = createSelector(
  selectSettings,
  (settings) => settings.agentNotifier?.lastDismissedAt,
)

export const selectAgentNotifierShouldShow = createSelector(
  selectAgentNotifierLastDismissedAt,
  (lastDismissedAt) => {
    const msPerDay = 8.64e7
    return (
      !lastDismissedAt ||
      (new Date().getTime() - lastDismissedAt) / msPerDay > 1
    )
  },
)

export const selectShowExtensionsPanel = createSelector(
  selectSettings,
  (settings) => settings.extensions?.showPanel,
)

export const selectActiveCodeGeneratorIdentifier = createSelector(
  selectSettings,
  (settings) => settings.extensions?.codeGeneratorIdentifier,
)

export const selectAppUpdateNotifier = createSelector(
  selectSettings,
  (settings): AppUpdateNotifierState | null =>
    Object.prototype.hasOwnProperty.call(settings, 'appUpdateNotifier')
      ? settings.appUpdateNotifier
      : null,
)
