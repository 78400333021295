import { PrimaryWorkerState } from '../implementation/types.d'
import createWorkerNotification from './create-worker-notification'

export const didSyncProjectNotification = createWorkerNotification<
  PrimaryWorkerState.ProjectStateSyncReturn
>('didSyncProject')

export const didUpdateSyncBranchesNotification = createWorkerNotification<
  PrimaryWorkerState.ProjectStateStatusReturn
>('didUpdateSyncBranches')

export const didImportRequestsNotification = createWorkerNotification<
  PrimaryWorkerState.ProjectStateObjects
>('didImportRequests')
