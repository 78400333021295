import { Project } from 'lib/project/types.d'
import getOnlyString from './get-only-string'

const isDynamicStringEmpty = (
  dynamicString: Project.DynamicString,
): boolean => {
  const onlyString = getOnlyString(dynamicString)
  return onlyString !== null && onlyString.length === 0
}

export default isDynamicStringEmpty
