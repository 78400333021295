import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'

const assertType = (
  { _type: actualType }: CloudAPI.SyncTreeItem,
  expectedType: Project.ObjectTypes,
): void => {
  if (actualType !== expectedType) {
    throw new Error(
      `[assertType] Expect object type ${expectedType}, got ${actualType}`,
    )
  }
}

export default assertType
