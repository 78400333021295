import { Project } from 'lib/project/types.d'
import { getEnvironmentVariable, getEnvironmentDomain } from '../atoms'

const getDomainForVariable = (
  ref: Project.GenericRef<Project.EnvironmentVariable>,
  objects: Project.ObjectMap,
): Project.EnvironmentDomain | null => {
  const variable = getEnvironmentVariable(ref, objects, true)
  if (!variable) {
    return null
  }
  return getEnvironmentDomain(variable.domain, objects, true)
}

export default getDomainForVariable
