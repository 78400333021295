import React, { useCallback, useMemo } from 'react'
import jsonpath from 'jsonpath'
import { Box } from 'reflexbox'
import { Button } from '@rapidapi/ui-lib'
import { Editor } from 'ecosystems'
import {
  getHttpMessageBodyString,
  getMessageByteSize,
} from 'lib/request-handling'
import JsonInputFilter from 'ecosystems/field-editors/json-tree-editor/json-input-filter'
import JsonInputEmpty from 'ecosystems/field-editors/json-tree-editor/json-input-empty'
import { useDebounce } from 'utils'
import { useHttpExchangeMessage } from '../http-exchange-helper-hooks'
import { HttpExchangeTabViewProps } from '../http-exchange-tabs-props.d'
import TabBoxContainer from '../tab-box-container'
import HttpExchangeJsonErrorContent, {
  HttpExchangeJsonTextErrorMessage,
} from './json-tab-errors'
import {
  JSON_TEXT_SIZE_LIMIT,
  JSON_TEXT_MESSAGE_ACTION_TEXT,
  JSON_TEXT_MESSAGE_SIZE_ERROR,
  JSON_TAB_MESSAGE_INVALID_BODY,
  JSON_TAB_MESSAGE_EMPTY_BODY,
  JSON_TAB_MESSAGE_EMPTY_TITLE,
  JSON_TAB_MESSAGE_INVALID_TITLE,
  JSON_TAB_MESSAGE_SIZE_TITLE,
} from './json-tab-constants'

const HttpExchangeJsonTextTab: React.FC<HttpExchangeTabViewProps> = ({
  messageType,
}) => {
  const { httpMessage } = useHttpExchangeMessage(messageType)
  const [filterKeywordDebounce, filterKeyword, bindFilterKeyword] = useDebounce<
    string
  >('', 800)

  const [jsonTextContent, jsonContentSize, jsonTextContentError] = useMemo((): [
    string | null,
    number,
    HttpExchangeJsonTextErrorMessage | null,
  ] => {
    if (!httpMessage) {
      return [
        null,
        0,
        {
          title: JSON_TAB_MESSAGE_EMPTY_TITLE,
          text: JSON_TAB_MESSAGE_EMPTY_BODY(messageType),
        },
      ]
    }

    // If the size exceeds the limit,
    // omit further setup and return an error right away
    const byteSize = getMessageByteSize(httpMessage)
    if (byteSize > JSON_TEXT_SIZE_LIMIT) {
      return [
        null,
        byteSize,
        {
          title: JSON_TAB_MESSAGE_SIZE_TITLE,
          text: JSON_TEXT_MESSAGE_SIZE_ERROR,
        },
      ]
    }

    // get string
    const jsonString = getHttpMessageBodyString(httpMessage)
    if (!jsonString || jsonString.trim().length === 0) {
      return [
        null,
        byteSize,
        {
          title: JSON_TAB_MESSAGE_EMPTY_TITLE,
          text: JSON_TAB_MESSAGE_EMPTY_BODY(messageType),
        },
      ]
    }

    return [jsonString, byteSize, null]
  }, [httpMessage, messageType])

  const handleDownload = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()

      if (!httpMessage) {
        return
      }

      // get json (do not pretty-print, better return the original)
      const jsonString = getHttpMessageBodyString(httpMessage)

      // download blob
      const blobData = new Blob([jsonString || ''], {
        type: 'application/json',
      })
      const blobURL = window.URL.createObjectURL(blobData)
      const link = document.createElement('a')
      link.setAttribute('href', blobURL)
      link.setAttribute('download', 'request.json')
      link.setAttribute('style', 'display: none')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    [httpMessage],
  )

  const [
    jsonTextContentFiltered,
    jsonTextContentResults,
    jsonTextContentFilteredError,
  ] = useMemo((): [
    string | null,
    number,
    HttpExchangeJsonTextErrorMessage | null,
  ] => {
    function parseValidate(ctx: string): unknown | null {
      try {
        return JSON.parse(ctx)
      } catch (err) {
        return null
      }
    }

    const parsedJson: unknown | null = parseValidate(jsonTextContent as string)
    const prettyPrintedJson = JSON.stringify(parsedJson, null, 2)

    if (filterKeywordDebounce.trim() === '') {
      if (!parsedJson) {
        return [
          null,
          0,
          {
            title: JSON_TAB_MESSAGE_INVALID_TITLE,
            text: JSON_TAB_MESSAGE_INVALID_BODY(messageType),
          },
        ]
      }
      return [JSON.stringify(parsedJson, null, 2), 0, null]
    }

    try {
      const query = jsonpath.query(parsedJson, filterKeywordDebounce)
      const results = query.length
      if (results > 0) {
        return [JSON.stringify(query, null, 2), results, null]
      }
      return [prettyPrintedJson, results, null]
    } catch (err) {
      return [prettyPrintedJson, 0, null]
    }
  }, [jsonTextContent, filterKeywordDebounce, messageType])

  const jsonTextContentHasError =
    jsonTextContentError || jsonTextContentFilteredError

  if (jsonTextContentHasError) {
    return (
      <HttpExchangeJsonErrorContent errorMessage={jsonTextContentHasError}>
        {jsonContentSize > JSON_TEXT_SIZE_LIMIT && (
          <Box width="100%">
            <Button onClick={handleDownload}>
              {JSON_TEXT_MESSAGE_ACTION_TEXT}
            </Button>
          </Box>
        )}
      </HttpExchangeJsonErrorContent>
    )
  }

  return (
    <TabBoxContainer pt={5}>
      <JsonInputFilter
        keyword={filterKeyword}
        onChange={(keyword: string) => bindFilterKeyword(keyword)}
        onClear={() => bindFilterKeyword('')}
      />
      {filterKeyword.length > 0 && jsonTextContentResults <= 1 ? (
        <JsonInputEmpty />
      ) : (
        <Editor
          {...{
            language: 'json',
            readOnly: true,
            content: jsonTextContentFiltered || '',
          }}
        />
      )}
    </TabBoxContainer>
  )
}

export default HttpExchangeJsonTextTab
