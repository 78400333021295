import React, { useCallback, useState } from 'react'
import { Flex, Box } from 'reflexbox'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, Typography, Button, Dialog, Elevation } from '@rapidapi/ui-lib'
import {
  DownloadContentLoading,
  DownloadContentLoaded,
} from 'pages/download-page'
import { settingsStateActions } from 'store/actions'
import {
  selectAgentNotifierShouldShow,
  selectAgentStatus,
} from 'store/selectors'
import { AgentStatus } from 'store/types.d'
import { useDownloadLinks } from 'utils'

type AgentNotificationInstallProps = AgentStatus & {
  launch: (e: React.MouseEvent) => void
  dismiss: (e: React.MouseEvent) => void
}

const AgentNotificationInstall: React.FC<AgentNotificationInstallProps> = ({
  installed = false,
  active = false,
  launch,
  dismiss,
}) => {
  const [targets, isLoading, hasError] = useDownloadLinks()
  const [showDialog, setShowDialog] = useState(false)
  const onClose = () => setShowDialog(false)
  return (
    <>
      <Dialog width={540} height="auto" open={showDialog} onClose={onClose}>
        <Box textAlign="center" m={5} p={5}>
          <Icon mx="auto" mb={5} size={64} symbol="luckymarmot" />
          {isLoading ? (
            <DownloadContentLoading />
          ) : (
            <DownloadContentLoaded targets={targets} errorMessage={hasError} />
          )}
        </Box>
      </Dialog>
      <Elevation>
        <Flex justifyContent="center" alignItems="center">
          <Typography variant="caption" mr={2}>
            Download Paw Desktop to test localhost requests.
          </Typography>
          <Box>
            {installed && !active && (
              <Button onClick={launch}>Open Paw Helper</Button>
            )}
            {!installed && !active && (
              <Button onClick={() => setShowDialog(!showDialog)} ml={2}>
                Learn More
              </Button>
            )}
            <Button onClick={dismiss} variant="secondary" ml={2}>
              Dismiss
            </Button>
          </Box>
        </Flex>
      </Elevation>
    </>
  )
}

const AgentNotifier: React.FC = () => {
  const { installed, active, checked } = useSelector(selectAgentStatus)
  const showNotification = useSelector(selectAgentNotifierShouldShow)

  // launch agent
  const launch = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    window.location.href = 'octopaw://start'
  }, [])

  // dismiss notification
  const dispatch = useDispatch()
  const dismiss = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      dispatch(
        settingsStateActions.setAgentNotifierLastDismissedAt(
          new Date().getTime(),
        ),
      )
    },
    [dispatch],
  )

  return (
    <Flex
      justifyContent="center"
      css={{
        position: 'fixed',
        bottom: 18,
        left: '50%',
        zIndex: 9998,
        width: '75%',
        marginLeft: `-${75 / 2}%`,
      }}
    >
      {showNotification && checked && !active && (
        <AgentNotificationInstall
          {...{ installed, active, checked, launch, dismiss }}
        />
      )}
    </Flex>
  )
}

export default AgentNotifier
