import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  assertType,
  loadAnything,
  loadPrimitiveString,
} from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

const loadDynamicValue = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.DynamicValue => {
  // check type
  assertType(object, 'dynamicValue')

  // extra 'dynamic` keys
  const extraKeys = Object.keys(object).filter(
    (key) => key.indexOf('_') !== 0 && key !== 'identifier',
  )
  const extraValues = extraKeys.reduce((acc, key) => {
    acc[key] = loadAnything(loader, object[key])
    return acc
  }, {} as Project.ObjectMap<unknown>)

  const dv: Project.DynamicValue = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'dynamicValue',
    identifier: loadPrimitiveString(object.identifier),
    ...extraValues,
  }
  return dv
}

export default loadDynamicValue
