import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'reflexbox'
import { Hotkey, hkUndoAction, hkRedoAction } from 'utils'
import { undoAction, redoAction } from 'store/actions'
import { selectProjectCanRedo, selectProjectCanUndo } from 'store/selectors'

import { Icon, A, Tooltip } from '@rapidapi/ui-lib'

interface ProjectUndoButton {
  hidden?: boolean
}

const ProjectUndoButton: React.FC<ProjectUndoButton> = ({ hidden }) => {
  const canUndo = useSelector(selectProjectCanUndo)
  const canRedo = useSelector(selectProjectCanRedo)

  const dispatch = useDispatch()
  const undo = useCallback(() => dispatch(undoAction()), [dispatch])
  const redo = useCallback(() => dispatch(redoAction()), [dispatch])

  useEffect(() => {
    Hotkey.bind(hkUndoAction.hotkey, () => {
      dispatch(undoAction())
      return false
    })

    Hotkey.bind(hkRedoAction.hotkey, () => {
      dispatch(redoAction())
      return false
    })

    return () => {
      Hotkey.unbind(hkUndoAction.hotkey)
      Hotkey.unbind(hkRedoAction.hotkey)
    }
  }, [dispatch])

  if (hidden) {
    return null
  }

  return (
    <>
      <Tooltip content="Undo" placement="right">
        <Box py={2}>
          <A
            variant="secondary"
            displayInline={false}
            onClick={undo}
            disabled={!canUndo}
          >
            <Icon symbol="undo" size={24} />
          </A>
        </Box>
      </Tooltip>
      <Tooltip content="Redo" placement="right">
        <Box py={2}>
          <A
            variant="secondary"
            displayInline={false}
            onClick={redo}
            disabled={!canRedo}
          >
            <Icon symbol="redo" size={24} />
          </A>
        </Box>
      </Tooltip>
    </>
  )
}

export default ProjectUndoButton
