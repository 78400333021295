import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  loadObjectNullableRef,
  loadPrimitiveString,
  loadPrimitiveNumber,
  loadPrimitiveBoolean,
  assertType,
} from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

import loadDynamicString from './load-dynamic-string'

const loadRequestVariable = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.RequestVariable => {
  // check type
  assertType(object, 'requestVariable')

  const requestVariable: Project.RequestVariable = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'requestVariable',
    name: loadPrimitiveString(object.name),
    summary: loadPrimitiveString(object.summary, null),
    variableType: loadPrimitiveNumber(object.type), // warning: it's `type` in cloud format but it conflicts with `_type`
    required: loadPrimitiveBoolean(object.required),
    value: loadObjectNullableRef(
      loader,
      object.value as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
    schema: loadObjectNullableRef(
      loader,
      object.schema as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
  }
  return requestVariable
}

export default loadRequestVariable
