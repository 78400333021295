/* eslint-disable no-bitwise */

/**
 * getMixedColor
 *
 * @summary
 * a function that generates hexes based on initial color, alpha and background color.
 *
 * @param {string}  sourceColor
 * @param {number}  alpha
 * @param {string}  backgroundColor
 *
 * @returns {string<hex color>}
 */
const getMixedColor = (
  sourceColor: string,
  alpha: number,
  backgroundColor: string,
): string => {
  const rgbSourceColor = sourceColor.replace('#', '')
  const rgbBackgroundColor = backgroundColor.replace('#', '')

  const calcColor = (offset: number) =>
    Math.floor(
      parseInt(rgbSourceColor.substr(offset, 2), 16) * alpha +
        parseInt(rgbBackgroundColor.substr(offset, 2), 16) * (1 - alpha),
    )

  const color = (
    (calcColor(0) << 16) |
    (calcColor(2) << 8) |
    calcColor(4)
  ).toString(16)

  return `#${color}`
}

export default getMixedColor
