/**
 * Sends arbitrary HTTP requests via the Paw Cloud Proxy or Paw Agent Proxy.
 * This is useful for HTTP requests that are neither to Paw Cloud API nor
 * those set up by the user's projects themselves.
 *
 * For now, this is only being used by the OAuth 2 flow.
 */

export * from './app-fetch-types.d'
export { default as getAppFetch } from './get-app-fetch'
export { default as useAppFetch } from './use-app-fetch'
