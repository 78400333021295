import { createSelector } from '@reduxjs/toolkit'
import { selectProject } from './selectors'

export const selectProjectCanUndo = createSelector(
  selectProject,
  (project) => project.undoStack.length > 0,
)

export const selectProjectCanRedo = createSelector(
  selectProject,
  (project) => project.redoStack.length > 0,
)
