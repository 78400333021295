import { Project } from 'lib/project/types.d'

const getUuidFromRef = (
  ref: Project.GenericRef<Project.AnyObject>,
): string | null => {
  if (!ref) {
    return null
  }
  const uuid = ref.ref
  if (!uuid) {
    return null
  }
  return uuid.toLowerCase()
}

export default getUuidFromRef
