import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  assertType,
  serializeObjectRefArray,
} from 'lib/cloud-sync/serializer/helpers'
import { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

const serializeRequestGroup = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.RequestGroup,
): CloudAPI.SyncTreeItem => {
  // check type
  assertType(object, 'group')

  // children or null (Paw Mac spec)
  const children = serializeObjectRefArray(serializer, object.children)

  const syncTreeItem: CloudAPI.SyncTreeItem = {
    _uuid: object.uuid,
    _type: 'group',
    title: object.title,
    summary: object.summary,
    children: children.length > 0 ? children : null,
  }
  return syncTreeItem
}

export default serializeRequestGroup
