import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import { assertType } from 'lib/cloud-sync/serializer/helpers'
import { CloudSerializer } from 'lib/cloud-sync/serializer/types.d'

const serializeSession = (
  serializer: CloudSerializer.ProjectSerializer,
  object: Project.Session,
): CloudAPI.SyncTreeItem => {
  // check type
  assertType(object, 'session')

  const syncTreeItem: CloudAPI.SyncTreeItem = {
    _uuid: object.uuid,
    _type: 'session',
    name: object.name,
  }
  return syncTreeItem
}

export default serializeSession
