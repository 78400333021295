/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccountStore } from './types.d'

const initialState: AccountStore.State = {
  userId: null,
  userResponse: null,
}

export default createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountUserResponse: (
      state: AccountStore.State,
      {
        payload: { userResponse },
      }: PayloadAction<AccountStore.SetUserResponsePayload>,
    ) => {
      state.userId = userResponse?.id || null
      state.userResponse = userResponse || null
    },
  },
})
