import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  assertType,
  loadObjectRefArray,
  loadPrimitiveString,
} from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

const loadRequestGroup = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.RequestGroup => {
  // check type
  assertType(object, 'group')

  const request: Project.RequestGroup = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'group',
    title: loadPrimitiveString(object.title),
    summary: loadPrimitiveString(object.summary, null),
    children: loadObjectRefArray(
      loader,
      object.children as Project.GenericRef<Project.AnyRequestTreeItem>[],
    ),
  }
  return request
}

export default loadRequestGroup
