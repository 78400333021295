import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'reflexbox'

const StyledBox = styled(Box)({
  gridArea: 'aside',
})

const AppAside: React.FC = ({ children }) => (
  <StyledBox
    as="aside"
    bg="backgrounds.secondary"
    color="content.primary"
    width={304}
    overflow="hidden"
    display="flex"
    flexDirection="column"
  >
    {children}
  </StyledBox>
)

export default AppAside
