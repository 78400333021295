import { Project } from 'lib/project/types.d'

const addObject = <T extends Project.AnyObject>(
  objects: Project.ObjectMap,
  object: T,
): Project.GenericRef<T> => {
  if (!object.uuid) {
    throw new Error('[addObject] Does not have a UUID')
  }

  const ref: Project.GenericRef<T> = { ref: object.uuid }

  // eslint-disable-next-line no-param-reassign
  objects[ref.ref] = object

  return ref
}

export default addObject
