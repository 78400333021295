import { Project } from 'lib/project/types.d'
import { getRequest } from '../atoms'

const getVariableById = (
  requestRef: Project.GenericRef<Project.Request>,
  objects: Project.ObjectMap,
  matchUuid: string,
): Project.GenericRef<Project.RequestVariable> | null => {
  const request = getRequest(requestRef, objects, false)
  const result = request.variables.find(
    (variableRef) => variableRef.ref === matchUuid,
  )
  return result || null
}

export default getVariableById
