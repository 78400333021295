import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import { assertType, loadPrimitiveString } from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

const loadSession = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.Session => {
  // check type
  assertType(object, 'session')

  const session: Project.Session = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'session',
    name: loadPrimitiveString(object.name),
  }
  return session
}

export default loadSession
