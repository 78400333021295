import { DynamicValues } from 'lib/dynamic-values/types.d'
import { evaluateDynamicString, Evaluation } from 'lib/evaluation'
import { Project } from 'lib/project'
import { getAllUuidsFromString } from 'lib/utils'
import {
  parseJsonWithDynamicStrings,
  serializeJsonWithDynamicStrings,
} from './parser'
import { JSONDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.JSONDynamicValue'

const defaultValue: JSONDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  json: '{}',
}

const editForm: DynamicValues.EditForm<JSONDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'json',
      fieldType: 'json',
      label: 'JSON',
    },
  ],
}

const implJSONDynamicValue: DynamicValues.Implementation<JSONDynamicValueInterface> = {
  title: 'JSON',
  identifier,
  defaultValue,
  editForm,
  getAllRefs(dv) {
    // eslint-disable-next-line react/destructuring-assignment
    if (dv.json) {
      const matches = getAllUuidsFromString(dv.json)
      return matches && Array.from(new Set(matches))
    }
    return null
  },

  getEvaluatedString: async (
    dv: JSONDynamicValueInterface,
    ctx: Evaluation.Ctx,
  ) => {
    const jsonStr = dv.json
    const subObjects = { ...ctx.project.objects }
    const subRoot: Project.GenericRef<Project.Project> = {
      ref: ctx.project.root?.ref || '',
    }
    if (!jsonStr || !subRoot) {
      return ''
    }

    const subCtx: Evaluation.Ctx = {
      ...ctx,
      project: {
        root: subRoot,
        objects: subObjects,
      },
    }

    try {
      const jsonTree = parseJsonWithDynamicStrings(subObjects, subRoot, jsonStr)
      return serializeJsonWithDynamicStrings(jsonTree, (ds) =>
        evaluateDynamicString(ds, subCtx),
      )
    } catch (error) {
      // return the original string as it is
      return jsonStr
    }
  },
  getTokenInfo: async () => ({
    title: 'JSON',
    text: null,
  }),
  isEmpty: (dv: JSONDynamicValueInterface) =>
    !dv.json || dv.json.length === 0 || dv.json === '{}',
}

export default implJSONDynamicValue
