const loadPrimitiveBoolean = (
  value: unknown,
  defaultValue = false,
): boolean => {
  if (value === undefined || value === null) {
    return defaultValue
  }
  return !!value
}

export default loadPrimitiveBoolean
