import { evaluateDynamicString, Evaluation, Project } from 'lib'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCtxEvaluationResultFactory } from 'store/selectors'

const useDynamicStringEvaluation = (
  dynamicStringRef: Project.GenericRef<Project.DynamicValue>,
): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selector, setSelector] = useState<(state: any) => string | null>(
    () => () => '',
  )
  const ctxSelector = useMemo(
    () => selectCtxEvaluationResultFactory((_, context) => context),
    [],
  )
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ctx: Evaluation.Ctx = useSelector(ctxSelector)!
  useEffect(() => {
    if (!ctx) {
      return
    }
    evaluateDynamicString(dynamicStringRef, ctx).then((data) => {
      setSelector(() => selectCtxEvaluationResultFactory(() => data))
    })
  }, [ctx, dynamicStringRef])
  const result = useSelector(selector)
  return result || ''
}

export default useDynamicStringEvaluation
