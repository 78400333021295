import { filterArrayNonNull } from 'lib/cloud-sync/loader/helpers'
import { Project } from 'lib/project/types.d'

// eslint-disable-next-line import/no-cycle
import duplicateObject from './duplicate-object'

type DuplicateAnythingInputItem =
  | Project.GenericRef<Project.AnyObject>
  | string
  | number
  | boolean

type DuplicateAnythingInput =
  | DuplicateAnythingInputItem
  | DuplicateAnythingInputItem[]
  | null

const duplicateAnything = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { input }: { input: DuplicateAnythingInput | null },
): DuplicateAnythingInput => {
  // inspired by `loadAnything()`

  // primitives
  if (
    typeof input === 'string' ||
    typeof input === 'number' ||
    typeof input === 'boolean'
  ) {
    return input
  }

  // arrays, it can be anything, just map the values
  if (input && Array.isArray(input)) {
    return filterArrayNonNull(
      (input || []).map((item) =>
        duplicateAnything(objects, root, { input: item }),
      ),
    ) as DuplicateAnythingInputItem[]
  }

  // objects are considered to be refs
  if (input && typeof input === 'object') {
    if (typeof input.ref !== 'string') {
      throw new Error(
        `duplicateAnything() called with an object that is not a { ref }`,
      )
    }
    return duplicateObject(objects, root, { sourceRef: input })
  }

  return null
}

export default duplicateAnything
