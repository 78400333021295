import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  assertType,
  loadObjectNullableRef,
} from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

import loadDynamicString from './load-dynamic-string'

const loadEnvironmentVariableValue = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.EnvironmentVariableValue => {
  // check type
  assertType(object, 'environmentVariableValue')

  const envValue: Project.EnvironmentVariableValue = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'environmentVariableValue',
    value: loadObjectNullableRef(
      loader,
      object.value as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
    variable: object.variable as Project.GenericRef<
      Project.EnvironmentVariable
    >,
    environment: object.environment as Project.GenericRef<Project.Environment>,
    domain: object.domain as Project.GenericRef<Project.EnvironmentDomain>,
  }
  return envValue
}

export default loadEnvironmentVariableValue
