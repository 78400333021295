import { Project } from 'lib/project'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAnalytics = (): any => (window as any).analytics as any

// eslint-disable-next-line import/prefer-default-export
export const segmentIdentify = (
  userId: string,
  properties?: Project.ObjectMap<string>,
): void => {
  const analytics = getAnalytics()
  if (analytics) {
    analytics.identify(userId, properties)
  }
}

// eslint-disable-next-line import/prefer-default-export
export const segmentTrack = (
  eventName: string,
  properties?: Project.ObjectMap<string>,
): void => {
  const analytics = getAnalytics()
  if (analytics) {
    analytics.track(`Octopaw ${eventName}`, properties)
  }
}
