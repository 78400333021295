const loadPrimitiveString = <T>(
  value: unknown,
  defaultValue: string | T = '',
): string | T => {
  if (typeof value !== 'string') {
    return defaultValue
  }
  return value
}

export default loadPrimitiveString
