import { Project } from 'lib/project/types.d'
import { getDynamicString, getRequest } from '../atoms'
import isDynamicStringEmptyOrPristine from './is-dynamic-string-empty-or-pristine'

const isBodyEmptyOrPristine = (
  ref: Project.GenericRef<Project.Request>,
  objects: Project.ObjectMap,
): boolean => {
  const request = getRequest(ref, objects, false)
  const bodyString = request.bodyString
    ? getDynamicString(request.bodyString, objects, true)
    : null
  return isDynamicStringEmptyOrPristine(bodyString, objects)
}

export default isBodyEmptyOrPristine
