import { GraphQLDynamicValueInterface, Project } from 'lib'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setProjectValue } from 'store/actions'
import useDynamicStringEvaluation from 'store/hooks/use-dynamic-string-evaluation.hook'
import { useDynamicValueObject } from 'utils'

const useSyncVariables = (
  objectRef: Project.GenericRef<GraphQLDynamicValueInterface>,
): void => {
  const dynamicValue = useDynamicValueObject<GraphQLDynamicValueInterface>(
    objectRef || { ref: '' },
  )
  const dispatch = useDispatch()
  const { gqlQuery, gqlVariables } = dynamicValue
  const gqlString = useDynamicStringEvaluation(gqlQuery || { ref: '' })

  useEffect(() => {
    if (!gqlString) {
      return
    }
    const paramStrings = /\(.*?\)/.exec(gqlString)

    if (paramStrings) {
      const queryVariablesString = paramStrings[0]

      if (queryVariablesString) {
        const previousVariables = JSON.parse(gqlVariables || '{}')
        const variables: Project.ObjectMap<string> = queryVariablesString
          .split(',')
          .map((e) => e.split(':').map((s) => s.trim()))
          .reduce((p: Project.ObjectMap<string>, c: string[]) => {
            // eslint-disable-next-line no-param-reassign
            p[c[0].substr(1, 9999)] = ''
            return p
          }, {})
        Object.keys(variables).forEach((key: string) => {
          if (previousVariables[key]) {
            variables[key] = previousVariables[key]
          }
        })
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              gqlVariables: JSON.stringify(variables),
            },
          }),
        )
      }
    } else {
      dispatch(
        setProjectValue({
          objectRef,
          update: {
            gqlVariables: JSON.stringify({}),
          },
        }),
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectRef, dispatch, gqlString])
}
export default useSyncVariables
