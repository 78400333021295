import React, { useMemo } from 'react'
import { hexy } from 'hexy'

import { EmptyState } from '@rapidapi/ui-lib'
import { Editor } from 'ecosystems/editor'
import { getHttpMessageBodyBinary } from 'lib/request-handling'

import { HttpExchangeTabViewProps } from '../http-exchange-tabs-props.d'
import { useHttpExchangeMessage } from '../http-exchange-helper-hooks'
import TabBoxContainer from '../tab-box-container'

const HttpExchangeHexTab: React.FC<HttpExchangeTabViewProps> = ({
  messageType,
}) => {
  const { httpMessage } = useHttpExchangeMessage(messageType)

  const content = useMemo(() => {
    if (!httpMessage) {
      return null
    }
    const bodyBytes = getHttpMessageBodyBinary(httpMessage)
    if (!bodyBytes) {
      return null
    }

    const byteArray: number[] = []
    bodyBytes.forEach((val) => {
      byteArray.push(val)
    })
    return hexy(byteArray)
  }, [httpMessage])

  if (!content) {
    return (
      <TabBoxContainer p={5}>
        <EmptyState
          symbol="danger"
          headline="Empty body"
          body={
            messageType === 'request'
              ? 'The request body is empty'
              : 'The response body is empty'
          }
        />
      </TabBoxContainer>
    )
  }

  return (
    <TabBoxContainer pt={5}>
      <Editor content={content} language="hex" />
    </TabBoxContainer>
  )
}

export default HttpExchangeHexTab
