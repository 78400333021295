import { Project } from 'lib/project/types.d'
import * as implementations from './implementations'
import { DynamicValues } from './types.d'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const implementationsArray: any[] = [
  implementations.implBodyFormKeyValueDynamicValue,
  implementations.implBodyMultipartFormDataDynamicValue,
  implementations.implEnvironmentVariableDynamicValue,
  implementations.implJSONDynamicValue,
  implementations.implJSONTreeDynamicValue,
  implementations.implGraphQLDynamicValue,
  implementations.implFileDynamicValue,
  implementations.implLocalValueDynamicValue,
  implementations.implRequestBodyLengthDynamicValue,
  implementations.implRequestMethodDynamicValue,
  implementations.implRequestRawBodyDynamicValue,
  implementations.implRequestURLDynamicValue,
  implementations.implRequestVariableDynamicValue,
  implementations.implBasicAuthDynamicValue,
  implementations.implOAuth1HeaderDynamicValue,
  implementations.implOAuth2DynamicValue,
  implementations.implBase64EncodingDynamicValue,
  implementations.implURLEncodingDynamicValue,
]

const getImplementations = (): DynamicValues.Implementation<
  Project.DynamicValue
>[] => implementationsArray

export default getImplementations
