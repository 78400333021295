import { loader, useMonaco } from '@monaco-editor/react'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { useEffect, useMemo } from 'react'
import { MonacoEditorSettings } from 'store/slices/ui/settings/types.d'
import { lightTheme, darkTheme } from '@rapidapi/ui-lib'

const useMonacoHook = (setting: MonacoEditorSettings): typeof monaco | null => {
  // declare loader config path once...
  loader.config({
    paths: { vs: setting.app.vsPath || '/vs' },
  })

  const mnco = useMonaco()
  const dark = useMemo(
    () =>
      ({
        base: 'vs-dark',
        inherit: true,
        rules: [
          {
            token: 'keyword',
            foreground: darkTheme.colors.content.accent,
            fontStyle: 'bold',
          },
          {
            token: 'variable',
            foreground: darkTheme.colors.content.accent,
            fontStyle: 'normal',
          },
          {
            token: 'string',
            foreground: darkTheme.colors.content.accent,
            fontStyle: 'normal',
          },
        ],
        colors: {
          'editor.foreground': darkTheme.colors.content.primary,
          'editor.background': darkTheme.colors.backgrounds.primary,
        },
      } as monaco.editor.IStandaloneThemeData),
    [],
  )

  const light = useMemo(
    () =>
      ({
        base: 'vs',
        inherit: true,
        rules: [
          {
            token: 'keyword',
            foreground: lightTheme.colors.content.accent,
            fontStyle: 'bold',
          },
          {
            token: 'variable',
            foreground: lightTheme.colors.content.accent,
            fontStyle: 'normal',
          },
          {
            token: 'string',
            foreground: lightTheme.colors.content.accent,
            fontStyle: 'normal',
          },
        ],
        colors: {
          'editor.foreground': lightTheme.colors.content.primary,
          'editor.background': lightTheme.colors.backgrounds.primary,
        },
      } as monaco.editor.IStandaloneThemeData),
    [],
  )

  useEffect(() => {
    if (!mnco) {
      return
    }
    mnco.editor.defineTheme('PawLight', light)
    mnco.editor.defineTheme('PawDark', dark)
  }, [mnco, dark, light])
  return mnco
}

export default useMonacoHook
