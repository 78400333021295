import React from 'react'
import { DataTable, DataTableLastRow } from '@rapidapi/ui-lib'
import { DataJSONField, DataJSONItem } from './json-data-table-types.d'
import JsonInputFilter from './json-input-filter'
import JsonInputEmpty from './json-input-empty'

interface JSONDataTableProps {
  fields: DataJSONField[]
  jsonTreeFilteredContent: DataJSONItem[] | null
  filterKeyword?: string
  jsonTreeFilteredContentResults?: number
  bindFilterKeyword?: React.Dispatch<string>
  lastRow?: DataTableLastRow
}

const JSONDataTable: React.FC<JSONDataTableProps> = ({
  fields,
  jsonTreeFilteredContent,
  filterKeyword = '',
  bindFilterKeyword,
  jsonTreeFilteredContentResults = 0,
  lastRow,
}) => (
  <>
    {filterKeyword.length > 0 && jsonTreeFilteredContentResults === 0 ? (
      <JsonInputEmpty />
    ) : (
      <>
        <DataTable
          fields={fields}
          items={(jsonTreeFilteredContent as DataJSONItem[]) || []}
          lastRow={lastRow}
        />
      </>
    )}
    {bindFilterKeyword && (
      <JsonInputFilter
        keyword={filterKeyword || ''}
        onChange={(keyword: string) => bindFilterKeyword(keyword)}
        onClear={() => bindFilterKeyword('')}
      />
    )}
  </>
)

export default JSONDataTable
