import { Evaluation } from 'lib/evaluation'
import {
  getDynamicString,
  getOnlyString,
  getRequest,
  getRequestBodyDynamicValue,
  Project,
} from 'lib/project'
import {
  implGraphQLDynamicValue,
  implBodyFormKeyValueDynamicValue,
  implBodyMultipartFormDataDynamicValue,
  implJSONDynamicValue,
  implJSONTreeDynamicValue,
} from 'lib/dynamic-values'
import { getUuid } from 'lib/utils'
import { RequestHandling } from '../types.d'

const headerContentTypeWWWFormEncoded = 'application/x-www-form-urlencoded'
const headerContentTypeJSON = 'application/json'
const headerContentTypeMultipartFormData = 'multipart/form-data'
const headerContentTypeGQL = 'application/json'
const headerContentTypeTextPlain = 'text/plain'

const getContentType = (
  concreteRequest: RequestHandling.ConcreteRequest,
  ctx: Evaluation.Ctx,
  charset = 'utf-8',
): string | null => {
  // determine dv type
  const { objects } = ctx.project
  const ref: Project.GenericRef<Project.Request> = {
    ref: concreteRequest.requestUuid,
  }

  // plain text
  const request = getRequest(ref, objects, false)
  const bodyString = request.bodyString
    ? getDynamicString(request.bodyString, objects, true)
    : null
  if (bodyString && getOnlyString(bodyString)) {
    return `${headerContentTypeTextPlain}; charset=${charset}`
  }

  // dynamic value
  const dynamicValue = getRequestBodyDynamicValue(ref, objects)
  switch (dynamicValue?.identifier) {
    case implBodyFormKeyValueDynamicValue.identifier:
      return `${headerContentTypeWWWFormEncoded}; charset=${charset}`
    case implGraphQLDynamicValue.identifier:
      return `${headerContentTypeGQL}; charset=${charset}`
    case implJSONDynamicValue.identifier:
      return `${headerContentTypeJSON}; charset=${charset}`
    case implJSONTreeDynamicValue.identifier:
      return `${headerContentTypeJSON}; charset=${charset}`
    case implBodyMultipartFormDataDynamicValue.identifier:
      return `${headerContentTypeMultipartFormData}; charset=${charset}; boundary=__X_PAW_BOUNDARY__`
    default:
      return null
  }
}

const addContentTypeHeader = (
  concreteRequest: RequestHandling.ConcreteRequest,
  ctx: Evaluation.Ctx,
  evaluatedRequest: Evaluation.EvaluatedRequest,
): Evaluation.EvaluatedRequest => {
  const hasContentType =
    evaluatedRequest.headers.findIndex(
      ({ name }) => name.toLowerCase() === 'content-type',
    ) >= 0

  // if already has a Content-Type header, do not add one automatically
  if (hasContentType) {
    return evaluatedRequest
  }

  // get the appropriate content type
  const contentType = getContentType(concreteRequest, ctx)

  // if none, return the same
  if (!contentType) {
    return evaluatedRequest
  }

  // add Content-Type header
  return {
    ...evaluatedRequest,
    headers: [
      ...evaluatedRequest.headers,
      {
        uuid: getUuid(),
        mode: 0,
        name: 'Content-Type',
        value: contentType,
      },
    ],
  }
}

const stripHeaderWhitespaces = (
  evaluatedRequest: Evaluation.EvaluatedRequest,
): Evaluation.EvaluatedRequest => ({
  ...evaluatedRequest,
  headers: evaluatedRequest.headers.map(({ name, value, ...more }) => ({
    ...more,
    name: name.trim(),
    value: value.trim(),
  })),
})

/*
 * Perform Automatic Modifications
 * Some modifications are required before sending the request:
 * - Adding Content-Type header depending on body type
 * - Strip whitespaces from headers
 * - (Not Supported Yet) Add Cookie header
 */
const performAutomaticRequestModifications = (
  concreteRequest: RequestHandling.ConcreteRequest,
  ctx: Evaluation.Ctx,
  inputEvaluatedRequest: Evaluation.EvaluatedRequest,
): Evaluation.EvaluatedRequest => {
  let evaluatedRequest = inputEvaluatedRequest

  // Strip header whitespaces
  evaluatedRequest = stripHeaderWhitespaces(evaluatedRequest)

  // Add Content-Type header
  evaluatedRequest = addContentTypeHeader(
    concreteRequest,
    ctx,
    evaluatedRequest,
  )

  return evaluatedRequest
}

export default performAutomaticRequestModifications
