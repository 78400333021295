import { Project } from 'lib/project/types.d'
import { getEnvironment, getEnvironmentDomain } from '../getters'
import deleteDynamicString from './delete-dynamic-string'
import deleteObject from './delete-object'

export interface DeleteEnvironmentArgs {
  ref: Project.GenericRef<Project.Environment>
}

const deleteEnvironment = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { ref }: DeleteEnvironmentArgs,
): Project.GenericRef<Project.Request> => {
  const environment = getEnvironment(ref, objects, false)
  const domain = getEnvironmentDomain(environment.domain, objects, false)

  // delete all values for this environment
  const values = (Object.values(objects).filter(
    ({ type }) => type === 'environmentVariableValue',
  ) as Project.EnvironmentVariableValue[]).filter(
    ({ environment: anEnvironment }) => anEnvironment.ref === ref.ref,
  )
  values.forEach(({ uuid, value }) => {
    if (value) {
      deleteDynamicString(objects, root, { ref: value })
    }

    deleteObject(objects, root, { ref: { ref: uuid } })
  })

  deleteObject(objects, root, { ref })

  // delete this environment from its domain
  domain.environments = domain.environments.filter(
    ({ ref: aRef }) => aRef !== ref.ref,
  )

  return ref
}

export default deleteEnvironment
