import { Middleware } from '@reduxjs/toolkit'

import { RootState } from 'store/root-reducer'
import { PrimaryProjectWorker } from 'worker/primary-worker'
import { PPWNotification } from '../types.d'

import handleAction from './handle-action'
import handleNotification from './handle-notification'

// eslint-disable-next-line @typescript-eslint/ban-types
const primaryWorkerMiddleware: Middleware<{}, RootState> = (store) => (
  next,
) => {
  let worker: PrimaryProjectWorker | null = null

  return (action) => {
    handleAction({
      action,
      worker,
      setWorker: (newWorker) => {
        if (newWorker) {
          newWorker.addNotificationObserver((notification: PPWNotification) => {
            handleNotification({
              notification,
              worker: newWorker,
              getState: () => store.getState(),
              next,
            })
          })
        }
        worker = newWorker
      },
      getState: () => store.getState(),
      next,
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('[primaryWorkerMiddleware] Error handling action in worker')
      throw error
    })
  }
}

export default primaryWorkerMiddleware
