import { PayloadAction } from '@reduxjs/toolkit'

import { extensionsLoadListAction, setProject } from 'store/actions'
import { selectExtensionScripts } from 'store/selectors'
import { getOAuthToken } from 'lib-hooks/cloud-api/cloud-api-auth'
import {
  configureWorkerAction,
  projectLoadWorkerAction,
} from 'worker/primary-worker/actions'
import { segmentTrack } from 'utils'

import extensionsLoadListActionHandler from './extensions-load-list-action-handler'
import { ActionHandler } from '../types.d'

const projectLoadActionHandler = async ({
  action: { payload: projectId },
  next,
  worker,
  setWorker,
  getState,
}: ActionHandler<PayloadAction<number>>): Promise<void> => {
  // store current project ID in Redux Store, so this middleware can be based off that
  next(setProject({ projectId }))

  // already has a worker, terminate it
  if (worker) {
    worker.terminate()
  }

  const { PrimaryProjectWorker } = await import('worker/primary-worker')
  // create a new worker
  const newWorker = new PrimaryProjectWorker(projectId)
  setWorker(newWorker)

  // first, configure the worker
  const authToken = getOAuthToken()
  if (!authToken) {
    throw new Error('User is not authenticated')
  }
  await newWorker.runAction(configureWorkerAction, { authToken })

  // analytics log
  segmentTrack('Project Load', { id: String(projectId) })

  let result
  try {
    result = await newWorker.runAction(projectLoadWorkerAction, {
      projectId,
    })
  } catch (error) {
    // @TODO show a message to the user
    // eslint-disable-next-line no-console
    console.error('[load project] syncing error', error)
    // eslint-disable-next-line no-alert
    window.alert(`Failed to load project.\n${error.message}`)
    return
  }

  // load project + persist project in the state
  const {
    root,
    objects,
    objectsBase,
    syncBranch,
    syncCommitMeta,
    syncBranches,
    cloudPendingChangesCount,
    realtimeIsConnected,
  } = result

  next(
    setProject({
      root,
      objects,
      objectsBase,
      syncState: {
        syncBranch,
        syncCommitMeta,
        syncBranches,
        cloudPendingChangesCount,
        realtimeIsConnected,
      },
    }),
  )

  // load extensions
  await extensionsLoadListActionHandler({
    getState,
    action: extensionsLoadListAction({
      scripts: selectExtensionScripts(getState()),
    }),
    next,
    worker: newWorker,
    setWorker,
  })
}

export default projectLoadActionHandler
