import { Project } from 'lib/project/types.d'
import deleteObject from './delete-object'

const deleteDynamicString = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    ref,
  }: {
    ref: Project.GenericRef<Project.DynamicString>
  },
): Project.GenericRef<Project.DynamicString> =>
  // note: it's the caller's responsibility to delete the
  // reference to this dynamic string

  deleteObject(objects, root, { ref })

export default deleteDynamicString
