import { Project } from 'lib/project/types.d'

const getOnlyDynamicValue = (
  dynamicString: Project.DynamicString,
): Project.GenericRef<Project.DynamicValue> | null => {
  if (dynamicString.strings.length !== 1) {
    return null
  }
  const first = dynamicString.strings[0]
  if (typeof first === 'object') {
    return first
  }
  return null
}

export default getOnlyDynamicValue
