import { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getProjectRoot = <Optional extends true | false>(
  ref: Project.GenericRef<Project.Project>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true ? Project.Project | null : Project.Project =>
  getObject(ref, objects, isOptional, 'project')

export default getProjectRoot
