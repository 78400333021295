import { Project } from 'lib/project/types.d'
import {
  findRequestTreeItemParent,
  getProjectRoot,
  getRequest,
  getRequestGroup,
} from '../getters'
import deleteObject from './delete-object'
import deleteKeyValue from './delete-key-value'
import deleteDynamicString from './delete-dynamic-string'
import deleteRequestVariable from './delete-request-variable'

const deleteRequest = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    ref,
    parentRef,
  }: {
    ref: Project.GenericRef<Project.Request>
    parentRef?: Project.GenericRef<Project.RequestGroup> | null
  },
): Project.GenericRef<Project.Request> => {
  // find the parent if not provided
  let foundParentRef:
    | Project.GenericRef<Project.RequestGroup>
    | null
    | undefined
  if (parentRef === undefined) {
    foundParentRef = findRequestTreeItemParent(ref, objects, root)
  } else {
    foundParentRef = parentRef
  }

  // remove reference to this Request
  if (foundParentRef) {
    // from a group
    const parentGroup = getRequestGroup(foundParentRef, objects, false)
    parentGroup.children = parentGroup.children.filter(
      (itemRef) => itemRef.ref !== ref.ref,
    )
  } else {
    // from project root
    const projectRoot = getProjectRoot(root, objects, false)
    projectRoot.requests = projectRoot.requests.filter(
      (itemRef) => itemRef.ref !== ref.ref,
    )
  }

  // delete children
  const request = getRequest(ref, objects, false)

  // delete Dynamic Strings
  if (request.method) {
    deleteDynamicString(objects, root, {
      ref: request.method,
    })
  }
  if (request.urlFull) {
    deleteDynamicString(objects, root, {
      ref: request.urlFull,
    })
  }
  if (request.bodyString) {
    deleteDynamicString(objects, root, {
      ref: request.bodyString,
    })
  }
  if (request.clientCertificate) {
    deleteDynamicString(objects, root, {
      ref: request.clientCertificate,
    })
  }

  // delete Key Values
  ;(request.headers || []).forEach((childRef) =>
    deleteKeyValue(objects, root, {
      ref: childRef,
      parentRef: ref,
      parentProperty: 'headers',
    }),
  )
  ;(request.urlParameters || []).forEach((childRef) =>
    deleteKeyValue(objects, root, {
      ref: childRef,
      parentRef: ref,
      parentProperty: 'urlParameters',
    }),
  )

  // delete Request Variables
  ;(request.variables || []).forEach((childRef) =>
    deleteRequestVariable(objects, root, {
      ref: childRef,
      parentRef: ref,
    }),
  )

  // delete Request
  return deleteObject(objects, root, { ref })
}

export default deleteRequest
