/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EnvironmentsState, SetCurrentEnvironmentPayload } from './types.d'

const initialState: EnvironmentsState = {
  currentEnvironments: {},
}

const environmentsSlice = createSlice({
  name: 'environments',
  initialState,
  reducers: {
    setSelectedEnvironment: (
      state: EnvironmentsState,
      {
        payload: { domainUuid, environmentUuid },
      }: PayloadAction<SetCurrentEnvironmentPayload>,
    ) => {
      state.currentEnvironments[domainUuid] = environmentUuid
    },
  },
})

export default environmentsSlice
