import { PPWPayloadBase, PPWMessage, PPWNotificationCreator } from '../types.d'

const createWorkerNotification = <
  Payload extends PPWPayloadBase = PPWPayloadBase,
  Type extends string = string
>(
  type: Type,
): PPWNotificationCreator<Type, Payload> => {
  function notificationMessageCreator(
    payload: Payload,
  ): PPWMessage<Type, Payload> {
    return { type, payload }
  }

  return {
    type,
    createNotification: notificationMessageCreator,
    matchNotification: (
      message: PPWMessage<Type, PPWPayloadBase>,
    ): message is PPWMessage<Type, Payload> => message.type === type,
  }
}

export default createWorkerNotification
