import { evaluateDynamicString } from 'lib/evaluation/evaluate'
import {
  getDynamicString,
  getParameter,
  isDynamicStringEmpty,
  Project,
} from 'lib/project'
import { DynamicValues } from 'lib/dynamic-values/types.d'
import { Evaluation } from 'lib/evaluation/types.d'
import { urlEncodeBodyComponent } from 'lib/utils'
import { BodyFormKeyValueDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.BodyFormKeyValueDynamicValue'

const defaultValue: BodyFormKeyValueDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  keyValues: [],
}

const defaultTokenInfo: DynamicValues.TokenInfo = {
  title: 'Form URL-Encoded',
  text: null,
}

const editForm: DynamicValues.EditForm<BodyFormKeyValueDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'keyValues',
      fieldType: 'parameterList',
      label: 'Key Values',
    },
  ],
}

const implBodyFormKeyValueDynamicValue: DynamicValues.Implementation<BodyFormKeyValueDynamicValueInterface> = {
  title: 'Form URL-Encoded',
  identifier,
  defaultValue,
  editForm,
  getAllRefs(dv) {
    return dv.keyValues
  },
  getEvaluatedString: async (
    dv: BodyFormKeyValueDynamicValueInterface,
    ctx: Evaluation.Ctx,
  ) =>
    (
      await Promise.all(
        (dv.keyValues || [])
          .map((kvRef) => getParameter(kvRef, ctx.project.objects, false))
          .filter((param) => param && param.enabled)
          .map(
            async (param): Promise<[string, string]> => [
              param.key ? await evaluateDynamicString(param.key, ctx) : '',
              param.value ? await evaluateDynamicString(param.value, ctx) : '',
            ],
          ),
      )
    )
      .filter(([key, value]) => key.length > 0 || value.length > 0)
      .map(
        ([key, value]) =>
          `${urlEncodeBodyComponent(key)}=${urlEncodeBodyComponent(value)}`,
      )
      .join('&'),
  getTokenInfo: async () => defaultTokenInfo,
  isEmpty: (
    dv: BodyFormKeyValueDynamicValueInterface,
    objects: Project.ObjectMap,
  ) => {
    const kvs = (dv.keyValues || [])
      .map((kvRef) => getParameter(kvRef, objects, false))
      .filter((param) => {
        if (!param) {
          return false
        }
        return (
          (param.key &&
            !isDynamicStringEmpty(
              getDynamicString(param.key, objects, false),
            )) ||
          (param.value &&
            !isDynamicStringEmpty(
              getDynamicString(param.value, objects, false),
            ))
        )
      })
    return kvs.length === 0
  },
}

export default implBodyFormKeyValueDynamicValue
