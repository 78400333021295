import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  assertType,
  loadObjectNullableRef,
  loadPrimitiveBoolean,
} from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

import loadDynamicString from './load-dynamic-string'

const loadParameter = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.Parameter => {
  // check type
  assertType(object, 'parameter')

  const parameter: Project.Parameter = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'parameter',
    enabled: loadPrimitiveBoolean(object.enabled),
    key: loadObjectNullableRef(
      loader,
      object.key as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
    value: loadObjectNullableRef(
      loader,
      object.value as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
  }
  return parameter
}

export default loadParameter
