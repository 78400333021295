import React from 'react'
import { Flex } from 'reflexbox'
import { EmptyState, Typography } from '@rapidapi/ui-lib'
import TabBoxContainer from '../tab-box-container'

export interface HttpExchangeJsonTextErrorMessage {
  title: string
  text: string
}

const HttpExchangeJsonErrorContent: React.FC<{
  errorMessage: HttpExchangeJsonTextErrorMessage
}> = ({ errorMessage: { title, text }, children }) => (
  <TabBoxContainer p={5}>
    <Flex
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      <EmptyState symbol="alert" headline={title}>
        <Typography color="secondary" maxWidth={280} mx="auto" mb={3}>
          {text}
        </Typography>
        {children}
      </EmptyState>
    </Flex>
  </TabBoxContainer>
)

export default HttpExchangeJsonErrorContent
