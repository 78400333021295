import {
  didSyncProjectNotification,
  didUpdateSyncBranchesNotification,
  didImportRequestsNotification,
} from '../notifications'
import {
  handleDidSyncProject,
  handleDidUpdateSyncBranches,
} from './notification-handlers'
import handledidImportRequests from './notification-handlers/handle-did-import-requests'
import { NotificationHandler } from './types.d'

const handleNotification = (handler: NotificationHandler): Promise<void> => {
  const { notification } = handler

  if (didSyncProjectNotification.matchNotification(notification)) {
    return handleDidSyncProject({
      ...handler,
      notification,
    })
  }

  if (didUpdateSyncBranchesNotification.matchNotification(notification)) {
    return handleDidUpdateSyncBranches({
      ...handler,
      notification,
    })
  }
  if (didImportRequestsNotification.matchNotification(notification)) {
    return handledidImportRequests({
      ...handler,
      notification,
    })
  }
  return Promise.resolve()
}

export default handleNotification
