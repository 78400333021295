import { Project } from 'lib/project'

const mapDynamicFieldValueToStrings = (
  value: string | undefined,
): (string | Project.GenericRef<Project.DynamicValue>)[] => {
  if (!value) {
    return []
  }

  const regexp = /#([a-f0-9-]{32,36})#/gi
  let match
  const strings: (string | Project.GenericRef<Project.DynamicValue>)[] = []
  let idx = 0
  // eslint-disable-next-line no-cond-assign
  while ((match = regexp.exec(value)) !== null) {
    if (match.index > idx) {
      strings.push(value.substring(idx, match.index))
    }
    strings.push({ ref: match[1] })
    idx = regexp.lastIndex
  }
  if (idx < value.length) {
    strings.push(value.substring(idx, value.length))
  }

  return strings
}

export default mapDynamicFieldValueToStrings
