import { Project } from 'lib/project/types.d'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'
import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import {
  loadDynamicString,
  loadDynamicValue,
  loadEnvironment,
  loadEnvironmentDomain,
  loadEnvironmentVariable,
  loadEnvironmentVariableValue,
  loadParameter,
  loadRequest,
  loadRequestGroup,
  loadRequestVariable,
} from 'lib/cloud-sync/loader/object-loaders'

import getUuidFromRef from './get-uuid-from-ref'

const getLoader = (
  objectType: CloudAPI.ObjectTypes,
): CloudLoader.ObjectLoaderFn<Project.AnyObject> => {
  switch (objectType) {
    case 'request':
      return loadRequest
    case 'group':
      return loadRequestGroup
    case 'parameter':
      // note: key 'parameter' applies to Key Value and Parameter
      // (due to backward compatibility with Paw Mac)
      // but this shouldn't be an issue as Key Values are loaded explicitly
      // using `loadKeyValue` in loadRequest
      return loadParameter
    case 'requestVariable':
      return loadRequestVariable
    case 'dynamicValue':
      return loadDynamicValue
    case 'dynamicString':
      return loadDynamicString
    case 'environmentDomain':
      return loadEnvironmentDomain
    case 'environment':
      return loadEnvironment
    case 'environmentVariable':
      return loadEnvironmentVariable
    case 'environmentVariableValue':
      return loadEnvironmentVariableValue
    default:
      throw new Error(`Loading request tree item of unknown type ${objectType}`)
  }
}

const loadObjectRef = <T extends Project.AnyObject>(
  loader: CloudLoader.ProjectLoader,
  ref: Project.GenericRef<T>,
  loaderFn?: CloudLoader.ObjectLoaderFn<T>,
): Project.GenericRef<T> => {
  // get ref
  const uuid = getUuidFromRef(ref)
  if (!uuid) {
    throw new Error(`Missing UUID field in Ref "${JSON.stringify(ref)}"`)
  }

  // get object
  const object = loader.syncTree[uuid] as CloudAPI.SyncTreeItem
  if (!object) {
    throw new Error(`Missing object for uuid = ${uuid}`)
  }
  const { _uuid: objectUuid, _type: objectType } = object
  if (uuid !== 'root' && objectUuid.toLowerCase() !== uuid.toLowerCase()) {
    throw new Error(`Non-matching uuid = ${uuid} != ${objectUuid}`)
  }

  // get a loader function
  let aLoaderFn = loaderFn || null
  if (!aLoaderFn) {
    aLoaderFn = getLoader(objectType) as CloudLoader.ObjectLoaderFn<T>
  }

  // run loader to get an object result
  const r = aLoaderFn(loader, object)

  // eslint-disable-next-line no-param-reassign
  loader.objects[r.uuid] = r

  return { ref: r.uuid }
}

export default loadObjectRef
