import React from 'react'
import { Box, Flex } from 'reflexbox'
import { border, color } from 'styled-system'
import css from '@styled-system/css'
import styled from 'themes'

import { Icon, Typography } from '@rapidapi/ui-lib'
import { Project } from 'lib/project'
import useDynamicValueEvaluation from './hooks/use-dynamic-value-evaluation'

type DynamicValuePreviewProps = {
  objectRef: Project.GenericRef<Project.DynamicValue>
}

const StyledBox = styled(Box)(
  border,
  color,
  css({
    border: 1,
    borderStyle: 'solid',
    borderColor: 'fills.quanternary',
    borderRadius: 'default',
    backgroundColor: 'fills.quanternary',
    whiteSpace: 'pre-wrap',
    maxHeight: 80,
    overflow: 'scroll',
  }),
)

const DynamicValuePreview: React.FC<DynamicValuePreviewProps> = ({
  objectRef,
}) => {
  const result = useDynamicValueEvaluation(objectRef)

  return (
    <Box mt={3}>
      <Flex alignItems="flex-start" justifyContent="flex-start" mt={1}>
        <Icon symbol="info" size={14} mr={1} color="content.secondary" />
        <Typography variant="caption" color="secondary">
          Preview
        </Typography>
      </Flex>
      <StyledBox mt={1} p={2}>
        <Typography
          variant="captionMono"
          fontFamily="JetBrains Mono"
          color="secondary"
        >
          {
            result ||
              '\u200b' /* \u200b – a hack to keep a proper height for empty */
          }
        </Typography>
      </StyledBox>
    </Box>
  )
}

export default DynamicValuePreview
