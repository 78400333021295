import { Project } from 'lib/project/types.d'
import { getEnvironmentVariable } from '../atoms'

const getValueForVariable = (
  variableRef: Project.GenericRef<Project.EnvironmentVariable>,
  environmentRef: Project.GenericRef<Project.Environment>,
  objects: Project.ObjectMap,
): Project.EnvironmentVariableValue | null => {
  const variable = getEnvironmentVariable(variableRef, objects, true)
  if (!variable) {
    return null
  }
  const variableUuid = variable.uuid
  const domainUuid = variable.domain.ref
  const environmentUuid = environmentRef.ref
  return (Object.values(objects).find(
    (item) =>
      item.type === 'environmentVariableValue' &&
      item.domain.ref === domainUuid &&
      item.variable.ref === variableUuid &&
      item.environment.ref === environmentUuid,
  ) || null) as Project.EnvironmentVariableValue | null
}

export default getValueForVariable
