import React, { useEffect, useState } from 'react'

import { buildSchema, GraphQLSchema, IntrospectionQuery } from 'graphql'
import { useCurrentRequest } from 'utils'
import { selectCurrentRequestBodyDynamicValueRef } from 'store/selectors'
import { useSelector } from 'react-redux'
import { getRemoteSchema } from 'lib'
import styled from 'themes'
import emptySchema from 'lib/gql/empty-schema'
import { GQLEditor } from 'ecosystems/field-editors/gql-editor'
import { Box, Flex } from 'reflexbox'
import { layout, space } from 'styled-system'
import { useAppFetch } from 'utils/app-fetch'
import useDynamicStringEvaluation from 'store/hooks/use-dynamic-string-evaluation.hook'
import GQLTabs from './gql-tabs'

const FlexStyled = styled(Flex)(layout, space, {
  position: 'relative',
})

const BoxStyled = styled(Box)(
  layout,
  space,
  ({ theme: { radii, borders } }) => ({
    border: borders.disclosure,
    borderRadius: radii.default,
  }),
)

const BodyTypeGQL: React.FC = () => {
  const onlyDvRef = useSelector(selectCurrentRequestBodyDynamicValueRef)
  const request = useCurrentRequest()
  const url = useDynamicStringEvaluation(request?.urlFull || { ref: '' })

  const [schema, setSchema] = useState<GraphQLSchema>(buildSchema(emptySchema))
  const [schemaString, setSchemaString] = useState<string>(emptySchema)

  const appFetch = useAppFetch<{ data: IntrospectionQuery; errors: unknown }>()
  useEffect(() => {
    if (!url) {
      return
    }
    getRemoteSchema(appFetch, url).then((data) => {
      setSchema(buildSchema(data.schema || emptySchema))
      setSchemaString(data.schema || emptySchema)
    })
  }, [url, appFetch, setSchema, setSchemaString])

  return (
    <FlexStyled
      mt={2}
      flexBasis={0}
      flexGrow={1}
      overflow="hidden"
      flexDirection="column"
    >
      <BoxStyled pl={2} py={1} m={4} style={{ minHeight: '30vh' }}>
        <GQLEditor objectRef={onlyDvRef || { ref: '' }} schema={schema} />
      </BoxStyled>
      <GQLTabs schema={schema} schemaString={schemaString} />
    </FlexStyled>
  )
}

export default BodyTypeGQL
