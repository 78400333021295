import React, { useEffect, useMemo, useRef } from 'react'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { useSelector } from 'react-redux'
import { selectEditorConfig, selectTheme } from 'store/selectors'
import { Project } from 'lib/project'
import { Box } from 'reflexbox'
import { useMonacoHook } from 'utils'
import { MonacoLanguage } from './types'

export type GQLMonacoType<T extends Project.AnyObject> = {
  content: string
  objectRef: Project.GenericRef<T>
  options?: monaco.editor.IEditorOptions
  onBlur?: (editorContent: string) => void
  onChange?: (editorContent: string) => void
  onBeforeModelDispose?: (editorContent: string) => void
  // onMount is initialized after a model has been created, since there's no
  // need to alter the editor's composition, onMount should only only be used to
  // if there's a need to attach events after the editor has been created.
  onMount?: (
    editor: monaco.editor.IStandaloneCodeEditor,
    _monaco_: typeof monaco,
  ) => void
  language: MonacoLanguage
}

const AdvancedMonaco: React.FC<GQLMonacoType<Project.Request>> = ({
  objectRef,
  content,
  options,
  onMount,
  onBlur,
  onChange,
  onBeforeModelDispose,
  language,
}) => {
  const settings = useSelector(selectEditorConfig)
  const themeMode = useSelector(selectTheme)

  const monacoRef = useMonacoHook(settings)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)

  const editorOptions = useMemo(
    (): monaco.editor.IStandaloneEditorConstructionOptions => ({
      ...settings.options,
      glyphMargin: false,
      folding: false,
      lineNumbers: 'off',
      lineDecorationsWidth: 0,
      lineNumbersMinChars: 0,
      readOnly: false,
      tabSize: 2,
      contextmenu: false,
      automaticLayout: true,
      scrollBeyondLastLine: false,
      language: language.language,
      hideCursorInOverviewRuler: true,
      overviewRulerLanes: 0,
      ...options,
    }),
    [options, settings, language],
  )

  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current || !monacoRef) {
      return undefined
    }

    const editor = monacoRef.editor.create(wrapperRef.current, {
      ...editorOptions,
      value: content,
    })

    editorRef.current = editor
    if (language.addEditorActions) {
      language.addEditorActions(editor)
    }

    const model = monacoRef.editor.createModel(
      content || '',
      language.language,
      monacoRef.Uri.file(`${objectRef.ref}.${language.language}`),
    )

    editor.setModel(model)

    if (onBeforeModelDispose) {
      model.onWillDispose(() => onBeforeModelDispose(model.getValue()))
    }

    if (onBlur) {
      editor.onDidBlurEditorWidget(() => onBlur(model.getValue()))
    }

    if (onChange) {
      editor.onDidChangeModelContent(() => onChange(model.getValue()))
    }

    if (onMount) {
      onMount(editor, monaco)
    }

    return () => {
      if (!editorRef || !editorRef.current) {
        return undefined
      }
      const hasModel = editorRef.current.getModel()
      if (hasModel) {
        hasModel.dispose()
      }
      editorRef.current.dispose()
      return undefined
    }
  }, [
    wrapperRef,
    monacoRef,
    editorRef,
    objectRef,
    editorOptions,
    content,
    onMount,
    onBlur,
    onChange,
    onBeforeModelDispose,
    themeMode,
    language,
  ])
  useEffect(() => {
    if (!monacoRef || !language.provideCompletionItems) {
      return () => ({})
    }
    const disposable = monacoRef.languages.registerCompletionItemProvider(
      language.language,
      {
        provideCompletionItems: language.provideCompletionItems,
      },
    )
    return () => {
      disposable.dispose()
    }
  }, [monacoRef, language])

  return <Box width="100%" height="100%" ref={wrapperRef} />
}

export default AdvancedMonaco
