import { createSelector } from '@reduxjs/toolkit'
import { requestPlaceholderURL } from 'lib/evaluation'
import { getDynamicString, getAllStrings, getObject } from 'lib/project/getters'
import { Project } from 'lib/project/types.d'
import { getUrlPath } from 'lib/utils'
import { RootState } from 'store/root-reducer'
import { selectProjectObjects } from './selectors'

interface RequestFastPreviewResult {
  method: string
  path: string
  line: string
}

/**
 * A "selector factory" that creates a selector with a request preview.
 */
const selectRequestFastPreviewFactory = <T>(
  requestRef: Project.GenericRef<Project.Request>,
): ((state: RootState) => RequestFastPreviewResult | null) =>
  createSelector(
    selectProjectObjects,
    (objects): RequestFastPreviewResult | null => {
      const request = getObject(requestRef, objects, true)
      if (!request || request.type !== 'request') {
        return null
      }

      // get method
      const methodDs = request.method
        ? getDynamicString(request.method, objects, false)
        : null
      const method = (
        (methodDs ? getAllStrings(methodDs) : null) || 'GET'
      ).trim()

      // get url
      const urlDs = request.urlFull
        ? getDynamicString(request.urlFull, objects, false)
        : null
      const url = (
        (urlDs ? getAllStrings(urlDs, () => '•') : null) ||
        requestPlaceholderURL
      ).trim()
      const path = getUrlPath(url)

      const result: RequestFastPreviewResult = {
        method,
        path,
        line: `${method} ${path}`,
      }
      return result
    },
  )

export default selectRequestFastPreviewFactory
