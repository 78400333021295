import React from 'react'
import { Notification } from '@rapidapi/ui-lib'
import { RequestTabViewProps } from 'ecosystems/project/request-editor/request-tabs/request-tabs-props.d'

const RequestDefaultTab: React.FC<RequestTabViewProps> = () => (
  <Notification
    m={0}
    variant="error"
    icon="alert"
    title="Not Implemented"
    width="100%"
  >
    There is no tab defined yet
  </Notification>
)

export default RequestDefaultTab
