import { getHttpMessageContentType } from 'lib/request-handling'
import { RequestHandling } from 'lib/request-handling/types.d'

// eslint-disable-next-line import/prefer-default-export
export const getDefaultTab = (
  httpMessage: RequestHandling.AnyHTTPMessage,
): string => {
  const contentType = getHttpMessageContentType(httpMessage)
  if (contentType) {
    if (contentType.indexOf('json') >= 0) {
      return 'json'
    }
    if (contentType.indexOf('html') >= 0) {
      return 'web'
    }
    if (contentType.match(/^image\//)) {
      return 'image'
    }
    if (contentType.indexOf('octet-stream') >= 0) {
      return 'hex'
    }
  }
  return 'text'
}
