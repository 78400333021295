import { evaluateDynamicString } from 'lib/evaluation/evaluate'
import { DynamicValues } from 'lib/dynamic-values/types.d'
import { Evaluation } from 'lib/evaluation/types.d'
import { getHttpBasicAuth } from 'lib/utils'
import { getDynamicString, isDynamicStringEmpty } from 'lib/project/getters'
import { Project } from 'lib/project/types.d'
import { BasicAuthDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.BasicAuthDynamicValue'

const defaultValue: BasicAuthDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  username: null,
  password: null,
  charset: null,
}

const editForm: DynamicValues.EditForm<BasicAuthDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'username',
      fieldType: 'dynamicString',
      label: 'Username',
    },
    {
      fieldKey: 'password',
      fieldType: 'dynamicString',
      label: 'Password',
    },
  ],
}

const implBasicAuthDynamicValue: DynamicValues.Implementation<BasicAuthDynamicValueInterface> = {
  title: 'HTTP Basic Auth',
  identifier,
  defaultValue,
  editForm,
  getAllRefs(dv) {
    // eslint-disable-next-line react/destructuring-assignment
    if (dv.username || dv.password) {
      const ret = []

      if (dv.username) {
        ret.push(dv.username)
      }
      if (dv.password) {
        ret.push(dv.password)
      }

      return ret
    }
    return null
  },
  getEvaluatedString: async (
    dv: BasicAuthDynamicValueInterface,
    ctx: Evaluation.Ctx,
  ) => {
    // @TODO this won't work if input contains non-ascii characters as we should use the encoding (or at least UTF-8)
    const username = encodeURIComponent(
      dv.username ? await evaluateDynamicString(dv.username, ctx) : '',
    )
    const password = encodeURIComponent(
      dv.password ? await evaluateDynamicString(dv.password, ctx) : '',
    )
    return getHttpBasicAuth(username, password)
  },
  getTokenInfo: async (
    dv: BasicAuthDynamicValueInterface,
    ctx: Evaluation.Ctx,
  ) => ({
    title: 'HTTP Basic Auth',
    text: dv.username ? await evaluateDynamicString(dv.username, ctx) : null,
  }),
  isEmpty: (dv: BasicAuthDynamicValueInterface, objects: Project.ObjectMap) =>
    (!dv.username ||
      isDynamicStringEmpty(getDynamicString(dv.username, objects, false))) &&
    (!dv.password ||
      isDynamicStringEmpty(getDynamicString(dv.password, objects, false))),
}

export default implBasicAuthDynamicValue
