import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ExtensionStore from './types.d'

const initialState: ExtensionStore.State = {
  scripts: [
    'https://luckymarmot-content.s3.amazonaws.com/extensions/openapiv3-exporter/main.min.js',
    'https://luckymarmot-content.s3.amazonaws.com/extensions/openapiv3-importer/main.min.js',
    /**
     * @todo a temporary location of HttpSnippet
     * @see {@link https://app.clickup.com/t/23rx16r}
     */
    'https://electropaw.web.app/httpsnippet.min.js',
  ],
  list: [],
  generatedCodeResult: {
    content: '',
    extname: 'txt',
    language: 'text',
  },
}

/**
 * @method setExtensionsResource
 * @summary reducer that sets the available and extensions status.
 * @returns {ExtensionStore[]}
 */
function setExtensionsResource(
  state: ExtensionStore.State,
  action: PayloadAction<{
    extensions: ExtensionStore.Model | ExtensionStore.Model[]
  }>,
): void {
  const { extensions } = action.payload

  if (Array.isArray(extensions) && extensions.length > 0) {
    extensions.forEach((item: ExtensionStore.Model) => {
      const srcExists = state.scripts.findIndex(
        (ext: string) => ext === item.url,
      )

      const extExists = state.list.findIndex(
        (ext: ExtensionStore.Model) => ext.identifier === item.identifier,
      )

      if (srcExists === -1) {
        state.scripts.splice(0, 0, item.url)
      }

      if (extExists === -1) {
        state.list.splice(0, 0, item)
      } else {
        state.list.splice(extExists, 1, item)
      }
    })
  }

  // eslint-disable-next-line no-param-reassign
  state.scripts = [...state.scripts].filter((i) => i !== null)
}

/**
 * @method unSetExtensions
 * @summary reducer that sets the available and extensions status.
 * @returns {ExtensionStore[]}
 */
function unSetExtensionsResource(
  state: ExtensionStore.State,
  action: PayloadAction<string | string[]>,
): void {
  let scriptRsourceIndex
  let extensionIndex

  const { payload } = action

  if (Array.isArray(payload)) {
    payload.forEach((ext: string) => {
      scriptRsourceIndex = state.scripts.indexOf(ext)
      extensionIndex = state.list.findIndex(
        (item: ExtensionStore.Model) => item.url === ext,
      )
      // eslint-disable-next-line no-param-reassign
      state.scripts.splice(scriptRsourceIndex, 1)

      // eslint-disable-next-line no-param-reassign
      state.list.splice(extensionIndex, 1)
    })
    return
  }

  scriptRsourceIndex = state.scripts.indexOf(payload)
  extensionIndex = state.list.findIndex(
    (item: ExtensionStore.Model) => item.url === payload,
  )

  if (scriptRsourceIndex !== -1) {
    // eslint-disable-next-line no-param-reassign
    state.scripts.splice(scriptRsourceIndex, 1)

    // eslint-disable-next-line no-param-reassign
    state.list.splice(extensionIndex, 1)
  }
}

/**
 * @method activateExtension
 * @summary reducer that toggles the disabled status of an extension
 * @returns {ExtensionStore[]}
 */
function activateExtension(
  state: ExtensionStore.State,
  action: PayloadAction<ExtensionStore.Model>,
): void {
  const extension = action.payload
  const itemIndex = state.list.findIndex(
    (item: ExtensionStore.Model) => item.identifier === extension.identifier,
  )

  if (itemIndex === -1) {
    return
  }
  // eslint-disable-next-line no-param-reassign
  state.list = [...state.list].filter((i) => i !== null)
  // eslint-disable-next-line no-param-reassign
  state.list[itemIndex] = { ...extension, disabled: !extension.disabled }
}

function setGeneratedCodeOutput(
  state: ExtensionStore.State,
  action: PayloadAction<ExtensionStore.GeneratedCodeResult>,
): void {
  // eslint-disable-next-line no-param-reassign
  state.generatedCodeResult = action.payload
}

function setExportedRequestsOuput(
  state: ExtensionStore.State,
  action: PayloadAction<ExtensionStore.GeneratedCodeResult>,
): void {
  // eslint-disable-next-line no-param-reassign
  state.generatedCodeResult = action.payload
}

function unsetExportRequestsStatus(state: ExtensionStore.State): void {
  // eslint-disable-next-line no-param-reassign
  state.generatedCodeResult = {
    ...state.generatedCodeResult,
    generateFile: false,
  }
}

export default createSlice({
  name: 'extensions',
  initialState,
  reducers: {
    setExtensionsResource,
    unSetExtensionsResource,
    activateExtension,
    setGeneratedCodeOutput,
    setExportedRequestsOuput,
    unsetExportRequestsStatus,
  },
})
