/* eslint-disable react/jsx-props-no-spreading */
import { Dropzone } from '@rapidapi/ui-lib'
import { Project } from 'lib'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Flex } from 'reflexbox'
import { setProjectValue } from 'store/actions'
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system'
import { HttpExchangeStorage } from 'worker/primary-worker/implementation/http-exchanges'

const httpExchangeStorage = new HttpExchangeStorage()

type FilePickerProps = SpaceProps &
  LayoutProps &
  FlexboxProps & {
    objectRef: Project.GenericRef<Project.DynamicValue<{ fileName: string }>>
  }
const FilePicker = ({ objectRef, ...props }: FilePickerProps): JSX.Element => {
  const dispatch = useDispatch()
  const onChange = useCallback(
    ({ file }: { file?: File | null }) => {
      if (!file) {
        return
      }
      const r = new FileReader()
      const { name } = file

      r.readAsBinaryString(file)
      r.onload = () => {
        httpExchangeStorage.addFile({
          fileName: name,
          content: (r.result as string) || '',
          uuid: objectRef.ref,
          mimeType: file.type,
        })
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              fileName: name,
            },
          }),
        )
      }
    },
    [dispatch, objectRef],
  )

  return (
    <Flex {...props} flexDirection="column">
      <Dropzone
        status={false}
        callback={(e) => {
          onChange(e)
        }}
      />
    </Flex>
  )
}
export default FilePicker
