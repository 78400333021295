import { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'
import { getKeyValue } from '../getters'
import addObject from './add-object'

// eslint-disable-next-line import/no-cycle
import duplicateDynamicString from './duplicate-dynamic-string'

const duplicateKeyValue = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceRef }: { sourceRef: Project.GenericRef<Project.KeyValue> },
): Project.GenericRef<Project.KeyValue> => {
  // get the original/source
  const sourceKeyValue = getKeyValue(sourceRef, objects, false)

  // create a new Key Value
  const keyValue: Project.KeyValue = {
    uuid: getUuid(),
    type: 'parameter',
    enabled: sourceKeyValue.enabled,
    mode: sourceKeyValue.mode,
    name: sourceKeyValue.name
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceKeyValue.name,
        })
      : null,
    value: sourceKeyValue.value
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceKeyValue.value,
        })
      : null,
  }
  return addObject(objects, keyValue)
}

export default duplicateKeyValue
