import { Project } from 'lib/project/types.d'
import {
  findRequestTreeItemParent,
  getProjectRoot,
  getRequestGroup,
} from '../getters'
import deleteObject from './delete-object'
// eslint-disable-next-line import/no-cycle
import deleteRequestTreeItem from './delete-request-tree-item'

const deleteRequestGroup = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    ref,
    parentRef,
  }: {
    ref: Project.GenericRef<Project.RequestGroup>
    parentRef?: Project.GenericRef<Project.RequestGroup> | null
  },
): Project.GenericRef<Project.RequestGroup> => {
  // find the parent if not provided
  let foundParentRef:
    | Project.GenericRef<Project.RequestGroup>
    | null
    | undefined
  if (parentRef === undefined) {
    foundParentRef = findRequestTreeItemParent(ref, objects, root)
  } else {
    foundParentRef = parentRef
  }

  // remove reference to this Request
  if (foundParentRef) {
    // from a group
    const parentGroup = getRequestGroup(foundParentRef, objects, false)
    parentGroup.children = parentGroup.children.filter(
      (itemRef) => itemRef.ref !== ref.ref,
    )
  } else {
    // from project root
    const projectRoot = getProjectRoot(root, objects, false)
    projectRoot.requests = projectRoot.requests.filter(
      (itemRef) => itemRef.ref !== ref.ref,
    )
  }

  // delete children
  const group = getRequestGroup(ref, objects, false)
  ;(group.children || []).forEach((childRef) =>
    deleteRequestTreeItem(objects, root, {
      ref: childRef,
      parentRef: ref,
    }),
  )

  // delete Group
  return deleteObject(objects, root, { ref })
}

export default deleteRequestGroup
