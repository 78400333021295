import { Project } from 'lib/project/types.d'
import { getObject } from '../getters'
import deleteRequest from './delete-request'
// eslint-disable-next-line import/no-cycle
import deleteRequestGroup from './delete-request-group'

export interface DeleteRequestTreeItemArgs {
  ref: Project.GenericRef<Project.AnyRequestTreeItem>
  parentRef?: Project.GenericRef<Project.RequestGroup> | null
}

const deleteRequestTreeItem = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { ref, parentRef }: DeleteRequestTreeItemArgs,
): Project.GenericRef<Project.AnyRequestTreeItem> | null => {
  // get object type
  const object = getObject(ref, objects, false)

  if (object.type === 'request') {
    return deleteRequest(objects, root, { ref, parentRef })
  }
  if (object.type === 'group') {
    return deleteRequestGroup(objects, root, { ref, parentRef })
  }

  return null
}

export default deleteRequestTreeItem
