import { Project } from 'lib/project/types.d'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'
import loadObjectRef from './load-object-ref'

const loadObjectNullableRef = <T extends Project.AnyObject>(
  loader: CloudLoader.ProjectLoader,
  ref: Project.GenericRef<T> | null,
  loaderFn?: CloudLoader.ObjectLoaderFn<T>,
): Project.GenericRef<T> | null => {
  if (!ref) {
    return null
  }
  return loadObjectRef(loader, ref, loaderFn)
}

export default loadObjectNullableRef
