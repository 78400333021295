import { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getDynamicString = <Optional extends true | false>(
  ref: Project.GenericRef<Project.DynamicString>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true
  ? Project.DynamicString | null
  : Project.DynamicString =>
  getObject(ref, objects, isOptional, 'dynamicString')

export default getDynamicString
