import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/root-reducer'
import { AgentStatus, RequestHandlingState } from './types.d'

export const selectRequestHandlingState = (
  state: RootState,
): RequestHandlingState => state.requestHandling

export const selectPendingRequests = createSelector(
  selectRequestHandlingState,
  (state) => state.pendingRequests,
)

export const selectPendingRequestCount = createSelector(
  selectPendingRequests,
  (pendingRequests) => pendingRequests.length,
)

export const selectHasPendingRequests = createSelector(
  selectPendingRequests,
  (pendingRequests) => pendingRequests.length > 0,
)

export const selectAgentStatus = createSelector(
  selectRequestHandlingState,
  (state): AgentStatus => state.agentStatus,
)
