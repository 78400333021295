import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  assertType,
  loadObjectRefArray,
  loadPrimitiveString,
} from 'lib/cloud-sync/loader/helpers'

import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

import loadEnvironmentVariableValue from './load-environment-variable-value'
import loadEnvironmentVariable from './load-environment-variable'
import loadEnvironment from './load-environment'

const loadEnvironmentDomain = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.EnvironmentDomain => {
  // check type
  assertType(object, 'environmentDomain')

  // load all variables for this domain
  const { _uuid: objectUuid } = object
  const values: Project.GenericRef<
    Project.EnvironmentVariableValue
  >[] = (Object.values(loader.syncTree) as CloudAPI.SyncTreeItem[])
    .filter(
      ({ _type: itemType, domain: itemDomain }) =>
        itemType === 'environmentVariableValue' &&
        (itemDomain as Project.GenericRef<Project.EnvironmentDomain>).ref ===
          objectUuid,
    )
    .map(({ _uuid: itemUuid }) => ({ ref: itemUuid }))
  loadObjectRefArray(loader, values, loadEnvironmentVariableValue)

  // create domain
  const domain: Project.EnvironmentDomain = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'environmentDomain',
    name: loadPrimitiveString(object.name),
    environments: loadObjectRefArray(
      loader,
      object.environments as Project.GenericRef<Project.Environment>[],
      loadEnvironment,
    ),
    variables: loadObjectRefArray(
      loader,
      object.variables as Project.GenericRef<Project.EnvironmentVariable>[],
      loadEnvironmentVariable,
    ),
  }
  return domain
}

export default loadEnvironmentDomain
