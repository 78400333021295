import React from 'react'
import { Box, Flex } from 'reflexbox'
import { EmptyState } from '@rapidapi/ui-lib'

const JsonInputEmpty: React.FC = () => (
  <Flex height="100%" justifyContent="center" alignItems="center">
    <Box flexBasis={0} flexGrow={1} overflow="hidden">
      <EmptyState symbol="search" body="No matches found" />
    </Box>
  </Flex>
)

export default JsonInputEmpty
