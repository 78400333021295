import { Evaluation } from 'lib/evaluation/types.d'
import { Project } from 'lib/project/types.d'

import evaluateDynamicValue from './evaluate-dynamic-value'

const evaluateDynamicStringComponents = async (
  components: (string | Project.GenericRef<Project.DynamicValue>)[],
  ctx: Evaluation.Ctx,
): Promise<string> => {
  const strings = await Promise.all(
    components.map(async (component) => {
      if (typeof component === 'string') {
        return component
      }
      return evaluateDynamicValue(component, ctx)
    }),
  )
  return strings.join('')
}

export default evaluateDynamicStringComponents
