import { Environments } from 'lib/environments/types.d'
import { RequestHandling } from 'lib/request-handling/types.d'
import ExtensionStore from 'store/slices/extensions/types.d'
import { ProjectChange } from 'store/slices/project/types.d'
import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { PrimaryWorkerState } from 'worker/primary-worker/implementation/types.d'
import { PawExtension } from 'worker/primary-worker/implementation/extensions/types.d'

import createWorkerAction from './create-worker-action'
import { WorkerProjectSync } from '../implementation/project-sync/worker-project-sync.types'

/*
 * Initial/Configure Action
 */

export const configureWorkerAction = createWorkerAction<{ authToken: string }>(
  'configure',
)

/*
 * Project Actions
 */

export const projectLoadWorkerAction = createWorkerAction<
  { projectId: number },
  PrimaryWorkerState.ProjectStateLoadReturn
>('projectLoad')

export const projectSyncWorkerAction = createWorkerAction<
  WorkerProjectSync.SyncOptions,
  PrimaryWorkerState.ProjectStateSyncReturn
>('projectSync')

export const projectApplyChangesWorkerAction = createWorkerAction<
  { changes: ProjectChange[]; objectsBase: number },
  PrimaryWorkerState.ProjectStateApplyChangeReturn
>('projectApplyChanges')

/*
 * Send Request Action
 */

export const requestSendWorkerAction = createWorkerAction<
  {
    usePawAgent?: boolean
    requestUuid: string
    currentEnvironments: Environments.CurrentEnvironmentMap
  },
  RequestHandling.HTTPExchange
>('requestSend')

/*
 * HTTP Exchange Actions
 */

export const httpExchangeFetchListWorkerAction = createWorkerAction<
  { requestUuid: string },
  RequestHandling.HTTPExchange[]
>('httpExchangeFetchList')

export const httpExchangeFetchItemWorkerAction = createWorkerAction<
  { httpExchangeUuid: string },
  RequestHandling.HTTPExchange | null
>('httpExchangeFetchItem')

export const httpExchangeDeleteListWorkerAction = createWorkerAction<{
  requestUuid: string
}>('httpExchangeDeleteList')

export const httpExchangeDeleteItemWorkerAction = createWorkerAction<{
  httpExchangeUuid: string
}>('httpExchangeDeleteItem')

/*
 * Extension Actions
 */

export const extensionsLoadListWorkerAction = createWorkerAction<
  { scripts: string[] },
  ExtensionStore.Model[]
>('extensionsLoadListAction')

export const extensionUnloadItemWorkerAction = createWorkerAction<
  ExtensionStore.Model,
  ExtensionStore.Model[]
>('extensionsUnloadItemAction')

export const extensionsGenerateCodeWorkerAction = createWorkerAction<
  {
    extension: ExtensionStore.Model
    requestUuid: string | string[]
    options?: PawExtension.Options
    userInfo?: CloudAPI.UserAccountResponse | null
    currentEnvironments: Environments.CurrentEnvironmentMap
  },
  string
>('extensionsGenerateCodeWorkerAction')

export const extensionsExportRequestsWorkerAction = createWorkerAction<
  {
    extension: ExtensionStore.Model
    requestUuid: string | string[]
    options?: PawExtension.Options
    userInfo?: CloudAPI.UserAccountResponse | null
    generateFile?: boolean
    currentEnvironments: Environments.CurrentEnvironmentMap
  },
  string
>('extensionsExportRequestsWorkerAction')

export const extensionImportRequestsWorkerAction = createWorkerAction<
  {
    extension: ExtensionStore.Importer
    content: RequestHandling.FileStored
  },
  string
>('extensionsImportRequestWorkerAction')
