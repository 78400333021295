import { Project } from 'lib/project/types.d'
import { DraggedListItem } from '@rapidapi/ui-lib'
import { getRequestGroup } from '../getters'

export interface UpdateRequestsListArgs {
  sourceItem: DraggedListItem
  targetItem: DraggedListItem
}

const updateRequestsList = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceItem, targetItem }: UpdateRequestsListArgs,
): void => {
  const project = objects[root.ref] as Project.Project

  // Exctract reordered item
  let item: Project.GenericRef<Project.AnyRequestTreeItem>[]
  if (sourceItem.parentId) {
    const sourceParentGroup = getRequestGroup(
      { ref: sourceItem.parentId },
      objects,
      false,
    )
    item = sourceParentGroup.children.splice(sourceItem.index, 1)
  } else {
    item = project.requests.splice(sourceItem.index, 1)
  }

  // Put extracted item into a new position
  if (targetItem.parentId) {
    const targetParentGroup = getRequestGroup(
      { ref: targetItem.parentId },
      objects,
      false,
    )
    targetParentGroup.children.splice(targetItem.index, 0, item[0])
  } else {
    project.requests.splice(targetItem.index, 0, item[0])
  }
}

export default updateRequestsList
