import { DynamicValues } from 'lib/dynamic-values/types.d'
import { HttpExchangeStorage } from 'worker/primary-worker/implementation/http-exchanges'
import { FileDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.FileDynamicValue'

const defaultValue: FileDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  fileName: '',
}

const editForm: DynamicValues.EditForm<FileDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'file',
      fieldType: 'file',
      label: 'File',
    },
  ],
}

const implFileDynamicValue: DynamicValues.Implementation<FileDynamicValueInterface> = {
  title: 'File',
  identifier,
  defaultValue,
  editForm,
  getAllRefs() {
    return null
  },
  getEvaluatedString: async (dv: FileDynamicValueInterface) => {
    const storage = new HttpExchangeStorage()
    const file = await storage.fetchFile(dv.uuid)
    return file?.content
      ? `; filename="${file.fileName}"\r\n\r\n${file.content}`
      : ''
  },

  getTokenInfo: async (dv: FileDynamicValueInterface) => ({
    title: 'File',
    text: dv && dv.file ? dv.fileName : null,
  }),

  isEmpty: (dv: FileDynamicValueInterface) => !!dv,
}

export default implFileDynamicValue
