import { getImplementation } from 'lib/dynamic-values'
import { Project } from 'lib/project/types.d'
import { getDynamicValue } from 'lib/project'
import { Evaluation } from 'lib/evaluation/types.d'

const evaluateDynamicValue = async (
  dvRef: Project.GenericRef<Project.DynamicValue>,
  ctx: Evaluation.Ctx,
): Promise<string> => {
  const dv = getDynamicValue(dvRef, ctx.project.objects, false)
  const impl = getImplementation(dv)
  if (impl) {
    return impl.getEvaluatedString(dv, ctx)
  }
  // @TODO add a dynamic value evaluation error
  return `Missing DV '${dv.identifier}'`
}

export default evaluateDynamicValue
