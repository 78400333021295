import { useCallback, useState } from 'react'

import { CloudAPI, renameProject } from 'lib/cloud-sync/cloud-api'
import { getOAuthToken } from './cloud-api-auth'

type Status = 'idle' | 'loading' | 'succeeded' | 'failed'

type UseGetRenameProjectResult = {
  start: (projectId: number, projectName: string) => Promise<void>
  status: Status
  error: Error | null
  response: CloudAPI.ProjectInfoResponse | null
}

const useRenameProject = (): UseGetRenameProjectResult => {
  const [status, setStatus] = useState<Status>('idle')
  const [error, setError] = useState<Error | null>(null)
  const [response, setResponse] = useState<CloudAPI.ProjectInfoResponse | null>(
    null,
  )

  const start = useCallback(async (projectId: number, projectName: string) => {
    setStatus('loading')
    try {
      // get token
      const token = getOAuthToken()
      if (!token) {
        setError(new Error('User is not authenticated'))
        setStatus('failed')
        return
      }

      // rename project via Paw Cloud API
      const projectInfo = await renameProject(projectId, projectName, token)

      // success
      setResponse(projectInfo)
      setStatus('succeeded')
    } catch (anError) {
      // failure
      setError(anError)
      setStatus('failed')
    }
  }, [])

  return {
    start,
    status,
    error,
    response,
  }
}

export default useRenameProject
