/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Project } from 'lib/project/types.d'
import { CurrentItemState } from './types.d'

const initialState: CurrentItemState = {
  currentTreeItemRef: null,
}

const currentItemSlice = createSlice({
  name: 'itemSelection',
  initialState,
  reducers: {
    setCurrentTreeItemRef: (
      state,
      action: PayloadAction<Project.GenericRef<
        Project.AnyRequestTreeItem
      > | null>,
    ) => {
      state.currentTreeItemRef = action.payload
    },
  },
})

export default currentItemSlice
