import { DraggedListItem } from '@rapidapi/ui-lib'
import { Project } from 'lib/project'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { applyProjectSetter } from 'store/actions'

const useChangeOrder = <T extends Project.TypesWithLists>(
  objectRef: Project.GenericRef<T>,
  objectProperty: Project.GetListKeys<T>,
): ((sourceItem: DraggedListItem, targetItem: DraggedListItem) => void) => {
  const dispatch = useDispatch()
  return useCallback(
    (sourceItem, targetItem) => {
      dispatch(
        applyProjectSetter<T>('updateKeyValueList')({
          sourceIndex: sourceItem.index,
          targetIndex: targetItem.index,
          parentRef: objectRef,
          parentProperty: objectProperty,
        }),
      )
    },
    [dispatch, objectRef, objectProperty],
  )
}
export default useChangeOrder
