import { Project } from 'lib/project/types.d'
import getObject from './get-object'

const getEnvironmentVariable = <Optional extends true | false>(
  ref: Project.GenericRef<Project.EnvironmentVariable>,
  objects: Project.ObjectMap,
  isOptional: Optional,
): Optional extends true
  ? Project.EnvironmentVariable | null
  : Project.EnvironmentVariable =>
  getObject(ref, objects, isOptional, 'environmentVariable')

export default getEnvironmentVariable
