import React, { MutableRefObject, useMemo } from 'react'
import { Box, Flex } from 'reflexbox'
import { Divider, IconButton, Tooltip, Typography } from '@rapidapi/ui-lib'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { getOS } from 'utils'
import styled, { IconSymbol } from 'themes'
import {
  INSERT_HEADING,
  INSERT_BOLD,
  INSERT_ITALIC,
  INSERT_QUOTE,
  INSERT_CODE,
  INSERT_LINK,
  INSERT_ORDER,
  INSERT_LIST,
} from './markdown-monaco-actions'

export type MarkdownEditorToolbarType = {
  editorRef: MutableRefObject<monaco.editor.IStandaloneCodeEditor | null>
  isEditing: boolean
  togglePreview: () => void
}

export type MarkdownEditorActionButton = {
  key: string
  label: string
  keybinding: string
  button: {
    icon: IconSymbol
    action: (e?: React.MouseEvent) => void
  }
}

export type MarkdownEditorActionArray = Array<
  MarkdownEditorActionButton | string
>

const ToolbarContainer = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
})

const ActionButtonTooltip: React.FC<{ title: string; content: string }> = ({
  title,
  content,
}) => (
  <Box textAlign="center">
    <Typography fontWeight="bold" color="inherit" variant="caption">
      {title}
    </Typography>
    <Typography color="inherit" variant="captionMono">
      {content}
    </Typography>
  </Box>
)

const ToolbarActionButtons: React.FC<{
  toolbar: MarkdownEditorActionArray
  disabled?: boolean
}> = ({ toolbar, disabled }) => (
  <Flex>
    {toolbar.map((item: MarkdownEditorActionButton | string, idx: number) =>
      typeof item === 'string' ? (
        <Box key={`${item}${idx.toString()}`} mr={4} />
      ) : (
        <Box key={item.key}>
          <Tooltip
            content={
              <ActionButtonTooltip
                title={item.label}
                content={item.keybinding}
              />
            }
            placement="bottom"
            enterDelay={100}
            leaveDelay={100}
          >
            <IconButton
              data-role="toolbar-button"
              icon={item.button.icon}
              onClick={item.button.action}
              disabled={disabled || false}
            />
          </Tooltip>
        </Box>
      ),
    )}
  </Flex>
)

const MarkdownEditorToolbar: React.FC<MarkdownEditorToolbarType> = ({
  editorRef,
  isEditing,
  togglePreview,
}) => {
  const ToolbarButtons = useMemo(
    (): MarkdownEditorActionArray => [
      {
        key: 'heading',
        label: 'Heading',
        keybinding: getOS({
          win: '^ Ctrl + ⇧ Shift + H',
          macos: '⌘ Cmd + ⇧ Shift + H',
        }),
        button: {
          icon: 'num',
          action: () => {
            if (!editorRef || !editorRef.current) {
              return
            }
            editorRef.current.trigger(
              INSERT_HEADING,
              `${INSERT_HEADING}-action`,
              '',
            )
          },
        },
      },
      {
        key: 'bold',
        label: 'Bold',
        keybinding: getOS({
          win: '^ Ctrl + B',
          macos: '⌘ Cmd + B',
        }),
        button: {
          icon: 'bold',
          action: () => {
            if (!editorRef || !editorRef.current) {
              return
            }
            editorRef.current.trigger(INSERT_BOLD, `${INSERT_BOLD}-action`, '')
          },
        },
      },
      {
        key: 'italic',
        label: 'Italic',
        keybinding: getOS({
          win: '^ Ctrl + I',
          macos: '⌘ Cmd + I',
        }),
        button: {
          icon: 'italic',
          action: () => {
            if (!editorRef || !editorRef.current) {
              return
            }

            editorRef.current.trigger(
              INSERT_ITALIC,
              `${INSERT_ITALIC}-action`,
              '',
            )
          },
        },
      },
      'divider',
      {
        key: 'blockquote',
        label: 'Blockquote ',
        keybinding: getOS({
          win: '^ Ctrl + ⇧ Shift + >',
          macos: '⌘ Cmd + ⇧ Shift + >',
        }),
        button: {
          icon: 'quote',
          action: () => {
            if (!editorRef || !editorRef.current) {
              return
            }
            editorRef.current.trigger(
              INSERT_QUOTE,
              `${INSERT_QUOTE}-action`,
              '',
            )
          },
        },
      },
      {
        key: 'code',
        label: 'Code',
        keybinding: getOS({
          win: '^ Ctrl + ⇧ Shift + <',
          macos: '⌘ Cmd + ⇧ Shift + <',
        }),
        button: {
          icon: 'code',
          action: () => {
            if (!editorRef || !editorRef.current) {
              return
            }
            editorRef.current.trigger(INSERT_CODE, `${INSERT_CODE}-action`, '')
          },
        },
      },
      {
        key: 'link',
        label: 'Link',
        keybinding: getOS({
          win: '^ Ctrl + ⇧ Shift + L',
          macos: '⌘ Cmd + ⇧ Shift + L',
        }),
        button: {
          icon: 'link',
          action: () => {
            if (!editorRef || !editorRef.current) {
              return
            }
            editorRef.current.trigger(INSERT_LINK, `${INSERT_LINK}-action`, '')
          },
        },
      },
      'divider',
      {
        key: 'ul',
        label: 'Unordered List',
        keybinding: getOS({
          win: '^ Ctrl + U',
          macos: '⌘ Cmd + U',
        }),
        button: {
          icon: 'list',
          action: () => {
            if (!editorRef || !editorRef.current) {
              return
            }
            editorRef.current.trigger(INSERT_LIST, `${INSERT_LIST}-action`, '')
          },
        },
      },
      {
        key: 'ol',
        label: 'Ordered List',
        keybinding: getOS({
          win: '^ Ctrl + O',
          macos: '⌘ Cmd + O',
        }),
        button: {
          icon: 'order',
          action: () => {
            if (!editorRef || !editorRef.current) {
              return
            }
            editorRef.current.trigger(
              INSERT_ORDER,
              `${INSERT_ORDER}-action`,
              '',
            )
          },
        },
      },
    ],
    [editorRef],
  )

  const toolbarTogglePreview = useMemo(
    (): MarkdownEditorActionButton => ({
      key: 'toggle-preview',
      label: isEditing ? 'Preview' : 'Edit',
      keybinding: getOS({
        win: '^ Ctrl + ⇧ Shift + M',
        macos: '⌘ Cmd + ⇧ Shift + M',
      }),
      button: {
        icon: isEditing ? 'eye' : 'edit',
        action: togglePreview,
      },
    }),
    [togglePreview, isEditing],
  )

  return (
    <ToolbarContainer>
      <Box marginY={-2}>
        <Divider />
      </Box>
      <Flex
        p={2}
        justifyContent="flex-start"
        alignItems="center"
        backgroundColor="backgrounds.primary"
      >
        <ToolbarActionButtons toolbar={ToolbarButtons} disabled={!isEditing} />
        <Box ml="auto">
          <ToolbarActionButtons toolbar={[toolbarTogglePreview]} />
        </Box>
      </Flex>
    </ToolbarContainer>
  )
}

export default MarkdownEditorToolbar
