import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  assertType,
  loadObjectNullableRef,
  loadPrimitiveBoolean,
  loadPrimitiveNumber,
} from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

import loadDynamicString from './load-dynamic-string'

const loadKeyValue = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.KeyValue => {
  // check type
  assertType(object, 'parameter')

  const keyValue: Project.KeyValue = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'parameter',
    enabled: loadPrimitiveBoolean(object.isEnabled, true),
    mode: loadPrimitiveNumber(object.mode, 0),
    name: loadObjectNullableRef(
      loader,
      object.name as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
    value: loadObjectNullableRef(
      loader,
      object.value as Project.GenericRef<Project.DynamicString>,
      loadDynamicString,
    ),
  }
  return keyValue
}

export default loadKeyValue
