import styled from '@emotion/styled'
import { Flex } from 'reflexbox'

const TabBoxContainer = styled(Flex)<{ scrollable?: boolean }>(
  ({ scrollable }) => ({
    flexBasis: 0,
    flexGrow: 1,
    flexDirection: 'column',
    ...(scrollable
      ? {
          overflow: 'auto',
        }
      : undefined),
  }),
)

export default TabBoxContainer
