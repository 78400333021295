import { Project } from 'lib/project/types.d'
import walk from 'lib/project/helpers/walk'

const getAllUsedIds = (
  nextObjects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
): Set<string> => {
  const used = new Set<string>()

  used.add('root')

  walk(nextObjects, root, (obj) => {
    used.add(obj.uuid)
  })
  return used
}
export default getAllUsedIds
