/**
 * Function(s) to perform regular API requests to Paw Cloud, normally to
 * https://paw.cloud/api/v3/.
 *
 * This is a higher-level implementation based on fetch functions
 * available in `cloud-api/fetch`.
 */

export * from './api-fetch-types.d'
export { default as cloudApiFetch } from './cloud-api-fetch'
export { default as handleError } from './handle-error'
