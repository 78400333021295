import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import { assertType, loadPrimitiveString } from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

const loadEnvironment = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.Environment => {
  // check type
  assertType(object, 'environment')

  const env: Project.Environment = {
    // eslint-disable-next-line no-underscore-dangle
    uuid: object._uuid,
    type: 'environment',
    name: loadPrimitiveString(object.name),
    domain: object.domain as Project.GenericRef<Project.EnvironmentDomain>,
  }
  return env
}

export default loadEnvironment
