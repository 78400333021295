import { PayloadAction } from '@reduxjs/toolkit'

import { httpExchangesActions } from 'store/actions'
import { httpExchangeFetchListWorkerAction } from 'worker/primary-worker/actions'
import { RequestUuidPayload } from 'store/types.d'

import { ActionHandler } from '../types.d'

const httpExchangeFetchListActionHandler = async ({
  action: {
    payload: { requestUuid },
  },
  next,
  worker,
}: ActionHandler<PayloadAction<RequestUuidPayload>>): Promise<void> => {
  if (!worker) {
    throw new Error('Worker is missing')
  }
  const httpExchanges = await worker.runAction(
    httpExchangeFetchListWorkerAction,
    {
      requestUuid,
    },
  )
  next(
    httpExchangesActions.setHttpExchangesForRequest({
      requestUuid,
      httpExchanges,
    }),
  )
}

export default httpExchangeFetchListActionHandler
