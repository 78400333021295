import { DynamicValues } from 'lib/dynamic-values/types.d'
import {
  evaluateBody,
  evaluateDynamicString,
  evaluateFullURLString,
  evaluateMethodString,
  requestPlaceholderURL,
} from 'lib/evaluation'
import { getRequestLayer } from 'lib/evaluation/layers'
import { Evaluation } from 'lib/evaluation/types.d'
import {
  getDynamicString,
  getRequest,
  getRequestBodyDynamicValue,
  isDynamicStringEmpty,
} from 'lib/project/getters'
import { Project } from 'lib/project/types.d'
import { implBodyFormKeyValueDynamicValue } from '../body-form-key-value-dynamic-value'
import getOAuth1Header, { OAuth1Algorithm } from './get-oauth1-header'
import { OAuth1HeaderDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.OAuth1HeaderDynamicValue'

const requestOAuthSignatureAlgorithmHMACSHA1 = 'HMAC-SHA1'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const requestOAuthSignatureAlgorithmHMACSHA256 = 'HMAC-SHA256'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const requestOAuthSignatureAlgorithmRSASHA1 = 'RSA-SHA1'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const requestOAuthSignatureAlgorithmPLAINTEXT = 'PLAINTEXT'

const defaultValue: OAuth1HeaderDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  // DynamicStrings
  consumerKey: null,
  consumerSecret: null,
  tokenSecret: null,
  token: null,
  nonce: null,
  timestamp: null,
  callback: null,
  additionalParameters: null,
  // strings
  algorithm: requestOAuthSignatureAlgorithmHMACSHA1,
  // booleans
  hasBodyHash: false,
}

const editForm: DynamicValues.EditForm<OAuth1HeaderDynamicValueInterface> = {
  fields: [
    {
      fieldKey: 'consumerKey',
      fieldType: 'dynamicString',
      label: 'Consumer Key',
    },
    {
      fieldKey: 'consumerSecret',
      fieldType: 'dynamicString',
      label: 'Consumer Secret',
    },
    {
      fieldKey: 'token',
      fieldType: 'dynamicString',
      label: 'Token',
    },
    {
      fieldKey: 'tokenSecret',
      fieldType: 'dynamicString',
      label: 'Token Secret',
    },
    {
      fieldKey: 'algorithm',
      fieldType: 'choice',
      label: 'Signature Method',
      choices: [
        {
          value: requestOAuthSignatureAlgorithmHMACSHA1,
          label: 'HMAC-SHA1',
        },
        {
          value: requestOAuthSignatureAlgorithmHMACSHA256,
          label: 'HMAC-SHA256',
        },
        {
          value: requestOAuthSignatureAlgorithmRSASHA1,
          label: 'RSA-SHA1',
        },
        {
          value: requestOAuthSignatureAlgorithmPLAINTEXT,
          label: 'PLAINTEXT',
        },
      ] as DynamicValues.EditFormRadioChoice[],
    },
    {
      fieldKey: 'nonce',
      fieldType: 'dynamicString',
      label: 'Nonce',
      advanced: true,
    },
    {
      fieldKey: 'timestamp',
      fieldType: 'dynamicString',
      label: 'Timestamp',
      advanced: true,
    },
    {
      fieldKey: 'hasBodyHash',
      fieldType: 'checkbox',
      label: 'Add Body hash',
      hint:
        "The 'oauth_body_hash' param will be set when required by the spec.",
      hintIcon: 'info',
      advanced: true,
    },
    {
      fieldKey: 'callback',
      fieldType: 'dynamicString',
      label: 'Callback URL',
      hint: "Value for parameter 'oauth_callback'.",
      hintIcon: 'info',
      advanced: true,
    },
    {
      fieldKey: 'additionalParameters',
      fieldType: 'dynamicString',
      label: 'Additional Params',
      hint:
        "Additional parameters (e.g. oauth_key=value), keys must start with 'oauth_' and each parameter should be URL-encoded.",
      hintIcon: 'info',
      advanced: true,
    },
  ],
}

const implOAuth1HeaderDynamicValue: DynamicValues.Implementation<OAuth1HeaderDynamicValueInterface> = {
  title: 'OAuth 1',
  identifier,
  defaultValue,
  editForm,
  getAllRefs(dv) {
    const ret: Project.GenericRef<Project.AnyObject>[] = [
      dv.consumerKey,
      dv.consumerSecret,
      dv.token,
      dv.tokenSecret,
      dv.nonce,
      dv.timestamp,
      dv.callback,
      dv.additionalParameters,
    ].filter((x): x is Project.GenericRef<Project.AnyObject> => !!x)

    return ret.length ? ret : null
  },
  getEvaluatedString: async (
    dv: OAuth1HeaderDynamicValueInterface,
    ctx: Evaluation.Ctx,
  ) => {
    // Consumer
    const consumerKey = dv.consumerKey
      ? await evaluateDynamicString(dv.consumerKey, ctx)
      : ''
    const consumerSecret = dv.consumerSecret
      ? await evaluateDynamicString(dv.consumerSecret, ctx)
      : ''

    // Token
    const token = dv.token ? await evaluateDynamicString(dv.token, ctx) : ''
    const tokenSecret = dv.tokenSecret
      ? await evaluateDynamicString(dv.tokenSecret, ctx)
      : ''

    // Nonce & Timestamp
    const nonce = dv.nonce ? await evaluateDynamicString(dv.nonce, ctx) : ''
    const timestamp = dv.timestamp
      ? await evaluateDynamicString(dv.timestamp, ctx)
      : ''

    // callback & additionalParameters
    const oauthCallback = dv.callback
      ? await evaluateDynamicString(dv.callback, ctx)
      : ''
    const oauthAdditionalParams = dv.additionalParameters
      ? await evaluateDynamicString(dv.additionalParameters, ctx)
      : ''

    // Get request params from context (evaluate)
    const requestRef = getRequestLayer(ctx)?.requestRef
    const request = requestRef
      ? getRequest(requestRef, ctx.project.objects, false)
      : null
    const url = request
      ? await evaluateFullURLString(request, ctx)
      : requestPlaceholderURL
    const method = request ? await evaluateMethodString(request, ctx) : 'GET'
    const data = request ? await evaluateBody(request, ctx) : null
    const bodyDv = requestRef
      ? getRequestBodyDynamicValue(requestRef, ctx.project.objects)
      : null
    const requestBodyIsForm =
      !!bodyDv &&
      bodyDv.identifier === implBodyFormKeyValueDynamicValue.identifier

    // Generate
    const headerValue = getOAuth1Header({
      consumer: {
        key: consumerKey,
        secret: consumerSecret,
      },
      token: {
        key: token,
        secret: tokenSecret,
      },
      nonce,
      timestamp,
      oauthCallback,
      oauthAdditionalParams,
      algorithm: dv.algorithm as OAuth1Algorithm,
      requestOptions: {
        url,
        method,
        data,
        includeBodyHash: dv.hasBodyHash,
      },
      requestBodyIsForm,
    })
    return headerValue
  },
  getTokenInfo: async () => ({
    title: 'OAuth 1',
    text: 'Authorization',
  }),
  isEmpty: (
    dv: OAuth1HeaderDynamicValueInterface,
    objects: Project.ObjectMap,
  ) =>
    (!dv.consumerKey ||
      isDynamicStringEmpty(getDynamicString(dv.consumerKey, objects, false))) &&
    (!dv.consumerSecret ||
      isDynamicStringEmpty(
        getDynamicString(dv.consumerSecret, objects, false),
      )) &&
    (!dv.tokenSecret ||
      isDynamicStringEmpty(getDynamicString(dv.tokenSecret, objects, false))) &&
    (!dv.token ||
      isDynamicStringEmpty(getDynamicString(dv.token, objects, false))),
}

export default implOAuth1HeaderDynamicValue
