import { Project } from 'lib/project'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { applyProjectSetter } from 'store/actions'

const useDeleteKeyValue = (
  objectRef: Project.GenericRef<Project.Request>,
  objectProperty: Project.GetListKeys<Project.Request>,
): ((uuid: string) => void) => {
  const dispatch = useDispatch()
  return useCallback(
    (uuid) => {
      if (!objectRef || !objectRef.ref) {
        return
      }
      dispatch(
        applyProjectSetter('deleteKeyValue')({
          ref: { ref: uuid },
          parentRef: objectRef,
          parentProperty: objectProperty,
        }),
      )
    },
    [dispatch, objectRef, objectProperty],
  )
}
export default useDeleteKeyValue
