import { useCallback } from 'react'
import {
  localProjectStorageDeleteProject,
  localProjectStorageInit,
} from 'worker/primary-worker/implementation/project-sync/local-storage'
import { PrimaryWorkerState } from 'worker/primary-worker/implementation/types.d'

const clearProjectDataFn = async (projectId: number): Promise<boolean> => {
  // create a "fake" worker state so we can use the IndexedDB storage functions
  const workerState: PrimaryWorkerState.WorkerState = {
    authToken: null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    database: null as any,
    projectState: {
      projectId,
      syncTree: null,
      syncCommitMeta: null,
      syncBranch: null,
      syncBranches: {},
      cloudPendingChanges: [],
      root: null,
      objects: {},
      objectsBase: 0,
      localStorage: null,
      realtimeStatus: 'idle',
      autoSaveEnabled: true,
      autoSaveTimer: null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      projectSyncMutex: null as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      objectsMutex: null as any,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    notificationFn: null as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extensions: null as any,
  }

  // init the IndexedDB storage
  localProjectStorageInit(workerState)

  return localProjectStorageDeleteProject(workerState)
}

const useClearProjectData = (
  projectId: number | null,
): (() => Promise<boolean>) =>
  useCallback(async () => {
    if (!projectId) {
      return false
    }
    const isSuccess = await clearProjectDataFn(projectId)
    if (isSuccess) {
      window.location.reload()
    }
    return isSuccess
  }, [projectId])

export default useClearProjectData
