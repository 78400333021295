import { DynamicValues } from 'lib/dynamic-values/types.d'
import { Evaluation, evaluateDynamicString } from 'lib/evaluation'
import { Project } from 'lib/project'
import {
  parseJsonWithDynamicStrings,
  serializeJsonWithDynamicStrings,
} from '../json-dynamic-value/parser'
import { GraphQLDynamicValueInterface } from './types.d'

const identifier = 'com.luckymarmot.GraphQLDynamicValue'

const defaultValue: GraphQLDynamicValueInterface = {
  uuid: '',
  type: 'dynamicValue',
  identifier,
  gqlQuery: null,
  gqlVariables: `{}`,
  optAutoFetchSchema: true,
  optUseRequestVariables: false,
}

const editForm: DynamicValues.EditForm<GraphQLDynamicValueInterface> = {
  fields: [{ fieldKey: 'gqlQuery', fieldType: 'gqlQuery', label: 'GraphQL' }],
}

const implGraphQLDynamicValue: DynamicValues.Implementation<GraphQLDynamicValueInterface> = {
  title: 'GraphQL',
  identifier,
  defaultValue,
  editForm,
  getAllRefs: (dv: GraphQLDynamicValueInterface) =>
    dv.gqlQuery ? [dv.gqlQuery] : null,
  getEvaluatedString: async (
    dv: Project.DynamicValue<GraphQLDynamicValueInterface>,
    ctx: Evaluation.Ctx,
  ) => {
    const gqlStr = await evaluateDynamicString(dv.gqlQuery || { ref: '' }, ctx)
    const subObjects = { ...ctx.project.objects }
    const subRoot: Project.GenericRef<Project.Project> = {
      ref: ctx.project.root?.ref || '',
    }
    if (!gqlStr || !subRoot) {
      return ''
    }

    const requestString = JSON.stringify({
      query: gqlStr,
      variables: JSON.parse(dv.gqlVariables || '{}'),
    })

    const subCtx: Evaluation.Ctx = {
      ...ctx,
      project: {
        root: subRoot,
        objects: subObjects,
      },
    }

    try {
      const jsonTree = parseJsonWithDynamicStrings(
        subObjects,
        subRoot,
        requestString,
      )

      return serializeJsonWithDynamicStrings(jsonTree, (ds) =>
        evaluateDynamicString(ds, subCtx),
      )
    } catch (error) {
      // return the original string as it is
      return requestString
    }
  },
  getTokenInfo: async () => ({
    title: 'GraphQL',
    text: null,
  }),
  isEmpty: (dv: Project.DynamicValue<GraphQLDynamicValueInterface>) =>
    !dv.gqlQuery,
}

export default implGraphQLDynamicValue
