import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { Project } from 'lib/project/types.d'
import {
  loadObjectRefArray,
  loadPrimitiveString,
} from 'lib/cloud-sync/loader/helpers'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

import loadEnvironmentDomain from './load-environment-domain'
import loadSession from './load-session'

const loadProjectRoot = (
  loader: CloudLoader.ProjectLoader,
  object: CloudAPI.SyncTreeItem,
): Project.Project => ({
  // note: unfortunately, Paw Mac wasn't consistent and used `uuid` for projects
  // while using `_uuid` for other objects
  uuid: object.uuid as string,
  type: 'project',
  encryptionKeyHash: loadPrimitiveString(object.encryptionKeyHash, null),
  requests: loadObjectRefArray(
    loader,
    object.requests as Project.GenericRef<Project.AnyRequestTreeItem>[],
  ),
  environmentDomains: loadObjectRefArray(
    loader,
    object.environmentDomains as Project.GenericRef<
      Project.EnvironmentDomain
    >[],
    loadEnvironmentDomain,
  ),
  sessions: loadObjectRefArray(
    loader,
    object.sessions as Project.GenericRef<Project.Session>[],
    loadSession,
  ),
})

export default loadProjectRoot
