import { DynamicValues, getImplementation } from 'lib/dynamic-values'
import { getDynamicValue } from 'lib/project'
import { Project } from 'lib/project/types.d'
import { Evaluation } from 'lib/evaluation/types.d'

const evaluateDynamicValueTokenInfo = async (
  dvRef: Project.GenericRef<Project.DynamicValue>,
  ctx: Evaluation.Ctx,
): Promise<DynamicValues.TokenInfo> => {
  const dv = getDynamicValue(dvRef, ctx.project.objects, false)
  const impl = getImplementation(dv)
  if (impl) {
    return impl.getTokenInfo(dv, ctx)
  }
  return {
    title: `Missing DV '${dv.identifier}'`,
    text: null,
  }
}

export default evaluateDynamicValueTokenInfo
