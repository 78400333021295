const loadPrimitiveNumber = <T>(
  value: unknown,
  defaultValue: number | T = 0,
): number | T => {
  if (typeof value !== 'number') {
    return defaultValue
  }
  return value
}

export default loadPrimitiveNumber
