/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { useDispatch } from 'react-redux'
import { DynamicFieldProps, DynamicFieldSuggestion } from '@rapidapi/ui-lib'
import { IconSymbol } from 'themes/types.d'
import { Project } from 'lib/project'
import { getAllSuggestions, getImplementation } from 'lib/dynamic-values'
import { getUuid } from 'lib/utils'
import { useDynamicStringOptionalFromRef } from 'utils/hooks'
import { applyProjectSetter } from 'store/actions'
import { ApplyAddKeyValueAction } from 'store/types.d'
import { parseUrl } from 'utils'
import {
  mapDynamicFieldValueToStrings,
  parseTokenId,
  mapDynamicStringToFieldValue,
  isEqualStrings,
  useTokenInfoArray,
} from './helpers'
import { emptyTokenDict } from './helpers/use-token-info-array'
import DynamicStringInput from './dynamic-string-input'

type DynamicStringEditorProps<
  T extends Project.AnyObject
> = DynamicFieldProps & {
  dynamicStringRef?: Project.GenericRef<Project.DynamicString>
  objectRef?: Project.GenericRef<T>
  objectProperty?: keyof T
  label?: string
  hint?: string
  hintIcon?: IconSymbol
  autocompleteCategory?:
    | 'LMCompletionCategoryHTTPRequestHeaders'
    | 'LMCompletionCategoryHTTPRequestHeadersValue'
    | 'LMCompletionCategoryHTTPRequestParameters'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DynamicStringEditorReturnType = React.ReactElement<any, any> | null

const DynamicStringEditor = <T extends Project.AnyObject>({
  dynamicStringRef,
  objectRef,
  objectProperty,
  ...other
}: DynamicStringEditorProps<T>): DynamicStringEditorReturnType => {
  const dynamicString = useDynamicStringOptionalFromRef(
    dynamicStringRef,
    objectRef,
    objectProperty,
  )
  const [stringValue, dynamicValueRefs] = useMemo(
    () => mapDynamicStringToFieldValue(dynamicString),
    [dynamicString],
  )

  const dynamicValues = useTokenInfoArray(dynamicValueRefs)

  const dispatch = useDispatch()
  const lastValueRef = useRef<
    (string | Project.GenericRef<Project.DynamicValue>)[] | null
  >(null)

  useEffect(() => {
    if (stringValue === '') {
      lastValueRef.current = null
    }
  }, [stringValue])

  const suggestions = useMemo(() => getAllSuggestions(), [])
  const onChange = useCallback(
    (
      value: string | undefined,
      insertedSuggestion?: DynamicFieldSuggestion | undefined,
    ) => {
      let strings = mapDynamicFieldValueToStrings(value)
      let inserts: Project.AnyObject[] = []

      if (insertedSuggestion) {
        const insertedSuggestionId = parseTokenId(insertedSuggestion.id)
        const suggestion =
          insertedSuggestionId &&
          suggestions.find(({ id }) => id === insertedSuggestionId.ref)
        const insertedSuggestionNewUuid = insertedSuggestion.newId ?? getUuid()

        if (suggestion) {
          strings = strings.map((item) => {
            if (
              typeof item !== 'string' &&
              item.ref === insertedSuggestionId?.ref
            ) {
              return { ref: insertedSuggestionNewUuid }
            }
            return item
          })
          const impl = getImplementation({
            uuid: insertedSuggestionNewUuid,
            identifier: suggestion.dvIdentifier,
            type: 'dynamicValue',
          })
          inserts = [
            {
              ...impl?.defaultValue,
              uuid: insertedSuggestionNewUuid,
              identifier: suggestion.dvIdentifier,
              type: 'dynamicValue',
            },
          ]
        }
      }

      // only propagate update if it actually has changed
      // propagate changes and insert new dynamic values in the same dispatch()
      if (
        !lastValueRef.current ||
        !isEqualStrings(lastValueRef.current, strings) ||
        inserts.length > 0
      ) {
        lastValueRef.current = strings

        const getQueryParams = parseUrl(lastValueRef.current.join(''))

        if (getQueryParams && Object.keys(getQueryParams).length > 0) {
          const requestQueryParams = Object.keys(getQueryParams.query).map(
            (name): ApplyAddKeyValueAction => ({
              setter: 'addKeyValue',
              args: {
                parentRef: objectRef || { ref: '' },
                parentProperty: 'urlParameters',
                args: {
                  name,
                  value: getQueryParams.query[name],
                },
              },
            }),
          )

          dispatch(
            applyProjectSetter('updateCombo')({
              updates: [
                {
                  setter: 'updateDynamicString',
                  args: {
                    dynamicStringRef,
                    parentRef: objectRef,
                    parentProperty: objectProperty,
                    strings,
                    inserts,
                  },
                },
                ...requestQueryParams,
              ],
            }),
          )
        } else {
          dispatch(
            applyProjectSetter('updateDynamicString')({
              dynamicStringRef,
              parentRef: objectRef,
              parentProperty: objectProperty,
              strings,
              inserts,
            }),
          )
        }
      }
    },
    [dispatch, dynamicStringRef, objectRef, objectProperty, suggestions],
  )

  return (
    <DynamicStringInput
      onChange={onChange}
      dynamicValues={dynamicValues || emptyTokenDict}
      stringValue={stringValue}
      {...other}
      suggestions={suggestions}
    />
  )
}

export default DynamicStringEditor
