import { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'

import { getRequest } from '../getters'
import addDynamicString from './add-dynamic-string'
import addObject from './add-object'

export interface AddRequestVariableArg {
  parentRef: Project.GenericRef<Project.Request>
  parentProperty: Project.GetListKeys<Project.Request>
  uuid?: string
  args?: {
    name: string
    description?: string
    required?: boolean
    schema?: string | Project.GenericRef<Project.DynamicString>
    value: string | Project.GenericRef<Project.DynamicString>
    variableType?: unknown
  }
}

const addRequestVariable = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { parentRef, parentProperty, uuid, args }: AddRequestVariableArg,
): Project.GenericRef<Project.RequestVariable> => {
  const requestVar: Project.RequestVariable = {
    uuid: uuid || getUuid(),
    type: 'requestVariable',
    ...args,
    name: args?.name || '',
    summary: args?.description || '',
    required: args?.required || false,
    schema:
      args && args.schema && typeof args.schema === 'string'
        ? addDynamicString(objects, root, { string: args.schema })
        : addDynamicString(objects, root),
    value:
      args && args.value && typeof args.value === 'string'
        ? addDynamicString(objects, root, { string: args.value })
        : addDynamicString(objects, root),
    variableType: args?.variableType || 'string',
  }
  const ref = addObject(objects, requestVar)

  const request = getRequest(parentRef, objects, false)
  if (request[parentProperty]) {
    request[parentProperty].push(ref)
  } else {
    request[parentProperty] = [ref]
  }

  return ref
}

export default addRequestVariable
