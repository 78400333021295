/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Project } from 'lib/project/types.d'
import { useAnyObjectProperty } from 'utils/hooks'
import { setProjectValue } from 'store/actions'
import { selectPanelSettings } from 'store/selectors'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import { editorKeyBindings } from 'ecosystems/editor'
import { hkToggleMarkdownPreview, Hotkey } from 'utils'
import { Box } from 'reflexbox'
import AdvancedMonaco from 'ecosystems/advanced-editor/advanced-monaco'
import { MonacoLanguage } from 'ecosystems/advanced-editor/types'
import MarkdownPreview from './markdown-preview'
import MarkdownEditorWrapper from './markdown-editor-wrapper'
import MarkdownEditorToolbar from './markdown-editor-toolbar'
import markdownMonacoActions from './markdown-monaco-actions'

type MarkdownEditorType<T extends Project.AnyObject> = {
  objectRef: Project.GenericRef<T>
  objectProperty: keyof T
}

const MarkdownEditor: React.FC<MarkdownEditorType<Project.Request>> = ({
  objectRef,
  objectProperty,
}) => {
  const dispatch = useDispatch()
  const panelSettings = useSelector(selectPanelSettings)

  const content =
    (useAnyObjectProperty(
      objectRef,
      objectProperty,
      'string',
      true,
    ) as string) || ''

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null)
  const allowDispatchRef = useRef(false)

  const [showPreview, setShowPreview] = useState(true)
  const [editorContent, setEditorContent] = useState(content)
  const editorContentRef = useRef(editorContent)

  const togglePreview = useCallback((): void => {
    if (editorRef.current) {
      dispatch(
        setProjectValue({
          objectRef,
          update: {
            [objectProperty]: editorRef.current.getValue(),
          },
        }),
      )
    }
    setShowPreview(!showPreview)
  }, [showPreview, dispatch, objectRef, objectProperty])

  const onMount = useCallback(
    (editor: monaco.editor.IStandaloneCodeEditor) => {
      // Bind Editor instance to component
      editorRef.current = editor
      // Init keybindings for hotkeys to work
      editorKeyBindings(editor, monaco, dispatch, panelSettings)

      // KeyBinding to Toggle Preview
      editor.addAction({
        id: 'toggle-markdown-preview',
        label: 'Toggle Markdown Preview',
        keybindings: [
          // eslint-disable-next-line no-bitwise
          monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.KEY_M,
        ],
        run: () => {
          setShowPreview(!showPreview)
          editorContentRef.current = editor.getValue()
        },
      })

      editor.onDidFocusEditorWidget(() => {
        allowDispatchRef.current = true
      })

      editor.onDidChangeModelContent(() => {
        editorContentRef.current = editor.getValue()
      })
    },
    [editorRef, allowDispatchRef, dispatch, panelSettings, showPreview],
  )

  // Persist any changes before disposing the current model
  const onBlur = useCallback(
    (newEditorContent) => {
      dispatch(
        setProjectValue({
          objectRef,
          update: {
            [objectProperty]: newEditorContent,
          },
        }),
      )
    },
    [dispatch, objectRef, objectProperty],
  )

  const focusOut = useCallback(
    (event: FocusEvent): void => {
      function updateFn(): void {
        dispatch(
          setProjectValue({
            objectRef,
            update: {
              [objectProperty]: editorContentRef.current,
            },
          }),
        )
      }

      if (!event.relatedTarget && allowDispatchRef.current) {
        updateFn()
        return
      }

      const relTarget = event.relatedTarget as HTMLElement
      const editorTextArea = relTarget.getAttribute('aria-roledescription')
      const toolbarButton = relTarget.dataset.role

      if (
        (relTarget && toolbarButton === 'toolbar-button') ||
        (relTarget && editorTextArea === 'editor')
      ) {
        return
      }

      updateFn()
    },
    [editorContentRef, allowDispatchRef, dispatch, objectRef, objectProperty],
  )

  useEffect(() => {
    setEditorContent(content)
    Hotkey.bind(hkToggleMarkdownPreview.hotkey, () => {
      setShowPreview(!showPreview)
      return false
    })
    if (!wrapperRef || !wrapperRef.current) {
      return undefined
    }

    const elemWrapper = wrapperRef.current
    elemWrapper.addEventListener('focusout', focusOut)
    return () => {
      Hotkey.unbind(hkToggleMarkdownPreview.hotkey)
      if (elemWrapper) {
        elemWrapper.removeEventListener('focusout', focusOut)
      }
    }
  }, [content, wrapperRef, focusOut, showPreview])

  const language: MonacoLanguage = useMemo(
    () => ({
      addEditorActions: markdownMonacoActions,
      language: 'markdown',
    }),
    [],
  )

  return (
    <MarkdownEditorWrapper ref={wrapperRef}>
      {!showPreview && <MarkdownPreview content={editorContent} />}
      <Box
        height="100%"
        style={{ visibility: showPreview ? 'visible' : 'hidden' }}
      >
        <AdvancedMonaco
          content={editorContent}
          objectRef={objectRef}
          onBlur={onBlur}
          onMount={onMount}
          language={language}
        />
      </Box>

      <MarkdownEditorToolbar
        isEditing={showPreview}
        togglePreview={togglePreview}
        editorRef={editorRef}
      />
    </MarkdownEditorWrapper>
  )
}

export default MarkdownEditor
