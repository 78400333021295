import { Project } from 'lib/project/types.d'
import { ApplySetterAction } from 'store/slices/project/types.d'

import { getNamedSetter } from './named-setters'

const updateCombo = <T, Y>(
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { updates }: { updates: ApplySetterAction<T, Y>[] },
): void => {
  updates.forEach(({ setter, args }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setterFn = getNamedSetter(setter as any)
    setterFn(objects, root, args)
  })
}

export default updateCombo
