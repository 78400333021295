import { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'

import { getRequest } from '../getters'
import addDynamicString from './add-dynamic-string'
import addObject from './add-object'

export interface AddKeyValueArg {
  parentRef: Project.GenericRef<Project.Request>
  parentProperty: Project.GetListKeys<Project.Request>
  uuid?: string
  args?:
    | Partial<Project.KeyValue>
    | {
        name?: string
        value?: string
      }
}

const addKeyValue = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { parentRef, parentProperty, uuid, args }: AddKeyValueArg,
): Project.GenericRef<Project.KeyValue> => {
  const keyValue: Project.KeyValue = {
    uuid: uuid || getUuid(),
    type: 'parameter',
    enabled: true,
    mode: 0,
    ...args,
    name:
      args && args.name && typeof args.name === 'string'
        ? addDynamicString(objects, root, { string: args.name })
        : addDynamicString(objects, root),
    value:
      args && args.value && typeof args.value === 'string'
        ? addDynamicString(objects, root, { string: args.value })
        : addDynamicString(objects, root),
  }
  const ref = addObject(objects, keyValue)

  const request = getRequest(parentRef, objects, false)
  if (request[parentProperty]) {
    request[parentProperty].push(ref)
  } else {
    request[parentProperty] = [ref]
  }
  return ref
}

export default addKeyValue
