/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react'
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system'
import { Box, Flex } from 'reflexbox'
import { useDispatch } from 'react-redux'

import { getDynamicString, getOnlyString, Project } from 'lib/project'
import {
  parseJsonWithDynamicStrings,
  serializeJsonWithDynamicStrings,
} from 'lib/dynamic-values/implementations/json-dynamic-value/parser'
import { useAnyObjectProperty } from 'utils/hooks'
import { setProjectValue } from 'store/actions'

import { Button, EmptyState } from '@rapidapi/ui-lib'
import { Editor } from 'ecosystems/editor'

type JsonEditorProps<T extends Project.AnyObject> = SpaceProps &
  LayoutProps &
  FlexboxProps & {
    objectRef: Project.GenericRef<T>
    objectProperty: keyof T
  }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type JsonEditorReturnType = React.ReactElement<any, any> | null

const JsonEditor = <T extends Project.AnyObject>({
  objectRef,
  objectProperty,
  ...props
}: JsonEditorProps<T>): JsonEditorReturnType => {
  // get value directly from the store, even without retrieving the entire object
  const jsonValue = (useAnyObjectProperty(
    objectRef,
    objectProperty,
    'string' /* expect */,
    true /* allowsNull */,
  ) || '') as string

  const dispatch = useDispatch()
  const onChange = useCallback(
    (newValue) => {
      dispatch(
        setProjectValue({
          objectRef,
          update: {
            [objectProperty]: newValue,
          },
        }),
      )
    },
    [dispatch, objectRef, objectProperty],
  )

  const handlePrettyPrint = useCallback((): boolean => {
    try {
      const jsonObject = JSON.parse(jsonValue)
      const newValue = JSON.stringify(jsonObject, null, 2)
      dispatch(
        setProjectValue({
          objectRef,
          update: {
            [objectProperty]: newValue,
          },
        }),
      )
      return true
    } catch (e) {
      return false
    }
  }, [dispatch, objectRef, objectProperty, jsonValue])

  // determines if the JSON contains any Dynamic String
  // in which case, we currently cannot edit it
  const containsDvs = useMemo((): boolean => {
    try {
      const subObjects = {}
      const subRoot: Project.GenericRef<Project.Project> = { ref: '' }

      // parse and serialize to see if we find any DVs
      const jsonTree = parseJsonWithDynamicStrings(
        subObjects,
        subRoot,
        jsonValue,
      )
      let resultContainsDvs = false
      serializeJsonWithDynamicStrings(
        jsonTree,
        (dsRef) => {
          const ds = getDynamicString(dsRef, subObjects, false)
          const onlyString = getOnlyString(ds)
          // if onlyString is `null` (not empty), there's a dynamic value
          resultContainsDvs = resultContainsDvs || onlyString === null
          return Promise.resolve(onlyString || 'dv')
        },
        true,
      )
      return resultContainsDvs
    } catch (e) {
      return false
    }
  }, [jsonValue])

  if (containsDvs) {
    return (
      <EmptyState
        symbol="alert"
        headline="Cannot edit JSON"
        body="This JSON field contains one or more dynamic values. It cannot be edited in Paw Web yet."
      />
    )
  }

  return (
    <Flex {...props} flexDirection="column">
      <Flex justifyContent="flex-end" p={3}>
        <Box>
          <Button
            onClick={handlePrettyPrint}
            startIcon="refresh"
            variant="tertiary"
          >
            Pretty Print JSON
          </Button>
        </Box>
      </Flex>
      <Editor
        content={jsonValue}
        language="json"
        readOnly={false}
        onChange={onChange}
      />
    </Flex>
  )
}

export default JsonEditor
