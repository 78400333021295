/* eslint-disable @typescript-eslint/no-explicit-any */

import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { loadObjectRef } from './helpers'
import { CloudLoader } from './types.d'
import { loadProjectRoot } from './object-loaders'

/**
 * Reads a project "commit" (version) from the Paw Cloud API
 * and converts it into a flat structure of objects.
 * Note: the input and output structures are quite similar, both
 * are flat but there are subtle differences. Parsing also enforces
 * data consistency.
 * @param commit Commit data from Paw Cloud
 * @return A Cloud Project Loader (flat structure)
 */
const loadProject = (
  syncTree: CloudAPI.SyncTree,
): CloudLoader.ProjectLoader => {
  // create loader
  const loader: CloudLoader.ProjectLoader = {
    syncTree,
    objects: {},
    root: null,
  }
  // load root
  loader.root = loadObjectRef(loader, { ref: 'root' }, loadProjectRoot)

  return loader
}

export default loadProject
