/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled, { IconSymbol } from 'themes'
import { Box } from 'reflexbox'
import css from '@styled-system/css'
import { variant } from 'styled-system'
import { useRouteMatch } from 'react-router-dom'
import { Icon, A, Tooltip, AnchorProps } from '@rapidapi/ui-lib'

export type NavItemProps = AnchorProps & {
  title: string
  icon: IconSymbol
}

const NavItemStyled = styled(Box)<{ variant: 'default' | 'selected' }>(
  css({
    borderRadius: 'default',
    paddingTop: 2,
    paddingBottom: 2,
  }),
  () =>
    variant({
      variants: {
        default: {},
        selected: {
          backgroundColor: 'backgrounds.accent',
        },
      },
    }),
)

const NavItem: React.FC<NavItemProps> = ({ title, icon, link, ...other }) => {
  const selected = useRouteMatch((link?.to as string) ?? '')?.isExact
  return (
    <Tooltip content={title} placement="right">
      <NavItemStyled variant={selected ? 'selected' : 'default'}>
        <A variant="secondary" displayInline={false} link={link} {...other}>
          <Icon symbol={icon} size={24} />
        </A>
      </NavItemStyled>
    </Tooltip>
  )
}

export default NavItem
