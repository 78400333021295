import { Project } from 'lib/project/types.d'
import deleteEnvironment from './delete-environment'
import deleteObject from './delete-object'
import deleteEnvironmentVariable from './delete-environment-variable'
import { getEnvironmentDomain } from '../getters'

export interface DeleteEnvironmentDomainArgs {
  ref: Project.GenericRef<Project.EnvironmentDomain>
}

const deleteEnvironmentDomain = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { ref }: DeleteEnvironmentDomainArgs,
): Project.GenericRef<Project.Request> => {
  const project = objects[root.ref] as Project.Project
  const domain = getEnvironmentDomain(ref, objects, false)

  // delete all variables for this domain
  domain.variables.forEach((variableRef) => {
    deleteEnvironmentVariable(objects, root, { ref: variableRef })
  })

  // delete all environments for this domain
  domain.environments.forEach((environmentRef) => {
    deleteEnvironment(objects, root, { ref: environmentRef })
  })

  // remote domain from project root
  project.environmentDomains = project.environmentDomains.filter(
    ({ ref: aRef }) => aRef !== ref.ref,
  )

  deleteObject(objects, root, { ref })

  return ref
}

export default deleteEnvironmentDomain
