import React, { useMemo } from 'react'

import { DataTable, DataTableField } from '@rapidapi/ui-lib'
import { RequestHandling } from 'lib/request-handling/types.d'
import { HttpExchangeTabViewProps } from '../http-exchange-tabs-props.d'
import { useHttpExchangeMessage } from '../http-exchange-helper-hooks'
import TabBoxContainer from '../tab-box-container'

const fields: DataTableField<RequestHandling.HTTPExchangeHeader>[] = [
  {
    key: 'Header Name',
    value: (data) => data?.name,
  },
  {
    key: 'Header Value',
    value: (data) => data?.value,
  },
]

const HttpExchangeHeadersTab: React.FC<HttpExchangeTabViewProps> = ({
  messageType,
}) => {
  const { httpMessage } = useHttpExchangeMessage(messageType)

  const headerItems = useMemo(
    () =>
      [...(httpMessage?.headers || [])].map((item, idx) => ({
        data: item,
        uuid: String(idx),
      })),
    [httpMessage],
  )

  return (
    <TabBoxContainer p={5} scrollable>
      <DataTable fields={fields} items={headerItems} />
    </TabBoxContainer>
  )
}

export default HttpExchangeHeadersTab
