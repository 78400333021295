import hmacSHA1 from 'crypto-js/hmac-sha1'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'
import OAuth, {
  Authorization,
  Consumer,
  Data,
  HashFunction,
  RequestOptions,
  Token,
} from 'oauth-1.0a'

export type OAuth1Algorithm =
  | 'HMAC-SHA1'
  | 'HMAC-SHA256'
  | 'RSA-SHA1'
  | 'PLAINTEXT'

export type OAuth1HeaderOptions = {
  consumer: Consumer
  token: Token
  requestOptions: RequestOptions
  requestBodyIsForm: boolean
  algorithm: OAuth1Algorithm
  nonce?: string
  timestamp?: string
  oauthCallback?: string
  oauthAdditionalParams?: string
}

const getHashFunction = (algorithm: OAuth1Algorithm): HashFunction => {
  switch (algorithm) {
    case 'HMAC-SHA1':
      return (baseString: string, key: string) =>
        Base64.stringify(hmacSHA1(baseString, key))
    case 'HMAC-SHA256':
      return (baseString: string, key: string) =>
        Base64.stringify(hmacSHA256(baseString, key))
    case 'RSA-SHA1':
      return () =>
        // @TODO support RSA
        // perhaps using: https://www.npmjs.com/package/tweetnacl
        '{RSA-SHA1 is not yet supported}'

    case 'PLAINTEXT':
      return (_: string, key: string) => key
    default:
      throw new Error(`Unknown OAuth 1 algorithm: ${algorithm}`)
  }
}

const getOAuth1Header = ({
  consumer,
  token,
  requestOptions,
  requestBodyIsForm,
  algorithm,
  nonce,
  timestamp,
  oauthCallback,
  oauthAdditionalParams,
}: OAuth1HeaderOptions): string => {
  const oauth = new OAuth({
    consumer,
    signature_method: algorithm,
    hash_function: getHashFunction(algorithm),
  })

  const oauthData: Data = {
    oauth_consumer_key: oauth.consumer.key,
    oauth_nonce: nonce && nonce.length > 0 ? nonce : oauth.getNonce(),
    oauth_signature_method: algorithm,
    oauth_timestamp:
      timestamp && timestamp.length > 0
        ? parseInt(timestamp, 10) || 0
        : oauth.getTimeStamp(),
    oauth_version: oauth.version,
    oauth_token: token.key,
    ...(requestOptions.includeBodyHash
      ? { oauth_body_hash: oauth.getBodyHash(requestOptions, token.secret) }
      : {}),
    ...(oauthCallback && oauthCallback.length > 0
      ? { oauth_callback: oauthCallback }
      : {}),
    ...(oauthAdditionalParams && oauthAdditionalParams.length > 0
      ? oauth.deParam(oauthAdditionalParams)
      : {}),
  }
  const oauthAuthorization: Authorization = {
    ...oauthData,
    oauth_signature: oauth.getSignature(
      {
        ...requestOptions,
        // data should only be included if body is a form (and included as params dict)
        data: requestBodyIsForm ? oauth.deParam(requestOptions.data) : null,
      },
      token.secret,
      oauthData,
    ),
  }

  const header = oauth.toHeader(oauthAuthorization)
  return header.Authorization
}

export default getOAuth1Header
