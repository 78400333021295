import { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'
import {
  findRequestTreeItemParent,
  getRequest,
  getRequestGroup,
} from '../getters'
import addDynamicString from './add-dynamic-string'
import addObject from './add-object'

// eslint-disable-next-line import/no-cycle
import duplicateDynamicString from './duplicate-dynamic-string'
import duplicateKeyValue from './duplicate-key-value'

export interface DuplicateRequestArgs {
  uuid?: string
  sourceRef: Project.GenericRef<Project.Request>
  parentRef?: Project.GenericRef<Project.RequestGroup> | null
}

const duplicateRequest = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { uuid, sourceRef, parentRef }: DuplicateRequestArgs,
): Project.GenericRef<Project.Request> => {
  // get the original/source
  const sourceRequest = getRequest(sourceRef, objects, false)

  // create a new request
  const request: Project.Request = {
    uuid: uuid || getUuid(),
    type: 'request',
    title: `${sourceRequest.title} Copy`,
    summary: sourceRequest.summary,
    method: sourceRequest.method
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceRequest.method,
        })
      : addDynamicString(objects, root, { string: 'GET' }),
    urlFull: sourceRequest.urlFull
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceRequest.urlFull,
        })
      : null,
    bodyString: sourceRequest.bodyString
      ? duplicateDynamicString(objects, root, {
          sourceRef: sourceRequest.bodyString,
        })
      : null,
    headers: (sourceRequest.headers || []).map((keyValueRef) =>
      duplicateKeyValue(objects, root, { sourceRef: keyValueRef }),
    ),
    urlParameters: (sourceRequest.urlParameters || []).map((keyValueRef) =>
      duplicateKeyValue(objects, root, { sourceRef: keyValueRef }),
    ),
    variables: [], // @TODO fix me
    followRedirects: sourceRequest.followRedirects || false,
    redirectAuthorization: sourceRequest.redirectAuthorization || false,
    redirectMethod: sourceRequest.redirectMethod || false,
    timeoutInterval: sourceRequest.timeoutInterval || 0,
    sendCookies: sourceRequest.sendCookies || true,
    storeCookies: sourceRequest.storeCookies || true,
    clientCertificate: sourceRequest.clientCertificate || null,
  }
  const ref = addObject(objects, request)

  // find parent
  // if passing undefined, search for parent
  // if passing null or a reference, use this parent
  const newParentRef =
    parentRef === undefined
      ? findRequestTreeItemParent(sourceRef, objects, root)
      : parentRef

  // add new request to parent
  if (newParentRef) {
    const parentGroup = getRequestGroup(newParentRef, objects, false)
    parentGroup.children.push(ref)
  } else {
    const project = objects[root.ref] as Project.Project
    project.requests.push(ref)
  }

  return ref
}

export default duplicateRequest
