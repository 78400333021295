import {
  authorizationCodeGrantType,
  implicitGrantType,
  resourceOwnerPasswordCredentialsGrantType,
} from 'lib/dynamic-values/implementations/oauth2-dynamic-value/impl-oauth2-dynamic-value'
import { getFullUrl } from 'lib/utils'
import { OAuth2Utils } from './oauth2-functions-types.d'

interface GetOAuth2AuthorizationUrlProps {
  evaluatedOAuth2Params: OAuth2Utils.EvaluatedOAuth2Params
}

const getOAuth2AuthorizationUrl = ({
  evaluatedOAuth2Params,
}: GetOAuth2AuthorizationUrlProps): string => {
  let queryParams: Record<string, string | undefined> = {
    client_id: evaluatedOAuth2Params.clientID || undefined,
    redirect_uri:
      evaluatedOAuth2Params.explicitCallbackURL &&
      evaluatedOAuth2Params.callbackURL
        ? evaluatedOAuth2Params.callbackURL
        : undefined,
    scope: evaluatedOAuth2Params.scope || undefined,
    state: evaluatedOAuth2Params.stateNonce || undefined,
  }

  if (evaluatedOAuth2Params.grantType === authorizationCodeGrantType) {
    /* 4.1.1.  Authorization Request
     * https://tools.ietf.org/html/rfc6749#section-4.1.1
     *
     * The client constructs the request URI by adding the following
     * parameters to the query component of the authorization endpoint URI
     * using the "application/x-www-form-urlencoded" format
     *
     * response_type
     *   REQUIRED.  Value MUST be set to "code".
     *
     * client_id
     *   REQUIRED.  The client identifier as described in Section 2.2.
     *
     * redirect_uri
     *   OPTIONAL.  As described in Section 3.1.2.
     *
     * scope
     *   OPTIONAL.  The scope of the access request as described by
     *   Section 3.3.
     *
     * state
     *   RECOMMENDED.  An opaque value used by the client to maintain
     *   state between the request and callback.  The authorization
     *   server includes this value when redirecting the user-agent back
     *   to the client.  The parameter SHOULD be used for preventing
     *   cross-site request forgery as described in Section 10.12.
     */
    queryParams = {
      ...queryParams,
      response_type: 'code',
    }
  } else if (evaluatedOAuth2Params.grantType === implicitGrantType) {
    /* 4.2.1.  Authorization Request
     * https://tools.ietf.org/html/rfc6749#section-4.2.1
     *
     * The client constructs the request URI by adding the following
     * parameters to the query component of the authorization endpoint URI
     * using the "application/x-www-form-urlencoded" format, per Appendix B:
     *
     * response_type
     *   REQUIRED.  Value MUST be set to "token".
     *
     * client_id
     *   REQUIRED.  The client identifier as described in Section 2.2.
     *
     * redirect_uri
     *   OPTIONAL.  As described in Section 3.1.2.
     *
     * scope
     *   OPTIONAL.  The scope of the access request as described by
     *   Section 3.3.
     *
     * state
     *   RECOMMENDED.  An opaque value used by the client to maintain
     *   state between the request and callback.  The authorization
     *   server includes this value when redirecting the user-agent back
     *   to the client.  The parameter SHOULD be used for preventing
     *   cross-site request forgery as described in Section 10.12.
     */
    queryParams = {
      ...queryParams,
      response_type: 'token',
    }
  } else if (
    evaluatedOAuth2Params.grantType ===
    resourceOwnerPasswordCredentialsGrantType
  ) {
    queryParams = {
      ...queryParams,
      grant_type: 'password',
      username: evaluatedOAuth2Params.username || '',
      password: evaluatedOAuth2Params.password || '',
    }
  } else {
    throw new Error(
      `[getOAuth2AuthorizationUrl] Invalid grant type ${evaluatedOAuth2Params.grantType}`,
    )
  }

  /* extra params */
  queryParams = {
    ...queryParams,
    ...evaluatedOAuth2Params.authRequestParams.reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: value,
      }),
      {},
    ),
  }

  return getFullUrl(evaluatedOAuth2Params.authorizationURL || '', queryParams)
}

export default getOAuth2AuthorizationUrl
