/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo, useState } from 'react'
import { Flex } from 'reflexbox'
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system'

import { Project } from 'lib/project'
import { getImplementation } from 'lib/dynamic-values'
import { useDynamicValueObject } from 'utils/hooks'

import { Button, Elevation, EmptyState } from '@rapidapi/ui-lib'

import DynamicValuePreview from './dynamic-value-preview'
import DynamicValueEditorFieldSet, {
  DynamicValueEditorRenderFieldContainer,
} from './dynamic-value-editor-field-set'

type DynamicValueEditorProps = SpaceProps &
  LayoutProps &
  FlexboxProps & {
    objectRef: Project.GenericRef<Project.DynamicValue>
    showPreview?: boolean
    fieldSetProps?: SpaceProps & LayoutProps & FlexboxProps
    renderFieldContainer?: DynamicValueEditorRenderFieldContainer
  }

const DynamicValueEditor: React.FC<DynamicValueEditorProps> = ({
  objectRef,
  fieldSetProps,
  renderFieldContainer,
  showPreview = true,
  ...props
}) => {
  const dynamicValue = useDynamicValueObject(objectRef)

  // filter fields, group by advanced
  const [impl, fields, advancedFields] = useMemo(() => {
    const aImpl = getImplementation(dynamicValue)
    const allFields = aImpl?.editForm?.fields
    const basicFields = allFields?.filter(({ advanced }) => !advanced)
    const aAdvancedFields = allFields?.filter(({ advanced }) => advanced)
    return [aImpl, basicFields, aAdvancedFields]
  }, [dynamicValue])

  const [showAdvanced, setShowAdvanced] = useState(false)
  const toggleShowAdvanced = useCallback(() => {
    setShowAdvanced((value) => !value)
  }, [])

  if (!impl) {
    return (
      <EmptyState
        symbol="alert"
        headline={`Missing dynamic value (${dynamicValue.identifier})`}
      />
    )
  }
  if (!fields) {
    return (
      <EmptyState
        symbol="brackets"
        headline="Not Editable"
        body={`This dynamic value type (${dynamicValue.identifier}) has no editable fields`}
      />
    )
  }

  return (
    <Flex flexDirection="column" alignItems="stretch" {...props}>
      <DynamicValueEditorFieldSet
        objectRef={objectRef}
        fields={fields}
        renderFieldContainer={renderFieldContainer}
        {...fieldSetProps}
      />
      {advancedFields && advancedFields.length > 0 && showAdvanced && (
        <Elevation variant="small" rounded>
          <DynamicValueEditorFieldSet
            {...fieldSetProps}
            objectRef={objectRef}
            fields={advancedFields}
            renderFieldContainer={renderFieldContainer}
          />
        </Elevation>
      )}
      {advancedFields && advancedFields.length > 0 && (
        <Button variant="tertiary" onClick={toggleShowAdvanced}>
          {showAdvanced ? 'Show Less' : 'Show More'}
        </Button>
      )}
      {showPreview && <DynamicValuePreview objectRef={objectRef} />}
    </Flex>
  )
}

export default DynamicValueEditor
