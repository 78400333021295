import React, { useCallback, useMemo } from 'react'
import { Box } from 'reflexbox'

import { CloudAPI } from 'lib/cloud-sync/cloud-api'
import { Select } from '@rapidapi/ui-lib'

interface TeamSelectProps {
  accountResponse: CloudAPI.UserAccountResponse
  teamListResponse: CloudAPI.TeamListResponse
  selectedTeamId: number | null
  setSelectedTeamId: (teamId: number) => void
}

const TeamSelect: React.FC<TeamSelectProps> = ({
  accountResponse,
  teamListResponse,
  selectedTeamId,
  setSelectedTeamId,
}) => {
  const items = useMemo((): string[] => {
    const personalTeamId = accountResponse.personal_team?.id
    return [
      ...(personalTeamId ? [String(personalTeamId)] : []),
      ...teamListResponse.map((team) => String(team.id)),
    ]
  }, [accountResponse, teamListResponse])

  const itemDataSelector = useCallback(
    (id?: string | null) => {
      const personalTeamId = accountResponse.personal_team?.id
      if (id === String(personalTeamId)) {
        return {
          id,
          label: `Personal (${accountResponse.username})`,
        }
      }
      const team = teamListResponse.find((aTeam) => String(aTeam.id) === id)
      if (!team) {
        return {
          id: id || '',
          label: id || '',
        }
      }
      return {
        id: id || '',
        label: team.name,
      }
    },
    [accountResponse, teamListResponse],
  )

  const onChangeMethod = useCallback(
    (value?: string | null) => {
      if (value) {
        setSelectedTeamId(parseInt(value, 10))
      }
    },
    [setSelectedTeamId],
  )

  return (
    <Select
      width="100%"
      items={items}
      itemDataSelector={itemDataSelector}
      value={String(selectedTeamId)}
      itemRender={(item) =>
        item ? (
          <Box minWidth={130} width="100%">
            {item.label}
          </Box>
        ) : undefined
      }
      onChange={onChangeMethod}
    />
  )
}

export default TeamSelect
