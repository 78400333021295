import React from 'react'
import { Box } from 'reflexbox'

import { Button, EmptyState, Typography } from '@rapidapi/ui-lib'

interface ErrorBoundaryFeedbackBoxProps {
  error?: Error
  eventId?: string | null
  projectId?: number | null
  clearProjectData?: () => void
  reloadPage?: () => void
}

const ErrorBoundaryFeedbackBox: React.FC<ErrorBoundaryFeedbackBoxProps> = ({
  error,
  eventId,
  projectId,
  clearProjectData,
  reloadPage,
}) => (
  <Box m={5}>
    <EmptyState symbol="danger" headline="You have encountered an error">
      {error && (
        <Typography variant="body2" color="secondary">
          {error.toString()}
        </Typography>
      )}
      {eventId ? (
        <Typography
          variant="body2"
          color="secondary"
        >{`Event ID: ${eventId}`}</Typography>
      ) : (
        <Typography variant="body2" color="secondary">
          Event ID was not reported
        </Typography>
      )}
      {projectId && (
        <Typography
          variant="body2"
          color="secondary"
        >{`Project ID: ${projectId}`}</Typography>
      )}
      {projectId && (
        <Box mt={3}>
          <Typography variant="body2" color="secondary" m={3}>
            If an error persists after reloading the application, you can try
            clearing the local project data.
          </Typography>
          {clearProjectData && (
            <Button
              mx={1}
              onClick={clearProjectData}
              variant="danger"
              startIcon="trash"
            >
              Clear Project Data
            </Button>
          )}
          {reloadPage && (
            <Button mx={1} onClick={reloadPage} startIcon="refresh">
              Reload
            </Button>
          )}
        </Box>
      )}
    </EmptyState>
  </Box>
)

export default ErrorBoundaryFeedbackBox
