import { Project } from 'lib/project/types.d'
import { CloudLoader } from 'lib/cloud-sync/loader/types.d'

import filterArrayNonNull from './filter-array-non-null'
import loadObjectRef from './load-object-ref'

const loadObjectRefArray = <T extends Project.AnyObject>(
  loader: CloudLoader.ProjectLoader,
  refs: Project.GenericRef<T>[],
  loaderFn?: CloudLoader.ObjectLoaderFn<T>,
): Project.GenericRef<T>[] =>
  filterArrayNonNull(
    (refs || []).map((ref) => loadObjectRef(loader, ref, loaderFn)),
  )

export default loadObjectRefArray
