import { Evaluation } from 'lib/evaluation/types.d'
import { Project } from 'lib/project/types.d'
import { getDynamicString } from 'lib/project'

import evaluateDynamicStringComponents from './evaluate-dynamic-string-components'

const evaluateDynamicString = async (
  dsRef: Project.GenericRef<Project.DynamicString>,
  ctx: Evaluation.Ctx,
): Promise<string> => {
  const ds = getDynamicString(dsRef, ctx.project.objects, false)
  return evaluateDynamicStringComponents(ds.strings, ctx)
}

export default evaluateDynamicString
