/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react'
import { SpaceProps } from 'styled-system'
import { Box, Flex } from 'reflexbox'
import { useDispatch } from 'react-redux'

import { RadioButton, Typography } from '@rapidapi/ui-lib'
import { Project } from 'lib/project'
import { setProjectValue } from 'store/actions'
import { useAnyObjectProperty } from 'utils/hooks'

export type RadioChoiceEditorChoice = {
  value: string | number
  label?: string
}

type RadioChoiceEditorProps<T extends Project.AnyObject> = SpaceProps & {
  objectRef: Project.GenericRef<T>
  objectProperty: keyof T
  label?: string
  choices: RadioChoiceEditorChoice[]
}

type RadioChoiceEditorReturnType = React.ReactElement<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> | null

type RadioChoiceEditorItemProps = {
  onChange: (value: unknown) => void
  value: unknown
  checked: boolean
  label?: string
}

const RadioChoiceEditorItem: React.FC<RadioChoiceEditorItemProps> = ({
  onChange,
  value,
  checked,
  label,
}) => {
  const onChangeRadioButton = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value) {
        onChange(value)
      }
    },
    [onChange, value],
  )

  return (
    <Box py={1}>
      <RadioButton
        type="radio"
        label={label || String(value)}
        checked={checked}
        value={String(value)}
        onChange={onChangeRadioButton}
      />
    </Box>
  )
}

const RadioChoiceEditor = <T extends Project.AnyObject>({
  objectRef,
  objectProperty,
  label,
  choices,
  ...props
}: RadioChoiceEditorProps<T>): RadioChoiceEditorReturnType => {
  // get current value
  const currentValue = useAnyObjectProperty(
    objectRef,
    objectProperty,
    undefined /* expect */,
    true /* allowsNull */,
  ) as string

  // dispatch update
  const dispatch = useDispatch()
  const onChange = useCallback(
    (newValue: unknown) => {
      dispatch(
        setProjectValue({
          objectRef,
          update: {
            [objectProperty]: newValue,
          },
        }),
      )
    },
    [dispatch, objectRef, objectProperty],
  )

  return (
    <Box>
      {label && (
        <Typography variant="body2" mb={1} color="primary">
          {label}
        </Typography>
      )}
      <Box maxHeight={220} overflow="auto">
        <Flex flexDirection="column" alignItems="stretch" {...props}>
          {choices.map(({ value, label: itemLabel }) => (
            <RadioChoiceEditorItem
              key={value}
              value={value}
              onChange={onChange}
              checked={value === currentValue}
              label={itemLabel}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

export default RadioChoiceEditor
