import { Project } from 'lib/project/types.d'
import { getDynamicValue } from '../getters'
import addDynamicValue from './add-dynamic-value'

// eslint-disable-next-line import/no-cycle
import duplicateAnything from './duplicate-anything'

const duplicateDynamicValue = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  { sourceRef }: { sourceRef: Project.GenericRef<Project.DynamicValue> },
): Project.GenericRef<Project.DynamicValue> => {
  // get the original/source
  const sourceDynamicValue = getDynamicValue(sourceRef, objects, false)

  // extra 'dynamic` keys
  const extraKeys = Object.keys(sourceDynamicValue).filter(
    (key) => key !== 'identifier' && key !== 'type' && key !== 'uuid',
  )
  const extraValues = extraKeys.reduce((acc, key) => {
    acc[key] = duplicateAnything(objects, root, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      input: (sourceDynamicValue[key] as unknown) as any,
    })
    return acc
  }, {} as Project.ObjectMap<unknown>)

  // create a new dynamic value with the new values
  return addDynamicValue(objects, root, {
    ...extraValues,
    identifier: sourceDynamicValue.identifier,
    uuid: undefined,
  })
}

export default duplicateDynamicValue
