import { getKeyValue, getRequest } from 'lib/project'
import { filterArrayNonNull } from 'lib/cloud-sync/loader/helpers'
import { Evaluation } from 'lib/evaluation/types.d'
import { Project } from 'lib/project/types.d'
import config from 'config'
import { evaluateDynamicString } from '../evaluate'

const requestPlaceholderURL = config.placeholderURL

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const keyValueModeNormal = 0
const keyValueModeNormalAlwaysAddEqualSign = 1
const keyValueModeRaw = 2

const evaluateMethodString = async (
  request: Project.Request,
  ctx: Evaluation.Ctx,
): Promise<string> =>
  // Orig: -[LMRequest evaluatedMethodStringInContext:]
  // @TODO push/pop layer

  request.method ? evaluateDynamicString(request.method, ctx) : 'GET'

const evaluateURLString = async (
  request: Project.Request,
  ctx: Evaluation.Ctx,
): Promise<string> => {
  // Orig: -[LMRequest evaluatedURLStringInContext:usingEncoding:replaceBlock:originalURLString:]
  // @TODO push/pop layer

  const url = request.urlFull
    ? await evaluateDynamicString(request.urlFull, ctx)
    : null
  if (!url || url.trim().length === 0) {
    return requestPlaceholderURL
  }
  return url
}

const evaluateUrlParameter = async (
  keyValueRef: Project.GenericRef<Project.KeyValue>,
  ctx: Evaluation.Ctx,
): Promise<Evaluation.EvaluatedParameter | null> => {
  // Orig: none
  // @TODO push/pop layer (addUrlParameterNameLayer:)
  // @TODO: evaluate the URL param value only if necessary
  // @TODO: Replacement Block

  const kv = getKeyValue(keyValueRef, ctx.project.objects, false)
  if (!kv.enabled) {
    return null
  }

  const evaluatedName = kv.name
    ? await evaluateDynamicString(kv.name, ctx)
    : null
  const evaluatedValue = kv.value
    ? await evaluateDynamicString(kv.value, ctx)
    : null
  if (!evaluatedName || evaluatedName.trim().length === 0) {
    return null
  }

  return {
    uuid: kv.uuid,
    mode: kv.mode,
    name: evaluatedName,
    value: evaluatedValue ?? '',
  }
}

const evaluateUrlParameters = async (
  request: Project.Request,
  ctx: Evaluation.Ctx,
): Promise<Evaluation.EvaluatedParameter[]> =>
  // Orig: -[LMRequest evaluatedUrlParametersBlock:inContext:replaceBlock:originalValues:]
  // @TODO push/pop layer

  filterArrayNonNull(
    await Promise.all(
      request.urlParameters.map(
        (keyValueRef: Project.GenericRef<Project.KeyValue>) =>
          evaluateUrlParameter(keyValueRef, ctx),
      ),
    ),
  )

const encodeUrlParameters = (
  url: string,
  urlParameters: Evaluation.EvaluatedParameter[],
): string => {
  // Orig: +[LMURLEncodedStringParser stringForArrayParameters:]

  let anUrl = url

  // Strip last '?' if any
  if (anUrl.endsWith('?')) {
    anUrl = anUrl.slice(0, anUrl.length - 1)
  }

  // If URL has no path components at all (like http://luckymarmot.com), we need to add a /
  if (!anUrl.match(/^[a-z]+:\/\/[^/]+\//i) && !anUrl.endsWith('/')) {
    anUrl += '/'
  }

  let hasParameter = anUrl.indexOf('?') >= 0

  urlParameters.forEach((kv: Evaluation.EvaluatedParameter) => {
    if (kv.name.length > 0 || kv.value.length > 0) {
      if (!hasParameter) {
        hasParameter = true
        anUrl += '?'
      } else {
        anUrl += '&'
      }

      if (kv.mode === keyValueModeRaw) {
        anUrl += kv.name + kv.value
      } else {
        const encodedName = encodeURIComponent(kv.name)
        const encodedValue = encodeURIComponent(kv.value)
        anUrl += encodedName
        if (
          encodedValue.length > 0 ||
          kv.mode === keyValueModeNormalAlwaysAddEqualSign
        ) {
          anUrl += `=${encodedValue}`
        }
      }
    }
  })

  return anUrl
}

const evaluateFullURLString = async (
  request: Project.Request,
  ctx: Evaluation.Ctx,
): Promise<string> => {
  // Orig: -[LMRequest evaluatedFullURLStringInContext:usingEncoding:urlReplaceBlock:urlParamsReplaceBlock:originalURLString:]

  // Evaluate URL string & URL Parameters
  const evaluatedUrlString = await evaluateURLString(request, ctx)
  const evaluatedUrlParameters = await evaluateUrlParameters(request, ctx)

  // URL Encode Parameters
  const evaluatedFullURLString = encodeUrlParameters(
    evaluatedUrlString,
    evaluatedUrlParameters,
  )

  return evaluatedFullURLString
}

const evaluateHeader = async (
  keyValueRef: Project.GenericRef<Project.KeyValue>,
  ctx: Evaluation.Ctx,
): Promise<Evaluation.EvaluatedParameter | null> => {
  // Orig: none
  // @TODO push/pop layer (addUrlParameterNameLayer:)
  // @TODO: evaluate the header value only if necessary
  // @TODO: Replacement Block

  // Only add headers with a name and enabled
  const kv = getKeyValue(keyValueRef, ctx.project.objects, false)
  if (!kv.enabled) {
    return null
  }

  const evaluatedName = kv.name
    ? await evaluateDynamicString(kv.name, ctx)
    : null
  const evaluatedValue = kv.value
    ? await evaluateDynamicString(kv.value, ctx)
    : null
  if (!evaluatedName || evaluatedName.trim().length === 0) {
    return null
  }

  return {
    uuid: kv.uuid,
    mode: 0, // does not apply to headers
    name: evaluatedName,
    value: evaluatedValue ?? '',
  }
}

const evaluateHeaders = async (
  request: Project.Request,
  ctx: Evaluation.Ctx,
): Promise<Evaluation.EvaluatedParameter[]> =>
  // Orig: -[LMRequest evaluatedHeadersBlock:inContext:revealedEncoding:replaceBlock:originalValues:]
  // @TODO push/pop layer

  filterArrayNonNull(
    await Promise.all(
      request.headers.map((keyValueRef: Project.GenericRef<Project.KeyValue>) =>
        evaluateHeader(keyValueRef, ctx),
      ),
    ),
  )

const evaluateBody = async (
  request: Project.Request,
  ctx: Evaluation.Ctx,
): Promise<string> =>
  // Orig: -[LMRequest evaluatedBodyDataInContext:usingEncoding:replaceBlock:originalValue:]
  // @TODO push/pop layer
  // @TODO evaluate as data not string
  // @TODO string encoding

  request.bodyString ? evaluateDynamicString(request.bodyString, ctx) : ''

const evaluateRequest = async (
  requestRef: Project.GenericRef<Project.Request>,
  ctx: Evaluation.Ctx,
): Promise<Evaluation.EvaluatedRequest> => {
  // push layer
  ctx.layers.push({
    layerType: 'RequestLayer',
    requestRef,
  })

  const request = getRequest(requestRef, ctx.project.objects, false)

  const method = await evaluateMethodString(request, ctx)
  const url = await evaluateFullURLString(request, ctx)
  const headers = await evaluateHeaders(request, ctx)
  const body = await evaluateBody(request, ctx)

  // pop layer
  ctx.layers.pop()

  return {
    method,
    url,
    headers,
    body,
  }
}

export {
  requestPlaceholderURL,
  evaluateMethodString,
  evaluateFullURLString,
  evaluateHeader,
  evaluateHeaders,
  evaluateBody,
  evaluateRequest,
}
