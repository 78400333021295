/* eslint-disable import/prefer-default-export */
import config from 'lib/cloud-sync/cloud-api/config'
import { CloudAPI } from 'lib/cloud-sync/cloud-api/types.d'
import { cloudApiFetch, handleError } from 'lib/cloud-sync/cloud-api/api-fetch'

/**
 * List teams the user belongs to.
 * @param token Access token
 */
export const getTeamList = async (
  token: string,
): Promise<CloudAPI.TeamListResponse> => {
  const { API_USE_MOCKS } = config
  if (API_USE_MOCKS) {
    throw new Error('Cannot list teams in mock mode')
  }
  const result = await cloudApiFetch({
    method: 'GET',
    path: '/teams/',
    token,
  })

  if (!result.ok) {
    return handleError(result)
  }

  const responseBody = await result.body.json()
  return responseBody as CloudAPI.TeamListResponse
}
