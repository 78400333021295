/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SpaceProps } from 'styled-system'

import { TextField, TextFieldProps } from '@rapidapi/ui-lib'
import { Project } from 'lib/project'
import { useAnyObjectProperty } from 'utils/hooks'
import { setProjectValue } from 'store/actions'

type StringEditorProps<T extends Project.AnyObject> = SpaceProps &
  TextFieldProps & {
    objectRef: Project.GenericRef<T>
    objectProperty: keyof T
  }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StringEditorReturnType = React.ReactElement<any, any> | null

const StringEditor = <T extends Project.AnyObject>({
  objectRef,
  objectProperty,
  onBlur: onBlurProp,
  multiline = false,
  minHeight,
  ...props
}: StringEditorProps<T>): StringEditorReturnType => {
  // get value directly from the store, even without retrieving the entire object
  const value = (useAnyObjectProperty(
    objectRef,
    objectProperty,
    'string' /* expect */,
    true /* allowsNull */,
  ) || '') as string
  const [editValue, setEditValue] = useState(value || '')
  const didEditRef = useRef(false)

  // reset editValue when value changes
  useEffect(() => {
    setEditValue(value)
  }, [value])

  // dispatch changes onBlur but store changes onChange
  const dispatch = useDispatch()
  const onChange = useCallback(
    (newValue: string) => {
      setEditValue(newValue)
      didEditRef.current = true
    },
    [setEditValue],
  )
  const onBlur = useCallback(
    (val?: string) => {
      if (onBlurProp) {
        onBlurProp(val)
      }
      // skip dispatch if never edited
      if (!didEditRef.current) {
        return
      }
      didEditRef.current = false
      dispatch(
        setProjectValue({
          objectRef,
          update: {
            [objectProperty]: editValue,
          },
        }),
      )
    },
    [dispatch, objectRef, objectProperty, editValue, onBlurProp],
  )

  return (
    <TextField
      minHeight={multiline && minHeight === undefined ? 160 : minHeight}
      value={editValue}
      {...{ multiline, onChange, onBlur, ...props }}
    />
  )
}

export default StringEditor
