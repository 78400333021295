import { Project } from 'lib/project/types.d'
import { getUuid } from 'lib/utils'
import addObject from './add-object'
import duplicateDynamicString from './duplicate-dynamic-string'
import { getEnvironment, getEnvironmentDomain } from '../getters'

export interface DuplicateEnvironmentArgs {
  ref: Project.GenericRef<Project.Environment>
}

const duplicateEnvironment = (
  objects: Project.ObjectMap,
  root: Project.GenericRef<Project.Project>,
  {
    ref,
  }: {
    ref: Project.GenericRef<Project.Environment>
  },
): Project.GenericRef<Project.Environment> => {
  const environment = getEnvironment(ref, objects, false)
  const domain = getEnvironmentDomain(environment.domain, objects, false)

  // copy environment
  const environmentCopy: Project.Environment = {
    uuid: getUuid(),
    type: 'environment',
    name: `${environment.name} copy`,
    domain: { ref: domain.uuid },
  }
  const refCopy = addObject(objects, environmentCopy)
  domain.environments.push(refCopy)

  // copy all values for this environment
  const values = (Object.values(objects).filter(
    ({ type }) => type === 'environmentVariableValue',
  ) as Project.EnvironmentVariableValue[]).filter(
    ({ environment: anEnvironment }) => anEnvironment.ref === ref.ref,
  )

  // copy environment variables and environment variable values
  values.forEach(({ value, variable }) => {
    if (value) {
      // copy environment variable value
      const environmentVariableValueCopy: Project.EnvironmentVariableValue = {
        uuid: getUuid(),
        type: 'environmentVariableValue',
        value: duplicateDynamicString(objects, root, {
          sourceRef: value,
        }),
        environment: { ref: environmentCopy.uuid },
        variable,
        domain: environmentCopy.domain,
      }
      addObject(objects, environmentVariableValueCopy)
    }
  })

  return ref
}

export default duplicateEnvironment
