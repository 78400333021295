import { Evaluation } from 'lib/evaluation'
import {
  agentProxySender,
  cfProxySender,
  RequestHandling,
} from 'lib/request-handling'
import { base64DecodeString, getUuid } from 'lib/utils'

import { AppFetch } from './app-fetch-types.d'

const getAppFetch = <T = unknown>(
  usePawAgent: boolean,
): AppFetch.AppFetch<T> => {
  const sender = usePawAgent ? agentProxySender : cfProxySender

  return async (url: string, options: AppFetch.AppFetchOptions) => {
    // Evaluated Request
    const evaluatedRequest: Evaluation.EvaluatedRequest = {
      method: options.method || 'GET',
      url,
      headers: Object.entries(options.headers || {}).map(([name, value]) => ({
        uuid: getUuid(),
        mode: 0,
        name,
        value,
      })),
      body: options.body || '',
    }

    // Concrete Request
    const concreteRequest = {
      projectUuid: getUuid(),
      requestUuid: getUuid(),
      evaluatedRequest,
      httpExchange: null,
      buildSteps: [],
      sender,
    } as RequestHandling.ConcreteRequest

    const { httpExchange } = await sender(concreteRequest)
    const response = httpExchange?.response
    if (!response) {
      throw new Error('Failed to fetch')
    }

    const responseBody: AppFetch.AppFetchResponseBody<T> = {
      text: async () => base64DecodeString(response.body.text, 'utf-8'),
      json: async () => {
        const bodyString = base64DecodeString(response.body.text, 'utf-8')
        return JSON.parse(bodyString)
      },
    }
    return {
      url,
      ok: response.status >= 200 && response.status < 300,
      status: response.status,
      statusText: response.statusText,
      headers: (response.headers as Evaluation.EvaluatedParameter[]).reduce(
        (acc, item) => ({
          ...acc,
          [item.name]: item.value,
        }),
        {},
      ),
      body: responseBody,
    } as AppFetch.AppFetchResponse<T>
  }
}

export default getAppFetch
